<template>
  <div class="px-4 py-5 border border-gray-600">
    <div class="flex justify-between">
      <div class="flex flex-1 flex-col">
        <div class="mb-2">
          <div class="flex flex-row items-center">
            <span class="text-base font-medium mr-2">{{ name }}</span>
            <span v-if="defusedBomb" class="text-gray-400 text-sm">
              (Defused)
            </span>
            <span v-if="plantedBomb" class="text-gray-400 text-sm">
              (Planted)
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <span> {{ kills }} / {{ assists }} / {{ died ? 1 : 0 }} </span>
      </div>
    </div>
    <div
      class="flex-row flex flex-1 justify-between align-center bg-gradient-to-r from-blue-900 to-blue-800 rounded px-4 py-5"
      v-if="equipment && equipment.length"
    >
      <div class="flex flex-1 flex-row align-center items-center">
        <img
          src="/img/icons/body-armor.png"
          class="h-6 mr-2"
          v-if="hasArmor && !hasHelmet"
          alt="armor"
        />
        <img
          src="/img/icons/head-armor.png"
          class="h-6 mr-2"
          v-if="hasArmor && hasHelmet"
          alt="helmet with armor"
        />
        <img
          src="/img/icons/defuser.png"
          class="h-6 mr-2"
          v-if="defuser"
          alt="defuse kit"
        />
        <img
          :src="`/img/icons/${item}`"
          v-for="(item, index) in sanitizeEquipment(equipment)"
          class="h-6 mr-2"
          :key="`${index}_${name}_${item}_${JSON.stringify(equipment)}`"
          :alt="item"
        />
      </div>
      <span class="bg-red-600 text-white rounded px-2 py-1"
        >- {{ accounting.formatMoney(spent, "$", 0) }}</span
      >
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
import { objSort } from "/src/utils/array_utils";

export default {
  name: "PlayerRoundRow",
  props: {
    name: String,
    spent: Number,
    value: Number,
    hasArmor: Boolean,
    hasHelmet: Boolean,
    equipment: Array,
    kills: Number,
    plantedBomb: Boolean,
    defusedBomb: Boolean,
    team: String,
    defuser: Boolean,
    assists: Number,
    died: Boolean,
  },
  data: function data() {
    return {
      accounting,
    };
  },
  methods: {
    sanitizeEquipment: function sanitizeEquipment(equipment) {
      if (!equipment) return null;

      const newEquipment = equipment.map(this.sanitizeItem);
      const filteredEquipment = newEquipment.filter((item) => item !== undefined);
      const sortedEquipment = filteredEquipment.sort(objSort("order"));
      return sortedEquipment.map((obj) => obj.name);
    },
    sanitizeItem: function sanitizeItem(item) {
      if (!item) return null;

      const equipment = {
        weapon_ak47: { name: "AK-47.png", order: 0 },
        weapon_aug: { name: "AUG.png", order: 0 },
        weapon_awp: { name: "AWP", order: 0 },
        weapon_bizon: { name: "PP-Bizon.png", order: 0 },
        weapon_cz75a: { name: "CZ75-Auto.png", order: 1 },
        weapon_deagle: { name: "Desert Eagle.png", order: 1 },
        weapon_decoy: { name: "Decoy Grenade.png", order: 2 },
        weapon_elite: { name: "Dual Berettas.png", order: 1 },
        weapon_famas: { name: "FAMAS.png", order: 0 },
        weapon_fiveseven: { name: "Five-SeveN.png", order: 1 },
        weapon_flashbang: { name: "Flashbang.png", order: 2 },
        weapon_g3sg1: { name: "G3SG1.png", order: 0 },
        weapon_galilar: { name: "Galil AR.png", order: 0 },
        weapon_glock: { name: "Glock-18.png", order: 1 },
        weapon_hegrenade: { name: "HE Grenade.png", order: 2 },
        weapon_incgrenade: { name: "Incendiary Grenade.png", order: 2 },
        weapon_hkp2000: { name: "P2000.png", order: 1 },
        weapon_m249: { name: "M249.png", order: 0 },
        weapon_m4a1: { name: "M4A4.png", order: 0 },
        weapon_m4a1_silencer: { name: "M4A1.png", order: 0 },
        weapon_mac10: { name: "MAC-10.png", order: 0 },
        weapon_mag7: { name: "MAG-7.png", order: 0 },
        weapon_molotov: { name: "Molotov.png", order: 2 },
        weapon_mp5sd: { name: "MP5-SD.png", order: 0 },
        weapon_mp7: { name: "MP7.png", order: 0 },
        weapon_mp9: { name: "MP9.png", order: 0 },
        weapon_negev: { name: "Neveg.png", order: 0 },
        weapon_nova: { name: "Nova.png", order: 0 },
        weapon_p250: { name: "P250.png", order: 1 },
        weapon_p90: { name: "P90.png", order: 0 },
        weapon_sawedoff: { name: "Sawed-off.png", order: 0 },
        weapon_scar20: { name: "SCAR-20.png", order: 0 },
        weapon_sg556: { name: "SG 553.png", order: 0 },
        weapon_ssg08: { name: "ssg 09.png", order: 0 },
        weapon_smokegrenade: { name: "Smoke Grenade.png", order: 2 },
        weapon_taser: { name: "Taser.png", order: 2 },
        weapon_tec9: { name: "Tec-9.png", order: 1 },
        weapon_ump45: { name: "UMP-45.png", order: 0 },
        weapon_usp_silencer: { name: "USP-S.png", order: 1 },
        weapon_xm1014: { name: "XM1014.png", order: 0 },
        weapon_revolver: { name: "R8 Revolver.png", order: 1 },
        weapon_m4a4: { name: "M4A4.png", order: 0 },
      };
      if (Object.prototype.hasOwnProperty.call(equipment, item)) {
        return equipment[item];
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped></style>
