<template>
  <div class="self-end">
    <div class="px-3 py-2 bg-gray-800 rounded-md flex gap-2 items-center">
      <img src="../assets/icons/search.svg" class="h-3 w-3 text-text-secondary" aria-hidden="true" />
      <input v-model="innerValue" type="text" class="py-0 px-0 appearance-none text-sm bg-gray-800 border-0 ring-0 focus:ring-0 text-text-primary placeholder:text-text-secondary" placeholder="Search..">
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const innerValue = ref(props.modelValue);

watch(innerValue, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
  if (newValue !== innerValue.value) {
    innerValue.value = newValue;
  }
});
</script>
