<template>
  <DarkFull>
    <div class="min-h-full flex flex-col bg-gray-950">
      <div class="w-full h-16 border-b border-gray-900 flex items-center justify-center">
        <img class="mx-auto h-6 w-auto" :src="Logo" alt="Refrag Logo" />
      </div>

      <div class="sm:mx-auto sm:w-full sm:max-w-md flex items-center justify-center flex-1">
        <div class="sm:mx-auto sm:w-full sm:max-w-md bg-gray-900 rounded-lg py-8 px-6 shadow sm:rounded-md">
          <div class="space-y-6">
            <div class="flex flex-col w-full items-center border-b border-gray-800 pb-6">
              <h1 class="text-text-primary font-semibold text-[24px]">Set New Password</h1>
              <h2 class="text-text-secondary">Set your new password, make it strong!</h2>
            </div>
            <div>
              <label for="password" class="block text-sm font-medium text-text-primary">
                Password
              </label>
              <div class="mt-2">
                <input
                  v-model="password"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  placeholder="Your new password"
                  required="true"
                  class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-3 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                  :class="{ 'border-red-400': v$.password.$errors.length }"
                  @blur="v$.password.$touch"
                />
              </div>
            </div>

            <div>
              <label
                for="password_confirmation"
                class="block text-sm font-medium text-text-primary"
              >
                Password Confirmation
              </label>
              <div class="mt-2">
                <input
                  v-model="password_confirmation"
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  autocomplete="current-password"
                  required="true"
                  class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-3 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                  :class="{ 'border-red-400': v$.password_confirmation.$errors.length }"
                  @blur="v$.password_confirmation.$touch"
                />
              </div>
            </div>

            <div>
              <button
                @click="setPassword"
                type="submit"
                class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                :class="[!saveEnabled && 'bg-gray-400 hover:bg-gray-400']"
                :disabled="!saveEnabled"
              >
                Set Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DarkFull>
</template>

<script>
import axios from "axios";
import Logo from "../assets/logos/refrag-horz-fill.svg";
import DarkFull from "../components/layouts/DarkFull.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { reactive } from "vue";
import { onMounted } from "vue";
import { mapMutations } from "vuex";

export default {
  setup() {
    const auth = useAuth();

    onMounted(() => {
      auth.redirect();
    });

    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      Logo,
      password: "",
      password_confirmation: "",
    };
  },
  components: {
    DarkFull,
  },
  computed: {
    saveEnabled() {
      return (
        this.password &&
        this.password_confirmation &&
        this.password == this.password_confirmation
      );
    },
  },
  methods: {
    ...mapMutations(["showMessage"]),
    setPassword() {
      this.v$.$touch();
      if (this.v$.$invalid) return false; // stop here if form is invalid

      axios({
        url: "/auth/password",
        method: "PUT",
        data: {
          password: this.password,
          password_confirmation: this.password,
        },
        headers: {
          ...this.$route.query,
        },
      })
        .then(() => {
          this.$router.push({
            name: "Login",
          });
        })
        .catch(() => {
          this.showMessage({
            messageType: "error",
            messageText: "Could not set a new password",
          });
        });
    },
  },
  validations() {
    return {
      password: { required, minLength: minLength(6) },
      password_confirmation: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs(this.password),
      },
    };
  },
};
</script>
