export default function groupGrenadesWithinThreshold(grenades) {
  const groupedPoints = [];

  for (let i = 0; i < grenades.length; i++) {
    const currentPoint = {
      x: grenades[i].end_x_pos,
      y: grenades[i].end_y_pos,
      start_x: grenades[i].start_x_pos,
      start_y: grenades[i].start_y_pos,
      id: grenades[i].id
    }
    const group = {
      def_id: grenades[i].grenade_data.defindex,
      points: [currentPoint]
    }

    for (let j = i + 1; j < grenades.length; j++) {
      const nextPoint = {
        x: grenades[j].end_x_pos,
        y: grenades[j].end_y_pos,
        start_x: grenades[j].start_x_pos,
        start_y: grenades[j].start_y_pos,
        id: grenades[j].id
      }

      const distance = Math.sqrt(
        Math.pow(nextPoint.x - currentPoint.x, 2) +
        Math.pow(nextPoint.y - currentPoint.y, 2)
      );

      if (distance <= 2 && grenades[i].grenade_data.defindex === grenades[j].grenade_data.defindex) {
        group.points.push(nextPoint);
        grenades.splice(j, 1);
        j--; // Decrement j to account for the removed point
      }
    }

    groupedPoints.push(group);
  }

  return groupedPoints;
}