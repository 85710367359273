<template>
  <Dashboard>
    <div class="max-w-full pt-2.5">
      <!-- Server Info Section -->
      <div class="flex flex-col md:flex-row pb-12 items-center md:items-start gap-y-6 md:gap-y-0" v-if="activeServer?.status == 'online'">
        <div
          class="min-w-[80px] w-[80px] h-20 rounded-full bg-gray-900 border border-gray-700 flex justify-center items-center"
        >
          <img class="w-9 h-9" src="../assets/icons/bigserver.svg">
        </div>
        <div class="flex w-full">
          <div class="flex flex-col justify-center items-center md:items-start md:pl-4 gap-y-2 flex-1">
            <div class="flex flex-row items-center gap-3">
              <div class="px-2 py-1 gap-x-1.5 flex items-center bg-success-900 rounded-md">
                <div class="w-1.5 h-1.5 rounded-full bg-success-500 ring-2 ring-success-700"></div>
                <h2 class="text-xs text-success-500 text-medium">{{ capitalizeFirstLetter(activeServer.status) }}</h2>
              </div>
              <div class="px-2 py-1 gap-x-1.5 flex items-center bg-gray-900 rounded-md">
                <img src="../assets/icons/locationpin.svg">
                <h2 class="text-xs text-text-secondary text-medium">{{ activeServer.server_location }}</h2>
              </div>
              <div class="w-[1px] h-4 bg-gray-800"/>
              <div class="px-2 py-1 gap-x-1.5 flex items-center bg-gray-900 rounded-md">
                <img src="../assets/icons/passwordkey.svg">
                <h2 class="text-xs text-text-secondary text-medium">{{ activeServer.password }}</h2>
              </div>
            </div>
            <div class="flex gap-x-2">
              <h3 class="text-2xl leading-6 font-medium text-white">
                {{ activeServer.ip }}:{{ activeServer.port }}
              </h3>
              <button
                class="cursor-pointer"
                v-clipboard:copy="
                  `connect ${activeServer.ip}:${activeServer.port}; password ${activeServer.password}`
                "
                v-clipboard:success="copySucceeded"
              >
                <img
                  class="w-6 h-6 duration-300"
                  src="../assets/icons/clipboard.svg"
                  :class="[copySucceededBool ? 'green-filter' : '']"
                >
              </button>
            </div>
          </div>
          <div class="hidden md:flex items-center gap-x-3">
            <a
              class="flex items-center justify-center gap-x-1 px-3 py-2 rounded-md bg-primary-600 hover:bg-primary-500 transition-colors duration-200"
              :href="`steam://connect/${activeServer.raw_ip}:${activeServer.port}/${activeServer.password}`"
            >
              <img src="../assets/icons/play.svg" class="w-5 h-5 text-text-primary cursor-pointer brightness-200" />
              <h1 class="text-text-primary text-sm">Join</h1>
            </a>
            <button
              @click="toggleTerminate"
              class="flex align-center justify-center items-center bg-gray-800 hover:bg-gray-700 duration-200 cursor-pointer px-3 py-2 rounded-md"
            >
              <svg
                class="animate-spin h-full font-normal w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                v-if="isTerminating"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <img class="w-5 h-5" src="../assets/icons/x.svg" v-else-if="!terminateSucceeded" />
              <CheckCircleIcon class="w-4" v-else />
            </button>
          </div>
        </div>
        <div class="flex md:hidden">
          <button
            @click="toggleTerminate"
            class="flex align-center justify-center items-center bg-gray-800 hover:bg-gray-700 duration-200 cursor-pointer px-3 py-2 rounded-md"
          >
            <svg
              class="animate-spin h-full font-normal w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              v-if="isTerminating"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <div class="flex text-text-secondary gap-x-2 items-center" v-else-if="!terminateSucceeded">
              <img class="w-5 h-5" src="../assets/icons/x.svg"  />
              Terminate
            </div>
            <CheckCircleIcon class="w-4" v-else />
          </button>
        </div>
      </div>

      <div class="flex flex-row pb-12 gap-6" v-if="activeServer?.status !== 'online'">
        <div
          class="min-w-[80px] h-20 rounded-full bg-gray-900 border border-gray-700 flex justify-center items-center"
        >
          <img class="w-9 h-9" src="../assets/icons/clock.svg">
        </div>
        <div class="flex flex-col gap-2 justify-center">
          <h1 class="text-text-primary text-2xl font-bold loading">Your server is loading</h1>
          <h2 class="text-text-secondary text-sm ">Please wait, this shouldn't take long.</h2>
        </div>
      </div>

      <!-- Mod Menu -->
      <div v-if="activeServer?.status == 'online' && !activeServer.is_scrim">
        <!-- navbar -->
        <nav class="w-full flex flex-row relative gap-x-9 border-b border-gray-800">
          <div
            ref="setupDiv"
            class="pb-[14px] cursor-pointer"
            @click="updateActiveTab('setup')"
          >
            <h3 class="font-medium leading-6 text-text-primary">Setup</h3>
          </div>
          <div
            ref="subscribedDiv"
            class="pb-[14px] cursor-pointer"
            @click="updateActiveTab('subscribed')"
          >
            <h3 class="font-medium leading-6 text-text-primary">Subscribed Sets</h3>
          </div>
          <div
            ref="infoDiv"
            class="pb-[14px] cursor-pointer"
            @click="updateActiveTab('info')"
          >
            <h3 class="font-medium leading-6 text-text-primary">Info</h3>
          </div>
          <div class="absolute flex-row flex bottom-0">
            <div
              class="h-[2px] grow-transition"
              :style="{ width: getSectionUnderlinePadding() + 'px' }"
            />
            <div
              ref="sectionUnderline"
              class="h-[2px] bg-primary-500 grow-transition"
              :style="{ width: getSectionUnderlineWidth() + 'px' }"
            />
          </div>
        </nav>
        <div>
          <ServerSetup
            :activeServer="activeServer"
            :activeTeam="activeTeam"
            @submitCommand="submitCommand"
            v-if="activeTab === 'setup'"
          ></ServerSetup>
          <ServerSubscribedContent
            :activeServer="activeServer"
            :activeTeam="activeTeam"
            @submitCommand="submitCommand"
            v-if="activeTab === 'subscribed'"
          ></ServerSubscribedContent>
          <ServerInfo
            :activeServer="activeServer"
            :activeTeam="activeTeam"
            @submitCommand="submitCommand"
            v-if="activeTab === 'info'"
          ></ServerInfo>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script>
import axios from "axios";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import ServerSetup from "../components/ServerManager/ServerSetup.vue";
import ServerSubscribedContent from "../components/ServerManager/ServerSubscribedContent.vue";
import { ref, computed } from "vue";
import {
  CheckCircleIcon,
  ClipboardCopyIcon,
  XCircleIcon,
  ArrowCircleRightIcon,
  ExclamationCircleIcon,
  PlayIcon,
} from "@heroicons/vue/solid";
import { useStore, mapMutations } from "vuex";
import ServerInfo from "../components/ServerManager/ServerInfo.vue";

export default {
  head: {
    title: "Refrag | Server Manager",
  },
  components: {
    Dashboard,
    ServerSetup,
    CheckCircleIcon,
    ClipboardCopyIcon,
    XCircleIcon,
    ArrowCircleRightIcon,
    ServerInfo,
    ExclamationCircleIcon,
    PlayIcon,
    ServerSubscribedContent,
  },
  setup() {
    const store = useStore();
    const activeTeam = ref(store.getters.activeTeam);
    const activeServer = computed(() => store.getters.activeServer);
    const activeTab = ref("setup");
    const showKickModal = ref(false);
    const kickPlayer = ref();
    const loading = ref(true);
    const subscribedDiv = ref();
    const setupDiv = ref();
    const infoDiv = ref();
    const sectionUnderline = ref(null);
    const sectionUnderlineWidth = ref(40);
    const sectionUnderlinePadding = ref(0);
    const copySucceededBool = ref(false);
    const isTerminating = ref(false);
    const terminateSucceeded = ref(false);

    function updateActiveTab(value) {
      activeTab.value = value;
      updateUnderlineStyle();
    }

    function toggleTerminate() {
      if (isTerminating.value || terminateSucceeded.value) return;
      if (confirm("Are you sure you want to terminate the server?")) {
        isTerminating.value = true;
        axios({
          url: `/cs_servers/${activeServer.value.id}`,
          method: "DELETE",
          headers: {
            "X-TEAM-ID": activeTeam.value.id,
          },
        })
          .then(() => {
            terminateSucceeded.value = true;
            isTerminating.value = false;
          })
          .catch((error) => {
            terminateSucceeded.value = false;
            isTerminating.value = false;
          });
      }
    }

    function toggleKickModel(player) {
      kickPlayer.value = player;
      showKickModal.value = !showKickModal.value;
    }

    function copySucceeded() {
      copySucceededBool.value = true;
      setTimeout(() => {
        copySucceededBool.value = false;
      }, 1000);
    }

    function updateUnderlineStyle() {
      if (activeTab.value === "setup") {
        sectionUnderlineWidth.value = setupDiv.value?.offsetWidth;
        sectionUnderlinePadding.value = setupDiv.value?.offsetLeft;
      } else if (activeTab.value === "subscribed") {
        sectionUnderlineWidth.value = subscribedDiv.value?.offsetWidth;
        sectionUnderlinePadding.value = subscribedDiv.value?.offsetLeft;
      } else if (activeTab.value === "info") {
        sectionUnderlineWidth.value = infoDiv.value?.offsetWidth;
        sectionUnderlinePadding.value = infoDiv.value?.offsetLeft;
      }
    }

    function getSectionUnderlineWidth() {
      return sectionUnderlineWidth.value;
    }

    function getSectionUnderlinePadding() {
      return sectionUnderlinePadding.value;
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function submitCommand(data) {
      axios({
        url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
        method: "POST",
        data: {
          command: data.command,
          value: data.value,
          is_client_command: data.is_client_command,
        },
        headers: {
          "X-TEAM-ID": activeTeam.value.id,
        },
      });
    }
    return {
      activeTeam,
      activeServer,
      activeTab,
      showKickModal,
      kickPlayer,
      loading,
      subscribedDiv,
      setupDiv,
      infoDiv,
      sectionUnderline,
      sectionUnderlinePadding,
      sectionUnderlineWidth,
      copySucceededBool,
      updateActiveTab,
      toggleKickModel,
      updateUnderlineStyle,
      getSectionUnderlinePadding,
      getSectionUnderlineWidth,
      submitCommand,
      copySucceeded,
      capitalizeFirstLetter,
      toggleTerminate,
    };
  },
  mounted() {
    this.subscribeToServer();
  },
  methods: {
    ...mapMutations(["updateActiveTeamData"]),
    subscribeToServer() {
      axios({
        url: "/cs_servers/running",
        method: "GET",
        headers: {
          "X-TEAM-ID": this.$store.state.activeTeam.id,
        }
      }).then((response) => {
        if(!response.data?.id) {
          this.$store.state.activeServer = null;
          this.$router.push("/");
        }
        this.$store.state.activeServer = response.data;
        this.activeServer = response.data;
        let websocket = this.$cable.connect();
        websocket.subscriptions.create(
          { channel: "CsServerChannel", id: response.data.id },
          {
            received: (data) => {
              if(data.type === "cs_server_terminated") {
                this.$router.push("/");
              }
              this.$store.state.activeServer = data;
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
};
</script>

<style scoped>
.grow-transition {
  transition: width 0.3s;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1.5s infinite;
  animation: ellipsis steps(4, end) 1.5s infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

.green-filter {
  filter: invert(64%) sepia(76%) saturate(2536%) hue-rotate(82deg) brightness(112%) contrast(120%);
}

@keyframes ellipsis {
  to {
    width: 22px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 22px;
  }
}
</style>
