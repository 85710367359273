<template>
  <div class="h-full" v-if="route.name">
    <metainfo>
      <template v-slot:title="{ content }">Refrag | {{ content }}</template>
    </metainfo>
    <router-view />
  </div>
  <div v-else class="h-full flex justify-center items-center">
    <p class="text-white">
      <svg
        class="animate-spin h-full font-normal w-12"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </p>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    return { route };
  },
};
</script>
