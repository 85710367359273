<template>
    <div class="flex flex-col h-full items-center lg:justify-center w-full lg:w-[640px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Welcome to Refrag!</h1>
        <h2 class="text-text-secondary text-center pb-5  md:px-32">Below, you can find information about core Refrag features. If you're ready, click 'continue' so we can help you set up your account.</h2>

        <div class="flex flex-col gap-4">
            <div class="flex w-full flex-col lg:flex-row p-6 gap-6 border border-gray-800 rounded-md gap-2">
                <video class="flex-1 w-full lg:w-[55%] object-cover rounded-md" autoplay loop muted playsinline>
                    <source src="../../assets/aim-gif.webm" type="video/webm" />
                </video>
                <div class="flex flex-col flex-1 gap-3">
                    <h1 class="text-text-primary text-lg font-medium">Aim like a headshot machine</h1>
                    <p class="text-text-secondary text-sm font-thinner pr-2">
                        Our aim mods contain fun fast paced single and duo player game modes where you practice shooting bots that simulate real scenarios.
                    </p>
                </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-4">
                <div class="flex-1 rounded-md border border-gray-800 pt-5">
                    <h1 class="text-text-primary text-lg font-medium px-5 pb-3">No more missed nades</h1>
                    <p class="text-text-secondary text-sm font-thinner px-5 pb-5">
                        With our nade practice tool, you can practice your nades on any map.
                    </p>
                    <div class="pl-5 rounded-tl-md">
                        <img class="h-32 w-full object-none sm:object-cover rounded-tl-md rounded-bl-md" src="../../assets/nade-preview.png" />
                    </div>
                </div>
                <div class="flex-1 rounded-md border border-gray-800 pt-5">
                    <h1 class="text-text-primary text-lg font-medium px-5 pb-3">Your server, Your rules</h1>
                    <p class="text-text-secondary text-sm font-thinner px-5 pb-5">
                        Play any of our game modes on a server from 25 different locations.
                    </p>
                    <div class="pl-5 rounded-tl-md">
                        <img class="h-32 w-full object-none sm:object-cover rounded-tl-md rounded-bl-md" src="../../assets/server-manager.png" />
                    </div>
                </div>
            </div>
            
            <div @click="emit('updateOnboardingStep', { value: props.currentStep + 1 })" class="cursor-pointer w-full py-3 mb-4 lg:mb-0 text-text-primary bg-primary-600 hover:bg-primary-500 rounded-md flex items-center justify-center gap-1">
                Continue
                <img class="h-4 brightness-200" src="../../assets/icons/rightarrow.svg" alt="Continue" />
            </div>
        </div>
    </div>
</template>

<script setup>

const emit = defineEmits(['updateOnboardingStep'])

const props = defineProps({
    currentStep: Number
});

</script>