<template>
  <form class="space-y-4">
    <div>
      <div class="text-center">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Are you sure you want to remove {{ this.removeUser?.email }}?
        </h3>
      </div>
    </div>
    <div class="pt-5">
      <div class="flex justify-center">
        <button
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          @click="removePlayer"
        >
          Remove
        </button>
        <button
          class="ml-3 float-right inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  props: ["removeUser"],
  computed: {
    ...mapState(["activeTeam"]),
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    removePlayer(e) {
      e.preventDefault();
      axios({
        url: `/teams/${this.activeTeam.id}/remove_user`,
        method: "POST",
        headers: {
          "X-TEAM-ID": this.activeTeam.id,
        },
        data: {
          user_id: this.removeUser.id,
        },
      })
        .then((response) => {
          this.showMessage({
            messageText: "Player removed",
            messageType: "success",
          });
          this.$emit("removePlayerSucceeded");
          this.$emit("toggleModal");
        })
        .catch((error) => {
          this.showMessage({
            messageText: "Error removing player",
            messageType: "error",
          });
        }).finally(() => {
          setTimeout(() => {
            this.hideMessage();
          }, 3000);
        });
    },
    cancel(e) {
      e.preventDefault();
      this.$emit("toggleModal");
    },
  },
};
</script>
