<template>
  <div class="flex-row items-center flex relative bg-gray-900 border border-gray-700 rounded-md text-text-secondary h-8">
    <div
      class="relative text-sm focus:ring flex-row flex shadow-inner cursor-pointer pl-3 h-full items-center"
      v-clipboard:copy="`connect ${ip}:${port}; password ${password}`"
      v-clipboard:success="copySucceeded"
    >
      <p
        class="flex items-center"
      >
        {{ `connect ${ip}:${port}; password ${password}` }}
      </p>
      <div class="mx-3 w-[1px] h-2/3 bg-gray-700"/>
      <div class="flex align-center justify-center cursor-pointer">
        <img src="../assets/icons/clipboard.svg" v-if="!state.copySucceeded" />
        <CheckCircleIcon class="w-4 text-green-400" v-else />
      </div>
      <div class="mx-3 w-[1px] h-2/3 bg-gray-700"/>
      <a class="flex align-center justify-center cursor-pointer" 
        :href="`steam://connect/${raw_ip}:${port}/${password}`">
        <img src="../assets/icons/play.svg"/>
      </a>
    </div>
    <div class="mx-3 w-[1px] h-2/3 bg-gray-700"/>
    <button
      @click="toggleTerminate"
      class="flex align-center justify-center items-center pr-3 cursor-pointer"
      :class="{
        'text-white border-transparent': !state.terminateSucceeded,
        'bg-green-500 border-green-500 text-white': state.terminateSucceeded,
      }"
    >
      <svg
        class="animate-spin h-full font-normal w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        v-if="state.isTerminating"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <img src="../assets/icons/x.svg" v-else-if="!state.terminateSucceeded" />
      <CheckCircleIcon class="w-4" v-else />
    </button>
  </div>
  <button
    @click="navToServer"
    type="button"
    class="inline-flex ml-3 rounded-md items-center px-3 h-8 border select-none border-transparent text-sm text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
  >
    Manage
  </button>
    
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { ClipboardCopyIcon, CheckCircleIcon, XCircleIcon, PlayIcon } from "@heroicons/vue/outline";

export default {
  data() {
    return {
      state: {
        copySucceeded: false,
        terminateSucceeded: false,
        isTerminating: false,
      },
    };
  },
  props: {
    ip: String,
    raw_ip: String,
    port: Number,
    password: String,
    id: Number,
    isAssessment: Boolean,
  },
  components: {
    ClipboardCopyIcon,
    CheckCircleIcon,
    XCircleIcon,
    PlayIcon,
},
  computed: {
    ...mapState(["activeTeam"]),
  },
  methods: {
    ...mapMutations(["showMessage", "updateActiveTeamData", "setActiveServer"]),
    navToServer() {
      this.$router.push("/server_manager")
    },
    copySucceeded() {
      this.state.copySucceeded = true;
      setTimeout(() => {
        this.state.copySucceeded = false;
      }, 1000);
    },
    finishTerminate() {
      this.state.terminateSucceeded = true;
      this.state.isTerminating = false;
      setTimeout(() => {
        this.updateActiveTeamData(this.$auth);
        this.setActiveServer({});
      }, 2000);
    },
    toggleTerminate() {
      if (this.isTerminating || this.state.terminateSucceeded) return;
      if (confirm("Are you sure you want to terminate the server?")) {
        this.state.isTerminating = true;
        axios({
          url: `/cs_servers/${this.id}`,
          method: "DELETE",
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
          .then(() => {
            this.finishTerminate();
          })
          .catch(() => {
            this.showMessage({
              messageType: "error",
              messageText:
                "Failed to terminate server, please wait 10 minutes or contact support.",
            });
          });
      }
    },
  },
};
</script>
