<template>
  <form class="space-y-4">
    <div>
      <div>
        <h3 class="text-lg leading-6 font-medium text-white">Invite a player</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-300">
          Add a player to your team. This will give them the player role and all your
          resources will be shared with them.
        </p>
      </div>

      <div class="mt-6">
        <div class="">
          <label for="emails" class="block text-sm font-medium text-white">
            Email addresses
          </label>
          <div class="mt-1">
            <textarea
              id="emails"
              name="emails"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border bg-gray-900 text-white border-gray-300 rounded-md"
              v-model="playerText"
            />
          </div>
          <p class="mt-2 text-sm text-gray-300">
            Fill in your players email addresses, separated by commas.
          </p>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          disabled
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400"
          v-if="isInviting"
        >
          Inviting Players...
        </button>
        <button
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click="invitePlayers"
          v-else
        >
          Invite
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      players: [],
      playerText: "",
      isInviting: false,
    };
  },
  props: {},
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    team() {
      //Checks to see which team to display based on activeTeamId
      for (var i = 0; i < this.user.teams.length; i++) {
        if (this.user.teams[i].id === Number(localStorage.activeTeamId)) {
          return this.user.teams[i];
        }
      }
      return this.user.teams[0] || {};
    },
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    invitePlayers(e) {
      this.hideMessage();
      e.preventDefault();
      this.players = this.playerText.replace(/\s/g, "").split(",");
      this.players.forEach((player) => {
        if (!this.validateEmail(player)) {
          this.showMessage({
            messageType: "error",
            messageText: `${player} is not a valid email address`,
          });
          this.isInviting = false;
          return;
        }
        axios({
          url: "/team_invites/",
          method: "POST",
          headers: {
            "X-TEAM-ID": this.team.id,
          },
          data: {
            email: player,
          },
        })
          .then(() => {
            this.showMessage({
              messageType: "success",
              messageText: `${player} was invited!`,
            });
            this.isInviting = true;
            this.$emit("inviteSuccessful");
            this.$emit("toggleModal");
          })
          .catch((err) => {
            this.showMessage({
              messageType: "error",
              messageText: `Error inviting ${player}!`,
            });
            this.isInviting = false;
          });
      });
    },
    validateEmail(email) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
  },
};
</script>
