<template>
  <DarkFull>
    <div class="min-h-full flex flex-col bg-gray-950">
      <div class="w-full h-16 border-b border-gray-900 flex items-center justify-center">
        <img class="mx-auto h-6 w-auto" :src="Logo" alt="Refrag Logo" />
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex-1 flex items-center justify-center">
        <div class="py-8 px-4 shadow sm:rounded-md sm:px-6 bg-gray-900 rounded-lg">
          <div class="space-y-6">
            <div class="flex flex-col w-full items-center border-b border-gray-800 pb-6">
              <h1 class="text-text-primary font-semibold text-[24px]">Change Email</h1>
              <h2 class="text-text-secondary text-center">To change your email, please enter the new email and current password.</h2>
            </div>  
            <div>
              <label for="email" class="block text-sm font-medium text-text-primary">
                New email address
              </label>
              <div class="mt-2">
                <input
                  v-model="newEmail"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder="Enter your new email"
                  required="true"
                  class="text-text-primary bg-gray-800 appearance-none block w-full px-3 py-3 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label for="password" class="block text-sm font-medium text-text-primary">
                Current password
              </label>
              <div class="mt-2">
                <input
                  v-model="currentPassword"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="password"
                  placeholder="Enter your password"
                  required="true"
                  class="text-text-primary bg-gray-800 appearance-none block w-full px-3 py-3 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
            </div>

            <!-- info about wrong password or email already in use -->
            <div v-if="error" class="text-danger-400 text-sm w-full py-2 px-3 border-danger-500 rounded-lg border flex items-center gap-2">
              <img class="w-4 h-4" src="../assets/icons/error.svg" />
              <span>{{ error }}</span>
            </div>

            <div>
              <button
                @click="updateEmail"
                type="submit"
                class="w-full flex items-center gap-2 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary duration-300 bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Update Email
                <!-- spinner -->
                <svg v-if="loading" width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="animate-spin">
                  <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2"/>
                  <path d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16" stroke="#597497" stroke-width="2"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DarkFull>
</template>

<script setup>
import axios from "axios";
import Logo from "../assets/logos/refrag-horz-fill.svg";
import DarkFull from "../components/layouts/DarkFull.vue";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const newEmail = ref("");
const currentPassword = ref("");
const user = ref(null);
const store = useStore();
const error = ref(null);
const loading = ref(false);

// axios get user by token
axios.get(`/users/user_by_email_token/${route.params.token}`).then((response) => {
  user.value = response.data.data;
}).catch((error) => {
  router.push("/login");
})

function updateEmail() {
  error.value = null;
  loading.value = true;
  axios({
    url: "/users/update_email",
    method: "PUT",
    headers: {},
    params: {
      token: route.params.token,
      password: currentPassword.value,
      email: newEmail.value,
    },
  }).then((res) => {
    loading.value = false;
    store.commit("showMessage", {
      messageType: "success",
      messageText: "Email Updated Successfully",
    });
    setTimeout(() => {
      router.push("/login");
      store.commit("hideMessage");
    }, 3000);
  }).catch((err) => {
    loading.value = false;
    if (err.response?.data?.message === 'Invalid password') {
      error.value = "Wrong password";
    } else {
      error.value = "Email already in use";
    }
  });
}

</script>
