<template>
  <div class="flex flex-row border-y border-gray-700">
    <div class="teamImage py-5 px-4 text-white">
      <span>{{ team.m_szClanTeamname }}</span>
    </div>
    <div class="flex flex-1 flex-row">
      <RoundResult
        :round="round"
        :team="team"
        v-for="round in rounds"
        :key="`${JSON.stringify(team)}${JSON.stringify(round)}`"
        :selectRound="selectRound"
        :totalRounds="rounds.length"
        class="border-r border-gray-700 py-5"
      />
    </div>
  </div>
</template>

<script>
import RoundResult from "./RoundResult.vue";

export default {
  name: "TeamRow",
  props: {
    team: Object,
    rounds: Array,
    selectRound: Function,
  },
  components: {
    RoundResult,
  },
};
</script>

<style lang="scss">
.roundHistory {
  &.columns > .column {
    padding: 0.75rem 0.1rem !important;
  }
  i {
    font-size: 21px;
  }
}
</style>
