<template>
  <Dashboard title="Custom Content Page" no-padding="true">
    <div class="w-full h-full flex flex-col relative">
      <div class="flex flex-col">
        <div class="flex relative flex-col">
          <div class="absolute left-0 top-0 w-full h-full z-10">
            <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
            <img class="absolute left-0 top-0 h-full w-full object-cover object-center" :src="`/maps/${content?.cs_map.name}_cs2.png`">
          </div>
          <div class="flex w-full px-9 py-12 z-20">
            <div class="flex flex-col lg:flex-row gap-y-3 w-full border-b pb-12 border-gray-800">
              <div class="flex flex-col gap-3 flex-1">
                <h1 class="text-text-primary font-semibold text-4xl">{{ content?.title }}</h1>
                <div v-if="!editing" class="flex gap-2 items-center">
                  <h2 class="text-text-secondary text-sm font-light">{{ content?.description }}</h2>
                  <button
                    v-if="canEdit()"
                    @click="editDescription"
                    class="inline-flex px-2 py-1 mr-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Edit</span>
                  </button>
                </div>
                <dd v-else class="mt-1 text-sm text-text-secondary sm:mt-0 sm:col-span-2 w-1/2 flex items-center">
                  <input
                    class="block w-full mr-4 rounded-md bg-gray-800 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                    type="text"
                    v-model="description"
                  />
                  <button
                    @click="saveDescription"
                    class="inline-flex px-2 py-1 mr-4 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Save</span>
                  </button>
                  <button
                    @click="editDescription"
                    class="inline-flex px-2 py-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Cancel</span>
                  </button>
                </dd>
                <div v-if="!editingHelp" class="flex gap-2 items-center">
                  <h2 class="text-text-secondary text-sm font-light">{{ content?.help }}</h2>
                  <button
                    v-if="canEdit()"
                    @click="editHelp"
                    class="inline-flex px-2 py-1 mr-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Edit</span>
                  </button>
                </div>
                <dd v-else class="mt-1 text-sm text-text-secondary sm:mt-0 sm:col-span-2 w-1/2 flex items-center">
                  <input
                    class="block w-full mr-4 rounded-md bg-gray-800 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                    type="text"
                    v-model="help"
                  />
                  <button
                    @click="saveHelp"
                    class="inline-flex px-2 py-1 mr-4 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Save</span>
                  </button>
                  <button
                    @click="editHelp"
                    class="inline-flex px-2 py-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  >
                      <span>Cancel</span>
                  </button>
                </dd>
              </div>
              <div class="flex flex-col gap-3">
                <button
                  :disabled="!activeServer"
                  @click="launchContent"
                  :class="[!activeServer ? 'bg-gray-600' : 'bg-primary-600 hover:bg-primary-500 duration-300']"
                  class="w-full has-tooltip lg:w-auto text-text-primary rounded-lg flex gap-2 py-2 items-center justify-center">
                  <span v-if="!activeServer" href="https://discord.gg/Refrag" class="tooltip rounded shadow-md p-1 mt-5 bg-gray-900 text-white top-16 min-w-32"
                    >Please start a server to launch a set</span
                  >
                  <svg
                    v-if="launchLoading"
                    class="animate-spin h-full font-normal w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <ThumbUpIcon v-else-if="launchSuccess" class="w-3 h-3 text-green-500" />
                  <img v-else src="../../assets/icons/play.svg" alt="play" class="w-3 h-3 brightness-200" />
                  <h1 class="text-xs">Launch Set</h1>
                </button>
                <div class="flex gap-3">
                  <button @click="unsubscribe" v-if="content?.is_subscribed" class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center">
                    <PlusCircleIcon class="w-3 h-3 text-text-primary" />
                    <h1 class="text-xs">Unsubscribe</h1>
                  </button>
                  <button @click="subscribe" v-else class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center">
                    <PlusCircleIcon class="w-3 h-3 text-text-primary" />
                    <h1 class="text-xs">Subscribe</h1>
                  </button>
                  <button class="w-full lg:w-auto text-text-primary bg-gray-800 rounded-lg flex gap-2 py-2 px-3 items-center justify-center"
                    v-clipboard:copy="`http://${url}/community_hub${modUrl}${content?.id}`"
                    v-clipboard:success="copySucceeded"
                  >
                    <img src="../../assets/icons/copy.svg" alt="subscribe" class="w-3 h-3 brightness-200" />
                    <h1 class="text-xs">Share</h1>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full px-9 z-20">
            <div class="w-full border-b border-gray-800">
              <h2 class="text-text-primary text-xl font-semibold pb-4">Details</h2>
              <div class="flex flex-col lg:flex-row gap-12 pb-12">
                <div v-if="content?.video_url" class="flex-1 bg-gray-900 rounded-lg">
                  <iframe class="w-full aspect-[16/9] rounded-lg"
                      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                      :src="content?.video_url + '&autoplay=1&mute=1'"
                      loading="lazy">
                  </iframe>
                </div>
                <div class="grid gap-6 flex-1"
                    :class="[content?.video_url ? 'grid-cols-1' : 'lg:grid-cols-2 grid-cols-1']">
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Author</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ getAuthorUsername() }}</h3>
                  </div>
                  <div v-if="modUrl === '/grenade_groups/'" class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Grenades</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ content?.grenade_count }}</h3>
                  </div>
                  <div class="flex gap-3" v-if="modUrl === '/custom_sets/' || modUrl === '/strategy/'">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Tags</h3>
                    <div v-if="!editingTags" class="flex gap-3">
                      <h3 class="text-text-primary text-sm font-semibold">{{ getActiveTags() }}</h3>
                      <button
                        v-if="canEdit()"
                        @click="editTags"
                        class="inline-flex px-2 py-1 mr-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                      >
                          <span>Edit</span>
                      </button>
                    </div>
                    <div v-else class="flex items-center flex-1 col-span-2">
                      <span class="space-x-1 flex-1 flex mr-2 justify-end">
                        <button v-for="tag in getTags()" :key="tag" type="button"
                          @click="toggleTag(tag)"
                          class="rounded-md px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset"
                          :class="[isTagActive(tag.name) ? 'text-indigo-500 ring-indigo-500 hover:ring-indigo-300' : 'text-slate-300 ring-slate-500 hover:ring-gray-100']"
                        >
                          {{ tag.name }}
                        </button>
                      </span>
                      <button
                        @click="editTags"
                        class="inline-flex px-2 py-1 items-centerborder border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                      >
                        <span>Done</span>
                      </button>
                    </div>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Subscriptions</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ content?.subscription_count }}</h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Plays</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ content?.play_count }}</h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Created At</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ moment(content?.created_at).format("LLL") }}</h3>
                  </div>
                  <div class="flex gap-3">
                    <h3 class="text-text-secondary text-sm font-light flex-1">Last Updated</h3>
                    <h3 class="text-text-primary text-sm font-semibold">{{ moment(content?.updated_at).format("LLL") }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-9 py-12">
          <h2 class="text-text-primary text-xl font-semibold pb-4">More Like This</h2>
          <div class="flex flex-col gap-1 lg:gap-[2px] rounded-lg w-full overflow-hidden">
            <ContentRow
              ref="contentRow"
              :content="row"
              :modUrl="convertModUrl()"
              :activeMod="getModName()"
              :activeServer="activeServer"
              @loadContent="loadContent"
              v-for="row in relatedContent"
              :key="row?.id"
            >
            </ContentRow>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal :server="modalServerInfo" :show="showServerModal" @close="showServerModal = false"/>
</template>

<script setup>
import { watch, ref, computed } from "vue";
import axios from "axios";
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import moment from "moment";
import { PlayIcon, PlusCircleIcon, ThumbUpIcon } from "@heroicons/vue/solid";
import ContentRow from "../../components/CommunityHub/ContentRow.vue"
import StartServerModal from "../../components/StartServerModal.vue"
import { useHead } from '@unhead/vue'

const route = useRoute();
const store = useStore();
const activeTeam = ref(store.getters.activeTeam);
const activeServer = computed(() => store.getters.activeServer);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const content = ref();
const loading = ref(true);
const url = ref(location.host);
const editing = ref(false);
const editingHelp = ref(false);
const editingTags = ref(false);
const modalServerInfo = ref();
const showServerModal = ref(false);
const relatedContent = ref();
const description = ref()
const help = ref();
const auth = useAuth();
let user = auth.user();
let modUrl = route.path.replace('/community_hub', '')
modUrl = modUrl.replace(/[0-9]/g, '')

useHead({
  title: () => `Refrag | ${content?.title || 'Custom Content Page'}`,
})

function updateModalServerInfo(info) {
  modalServerInfo.value = info;
  showServerModal.value = true;
}

function launchContent() {
  if (!activeServer.value.id) {
    let serverInfo = {
      cs_map: content.value.cs_map,
      mode: getModName(),
      content: content.value,
    }
    updateModalServerInfo(serverInfo);
    return;
  }

  launchLoading.value = true;
  let command = "";
  let value = "";

  if (modUrl === "/custom_sets/") {
    command = "load_set";
    value = `custom_set, ${route.params.id}`;
  } else if (modUrl === "/grenade_groups/") {
    command = "load_nadr";
    value = `grenades, ${route.params.id}`;
  } else if (modUrl === "/strategy/") {
    command = "load_nadr";
    value = `strat, ${route.params.id}`;
  } else if (modUrl === "/vpl/") {
    command = "load_vpl";
    value = `${route.params.id}`;
  }

  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      "command": command,
      "value": value,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  }).then(() => {
    launchSuccess.value = true;
    launchLoading.value = false;
    setTimeout(() => {
      launchSuccess.value = false;
    }, 2000);
  }).catch((err) => {
    this.showMessage({
      messageType: "error",
      messageText: `Could not execute server command!`,
    });
  });
}

function tagArray() {
  if (!content.value?.active_tags) return ''
  return Array.prototype.map.call(content.value?.active_tags, function(tag) { return `filters[tags][]=${tag.name}` }).join('&');
}

function serializeParams(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function getRelatedContent() {
  if (!activeTeam.value) return;
  loading.value = true;

  const params = serializeParams({
    "filters[map]": content.value?.map || '',
    page: 1,
    per_page: 6
  }).concat(tagArray())

  axios({
    url: `${modUrl}?${params}`,
    method: "GET",
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    switch (modUrl) {
      case "/custom_sets/":
        relatedContent.value = response.data.custom_sets.filter((item) => item.id !== content.value.id);
        break;
      case "/grenade_groups/":
        relatedContent.value = response.data.grenade_groups.filter((item) => item.id !== content.value.id);
        break;
      case "/strategy/":
        relatedContent.value = response.data.solo_recordings.filter((item) => item.id !== content.value.id);
        break;
      case "/vpl/":
        relatedContent.value = response.data.solo_recordings.filter((item) => item.id !== content.value.id);
        break;
    }
    loading.value = false;
  });
}

function canEdit() {
  return (user.id === content.value?.author_id)
}

function editDescription() {
  editing.value = !editing.value
}

function editHelp() {
  editingHelp.value = !editingHelp.value
}

function editTags() {
  editingTags.value = !editingTags.value
}

function getActiveTags() {
  if (!content.value?.active_tags) return ''
  return Array.prototype.map.call(content.value?.active_tags, function(tag) { return tag.name; }).join(" | ");
}

function isTagActive(name) {
  return content.value?.active_tags.filter(tag => tag.name === name).length > 0;
}

function getTags() {
  let modName = 'CreatorStudio'
  if (modUrl === "/grenade_groups/") {
    modName = 'NADR'
  } else if (modUrl === "/strategy/") {
    modName = 'Restrat'
  } else if (modUrl === "/vpl/") {
    modName = 'VPL'
  }
  let tag_categories = store.state.modInfo?.mods.find(({ name }) => name === modName)?.tag_categories
  let tags = []
  tag_categories.forEach(category => {
    category.tags.forEach(tag => {
      tags.push(tag)
    })
  })
  return tags
}

function toggleTag(tag) {
  axios({
    method: "PUT",
    url: `${modUrl}${route.params.id}/tag`,
    params: {
      tag_id: tag.id
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then(() => {
    getContent();
  });
}

function getModName() {
  if(modUrl === "/custom_sets/") {
    return "Creator Studio"
  } else if(modUrl === "/grenade_groups/") {
    return "Grenade Groups"
  } else if(modUrl === "/strategy/") {
    return "Strategies"
  } else if (modUrl === "/vpl/") {
    return "VPL"
  }
}

function copySucceeded() {
  store.commit("showMessage", {messageType: "success", messageText: "Link Copied Successfully"})
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

function saveDescription() {
  axios({
    method: "PUT",
    url: `${modUrl}/${route.params.id}`,
    data: {
      description: description.value
    }
  }).then(() => {
    editDescription();
    getContent();
  });
}

function saveHelp() {
  axios({
    method: "PUT",
    url: `${modUrl}/${route.params.id}`,
    data: {
      help: help.value
    }
  }).then(() => {
    editHelp();
    getContent();
  });
}

function convertModUrl() {
  if(modUrl === "/custom_sets/") {
    return "/custom_sets/"
  } else if(modUrl === "/grenade_groups/") {
    return "/grenade_groups/"
  } else if(modUrl === "/strategy/" || modUrl === "/vpl/") {
    return "/solo_recordings/"
  }
}

function getContent() {
  if (!activeTeam.value) return;
  loading.value = true;

  axios({
    url: `${convertModUrl()}/${route.params.id}`,
    method: "GET",
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
    }).then((response) => {
      content.value = response.data
      description.value = response.data.description
      getRelatedContent();
      loading.value = false
  })
}

function subscribe() {
  axios({
    url: `${convertModUrl()}subscribe`,
    method: "POST",
    params: {
      "id": route.params.id,
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then(() => {
    getContent();
  });
}

function unsubscribe() {
  axios({
    url: `${convertModUrl()}unsubscribe`,
    method: "DELETE",
    params: {
      "id": route.params.id,
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then(() => {
    getContent();
  });
}

function getAuthorLink() {
  if(content.value?.author_username) {
    return content.value?.author_username
  }
  return content.value?.author_uuid
}

function getAuthorUsername() {
  if(content.value?.author_username) {
    return content.value?.author_username
  }
  return content.value?.user?.personaname
}

watch(
  () => store.getters.activeTeam,
  (currentValue) => {
    activeTeam.value = currentValue;
    getContent();
  }
);

watch(
  () => route.params.id,
  (currentValue) => {
    getContent();
  }
);

getContent();

</script>

<style scoped>
.map-linear-gradient {
  background: linear-gradient(180deg, rgba(8, 10, 13, 0.9) 0%, #080A0D 100%);
}
</style>
