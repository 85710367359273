<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div class="flex flex-col h-full items-center mt-16 lg:mt-0 lg:justify-center w-full lg:w-[640px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">All ready!</h1>
        <h2 class="text-text-secondary text-center pb-6">You have completed the onboarding process! <br/>Here is a video to walk you through our platform.</h2>

        <div class="lg:px-12 w-full flex items-center">
            <div class="border border-gray-800 rounded-md w-full p-6 flex flex-col items-center">
                <iframe
                    src="https://customer-o1jnfnsdef83eyjx.cloudflarestream.com/a68fa80aadd03bbe160228d415553aa8/iframe?muted=true&preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-o1jnfnsdef83eyjx.cloudflarestream.com%2Fa68fa80aadd03bbe160228d415553aa8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&primaryColor=%239cadc4"
                    loading="lazy"
                    class="w-full aspect-[16/9]"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                ></iframe>

                <h1 class="font-medium text-xl text-text-primary pt-4">Refrag Tutorial</h1>
                <p class="font-light text-sm text-text-secondary pt-4 text-center px-8">This video will walk you through the process of starting your first Refrag server. This will help you navigate the website so you can spend more time improving!</p>
            </div>
            
        </div>

        <div @click="emit('finishOnboarding')" class="cursor-pointer w-full py-3 text-text-primary bg-primary-600 hover:bg-primary-500 mt-10 rounded-md flex items-center justify-center gap-1">
            Complete Onboarding
            <img class="h-4 brightness-200" src="../../assets/icons/rightarrow.svg" alt="Continue" />
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['updateOnboardingStep', 'finishOnboarding']);

const props = defineProps({
    currentStep: Number
});

</script>