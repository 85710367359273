<template>
    <div class="p-4 gap-6 bg-gray-900 rounded-md absolute flex flex-col w-[500px] z-50">
        <div class="flex gap-2 items-center w-full">
            <h1 class="text-text-primary text-xl font-bold flex-1">{{ props.data.title }}</h1>
            <XIcon class="h-5 w-5 cursor-pointer text-text-secondary" @click="emit('close')" />
        </div>
        <div class="text-text-secondary">
            {{ props.data.description }}
        </div>
        <img class="w-full aspect-[16/9]" :src="props.data.image" alt="Notification Image">
        <div @click="navToLink" class="bg-primary-500 px-4 py-3 hover:bg-primary-400 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md">
            Learn More
            <img class="brightness-200" src="../assets/icons/rightarrow.svg">
        </div>
    </div>
  </template>
  
  <script setup>

  import { XIcon } from '@heroicons/vue/solid';
  import { useRouter } from 'vue-router';
  import axios from 'axios';

  const emit = defineEmits(["close"]);
  const router = useRouter();

  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
  })

  function navToLink() {
    axios.post("/notifications/" + props.data.id + "/see");
    emit('close');
    router.push(props.data.link);
  }

  </script>