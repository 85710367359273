export function createClickablePointsPlugin(getMatchId, onPointClick) {
  return {
    id: 'clickablePoints',
    afterDraw: (chart) => {
      if (!chart.data.datasets || chart.data.datasets.length === 0) {
        return; // Exit early if no data
      }
      
      const ctx = chart.ctx;
      
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        dataset.data.forEach((datapoint, index) => {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (!meta.data[index]) return;

          const point = meta.data[index];
          
          ctx.beginPath();
          ctx.arc(point.x, point.y, 10, 0, Math.PI * 2);
          ctx.fillStyle = 'transparent';
          ctx.fill();
          ctx.closePath();
        });
      });

      chart.canvas.onclick = (event) => {
        const rect = chart.canvas.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          dataset.data.forEach((datapoint, index) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            if (!meta.data[index]) return;

            const point = meta.data[index];
            const distance = Math.sqrt(Math.pow(clickX - point.x, 2) + Math.pow(clickY - point.y, 2));

            if (distance <= 10) {
              const matchId = getMatchId(index);
              if (matchId) {
                onPointClick(matchId);
              }
            }
          });
        });
      };

      chart.canvas.onmousemove = (event) => {
        const rect = chart.canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;
        let isOverPoint = false;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          dataset.data.forEach((datapoint, index) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            if (!meta.data[index]) return;

            const point = meta.data[index];
            const distance = Math.sqrt(Math.pow(mouseX - point.x, 2) + Math.pow(mouseY - point.y, 2));

            if (distance <= 10) {
              isOverPoint = true;
            }
          });
        });

        chart.canvas.style.cursor = isOverPoint ? 'pointer' : 'default';
      };
    }
  };
}