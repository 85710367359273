<template>
  <div class="ml-4 h-8 flex flex-row group cursor-pointer">
    <div class="py-1 justify-center items-center gap-1 inline-flex">
      <div className="w-5 h-5 relative flex items-center text-red-500">
        <img 
          class="w-5 h-5" 
          src="../../../assets/icons/bolt.svg" 
          :class="{
            'animate-pulse': user.activity_streak > 0,
            'gray-bolt': user.activity_streak === 0 || !user.activity_streak,
          }"
        />
      </div>
      <div
        :class="streakTextClasses"
        class="flex items-center h-6 text-sm font-medium leading-none"
      >
        {{ user.activity_streak || 0 }}
      </div>
    </div>
    <div class="w-[1px] bg-gray-700 h-full ml-4 my-auto"></div>
    <StreaksPopUp :user="user" class="hidden group-hover:block" />
  </div>
</template>

<script>
import StreaksPopUp from './StreaksPopUp.vue';
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    StreaksPopUp,
  },
  computed: {
    streakTextClasses() {
      if (this.user.activity_streak > 0) {
        return "bg-gradient-to-b from-[#FB6D01] to-[#FBD201] text-transparent bg-clip-text";
      }
      return "text-text-secondary";
    },
    streakColors() {
      if (this.user.activity_streak > 0) {
        return {
          start: "#FBD201",
          stop: "#FB6D01",
        };
      }
      return {
        start: "rgb(148 163 184 / var(--tw-text-opacity))",
        stop: "rgb(148 163 184 / var(--tw-text-opacity))",
      };
    },
  },
};
</script>

<style scoped>

.gray-bolt {
  filter: brightness(0) saturate(100%) invert(77%) sepia(21%) saturate(312%) hue-rotate(175deg) brightness(88%) contrast(82%);
}

</style>
