<template>
  <div 
    :class="[props.isActive ? 'bg-gray-800 outline outline-1 outline-primary-500' : 'bg-gray-900']"
    class="overflow-hidden py-16 cursor-pointer rounded-md text-text-primary"
  >
    <dt class="text-sm truncate text-center text-text-secondary">
      {{ subtitle.toUpperCase() }}
    </dt>
    <dd class="mt-1 text-3xl font-semibold font-header flex flex-row align-center items-center justify-center">
      <img :src="imageURL" class="w-10 h-10 rounded-full mr-2" v-if="imageURL" />
      {{ title }}
    </dd>
  </div>
</template>

<script setup>
import { ref, toRefs } from "vue";

const props = defineProps({
  title: {
    type: [String, Number],
    required: true,
  },
  subtitle: String,
  imageURL: String,
  isActive: Boolean,
})

const name = ref("StatBlock")

</script>

<style lang="scss">
.statisticLabel {
  font-variant: small-caps;
}
</style>
