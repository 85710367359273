<template>
  <Dashboard title="Restrat Demos">
    <div class="rounded-t bg-gray-950">
      <div class="flex items-center justify-end flex-wrap sm:flex-nowrap">
        <div class="flex-1 pb-6">
          <h3 class="text-text-primary text-[28px] font-medium flex-grow">Community Hub</h3>
          <p class="text-sm leading-5 text-gray-300">
            Access Community Hub sets through the Creator Studio mode,
            <a
              class="text-indigo-400 underline"
              target="_blank"
              href="https://wiki.refrag.gg/en/community-hub"
            >
              more info here</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="bg-gray-950">
      <div class="overflow-x-auto">
        <div class="align-middle inline-block min-w-full">
          <div class="w-full flex gap-6 relative items-start">
            <div class="flex flex-col gap-4 flex-1">
              <div class="flex items-center gap-3 flex-col lg:flex-row">
                <h1 class="text-xl font-semibold text-text-primary flex-1">{{ activeMod }}</h1>
                <button v-if="activeMod === 'Creator Studio'" @click="toggleFilterMenu()" class="h-8 px-3 w-full lg:w-auto bg-gray-800 rounded-lg border border-gray-700 text-text-primary text-sm font-light flex items-center justify-center xl:hidden">
                  Filter by Game Types
                </button>
                <div class="flex gap-3 w-full lg:w-auto">
                  <div class="flex justify-center lg:justify-end w-full lg:w-auto">
                    <form action="" class="relative mx-auto w-full lg:w-auto">
                      <input
                        type="search"
                        v-model="searchInput"
                        placeholder="Search..."
                        class="peer cursor-pointer text-sm font-light relative z-10 h-8 w-full lg:w-42 rounded-md bg-gray-800 border-gray-700 focus:border-gray-700 focus:outline-none focus:ring-0 pl-8 outline-none text-text-primary placeholder:text-text-secondary"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute top-0 left-0 z-10 ml-2 translate-y-1/2 h-4 w-4 absolute"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="#9CADC4"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </form>
                  </div>

                  <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
                    <MenuButton
                      class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
                    >
                      <div class="flex-1 text-left font-light">
                        <span class="text-text-secondary">Map:</span> {{ getFriendlyMapName() }}
                      </div>
                      <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
                    </MenuButton>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div class="py-1 flex flex-col px-4">
                          <MenuItem @click="updateActiveMap('')">
                            <a
                              href="#"
                              :class="[
                                activeMap === '' ? 'text-text-primary' : 'text-text-secondary',
                                'group flex items-center py-2 text-sm w-full justify-start font-light',
                              ]"
                            >
                              All Maps
                              <CheckIcon v-if="activeMap === ''" class="h-5 w-5 ml-auto text-primary-400" />
                            </a>
                          </MenuItem>
                          <MenuItem
                            :key="map"
                            v-for="map in maps"
                            v-slot="{ active }"
                            @click="updateActiveMap(map.name)"
                          >
                            <a
                              href="#"
                              :class="[
                                active ? 'text-text-primary' : 'text-text-secondary',
                                'group flex items-center py-2 text-sm w-full justify-start',
                              ]"
                            >
                              {{ map.friendly_name }}
                              <CheckIcon v-if="map.name === activeMap" class="h-5 w-5 ml-auto text-primary-400" />
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
              <div class="w-full flex justify-center " v-if="loading">
                <svg
                  class="w-16 h-16 text-white animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div v-else class="flex flex-col gap-1 lg:gap-[2px] rounded-lg w-full overflow-hidden" ref="contentBlock">
                <ContentRow
                  ref="contentRow"
                  :content="content"
                  :modUrl="modUrl"
                  :activeMod="activeMod"
                  :activeServer="activeServer"
                  @updateServerInfo="updateServerInfo"
                  @loadContent="loadContent"
                  v-for="content in contents"
                  :key="content.id"
                >
                </ContentRow>
              </div>
            </div>
            <div v-if="activeMod === 'Creator Studio'" ref="gameTypeFilter" class="w-full sm:w-auto sm:min-w-[360px] sm:max-w-[360px] min-h-[500px] p-6 bg-gray-900 rounded-lg flex-col gap-6 right-0 top-0 z-50 shadow-lg overflow-y-auto"
              :class="[showFilterMenu ? 'absolute xl:flex xl:relative' : 'hidden xl:flex']">
              <div class="w-full flex items-center pb-6 border-b border-gray-800">
                <h1 class="text-xl text-text-primary font-semibold flex-1">Game Types</h1>
                <h2 @click="clearTags()" class="text-primary-400 text-xs cursor-pointer">Reset all</h2>
                <XIcon @click="toggleFilterMenu()" class="ml-2 h-5 w-5 xl:hidden text-text-secondary cursor-pointer hover:scale-105 duration-300" />
              </div>

              <div class="flex flex-col gap-9 py-6 xl:py-0">
                <div class="flex flex-col gap-3" v-for="category in tagCategories">
                  <h1 class="text-text-secondary tracking-[1.5px] text-xs uppercase">{{ category.name }}</h1>
                  <div class="flex flex-col gap-4">
                    <Disclosure v-for="tag in category.tags" v-slot="{ open }">
                      <DisclosureButton class="flex gap-2 items-center justify-center">
                        <input
                          type="checkbox"
                          :checked="isTagActive(tag.name)"
                          @click="toggleTag(tag.name)"
                          class="text-primary-600 border-gray-400 bg-gray-800 rounded-sm"
                        />
                        <span class="text-text-primary text-sm flex-1 text-left">{{ tag.name }}</span>
                        <ChevronDownIcon v-if="tag.description || tag.video_url" class="h-4 w-4 text-text-primary" :class="open && 'rotate-180 duration-300 transform'" />
                      </DisclosureButton>
                      <DisclosurePanel v-if="tag.description || tag.video_url" class="flex flex-col gap-4">
                        <h2 class="text-sm text-text-secondary font-light">{{ tag.description }}</h2>
                        <iframe
                          v-if="tag.video_url"
                          class="w-full aspect-[16/9] rounded-md bg-gray-800"
                          frameborder="0"
                          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                          allowfullscreen
                          :src="tag.video_url">
                        </iframe>
                      </DisclosurePanel>
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="flex mt-6 flex-row justify-end lg:justify-center px-4 items-center pb-4 gap-2 relative">
      <div class="absolute translate-y-1/2 top-0 left-0 text-xs text-text-secondary font-light">
        Showing {{ getFirstIndex() }}-{{ getLastIndex() }} of {{ totalCount }}
      </div>
      <button
        class="relative cursor-pointer inline-flex items-center justify-center h-8 w-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        :class="page == 1 ? 'bg-gray-800 opacity-30' : 'bg-gray-800 hover:bg-gray-700'"
        @click="previousPage"
        :disabled="page == 1"
      >
        <ChevronLeftIcon class="w-4 h-4 text-text-primary" />
      </button>
      <button
        class="h-8 w-8 flex items-center justify-center cursor-pointer text-primary-400 font-light text-sm">
        {{ page }}
      </button>
      <button
        @click="selectPage(page + 1)"
        v-if="page + 1 < totalPages"
        class="h-8 w-8 flex items-center justify-center cursor-pointer text-text-primary font-light text-sm">
        {{ page + 1 }}
      </button>
      <button
        @click="selectPage(page + 2)"
        v-if="page + 2 < totalPages"
        class="h-8 w-8 flex items-center justify-center cursor-pointer text-text-primary font-light text-sm">
        ...
      </button>
      <button
        @click="selectPage(totalPages)"
        v-if="page < totalPages"
        class="h-8 w-8 flex items-center justify-center cursor-pointer text-text-primary font-light text-sm">
        {{ totalPages }}
      </button>
      <button
        class="relative cursor-pointer inline-flex items-center justify-center h-8 w-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        :class="page >= totalPages ? 'bg-gray-800 opacity-30' : 'bg-gray-800 hover:bg-gray-700'"
        @click="nextPage"
        :disabled="page >= totalPages"
      >
        <ChevronRightIcon class="w-4 h-4 text-text-primary"/>
      </button>
    </div>
  </Dashboard>
  <StartServerModal :server="serverInfo" :show="showServerModal" @close="showServerModal = false"/>
</template>

<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import ContentRow from "../../components/CommunityHub/ContentRow.vue";
import { Menu, MenuButton, MenuItem, MenuItems, Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon, XIcon } from "@heroicons/vue/outline";
import { watch, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import StartServerModal from "../../components/StartServerModal.vue";
import { useHead } from '@unhead/vue'

const store = useStore();
const activeTeam = ref(store.getters.activeTeam);
const activeServer = computed(() => store.getters.activeServer);
const sortObject = ref("subscription_count");
const page = ref(1);
const totalPages = ref(1);
const totalCount = ref(1);
const activeMod = ref("Creator Studio");
const activeTags = ref([]);
const activeMap = ref("");
const contents = ref([]);
const maps = ref([]);
const searchInput = ref("");
const loading = ref(true);
const modUrl = ref("custom_sets");
const route = useRoute();
const tagCategories = ref();
const showFilterMenu = ref(false);
const contentBlock = ref();
const gameTypeFilter = ref();
const serverInfo = ref();
const showServerModal = ref(false);

useHead({
  title: 'Refrag | Community Hub',
})

if(route.query.filter_map || route.query.tags) {
  activeMap.value = route.query.filter_map;
  route.query.tags.split('-').forEach(tag => {
    if(tag) toggleTag(tag);
  });
}

if(route.path) {
  activeMod.value = getActiveMod(route.path.replace(/\/community_hub\//g, ''));
}

function getFirstIndex() {
  return (page.value - 1) * 10 + 1;
}

function getLastIndex() {
  if (totalCount.value < 10) return totalCount.value;
  return page.value * 10 > totalCount.value ? totalCount.value : page.value * 10;
}

function toggleFilterMenu() {
  showFilterMenu.value = !showFilterMenu.value;
}

function clearTags() {
  activeTags.value = [];
  resetPagination();
  loadContent();
}

function updateTagCategories() {
  if (activeMod.value === "Creator Studio")
    tagCategories.value = store.state.modInfo?.mods.find(({ name }) => name === 'CreatorStudio')?.tag_categories
  if (activeMod.value === "Grenade Groups")
    tagCategories.value = store.state.modInfo?.mods.find(({ name }) => name === 'NADR')?.tags
  if (activeMod.value === "Strategies")
    tagCategories.value = store.state.modInfo?.mods.find(({ name }) => name === 'Restrat')?.tags
  if (activeMod.value === "VPL")
    tagCategories.value = store.state.modInfo?.mods.find(({ name }) => name === 'VPL')?.tags
}

function getFriendlyMapName() {
  if (!activeMap.value) return "All Maps";
  return maps.value.find(({ name }) => name === activeMap.value)?.friendly_name || "All Maps";
}

function getMaps() {
  const compareMapName = activeMod == "Grenade Groups" ? "nadr" : "creatorstudio";
  const foundMod = store.state.modInfo?.mods?.find(({ name }) => name.toLowerCase() === compareMapName)
  if(foundMod) {
    maps.value = foundMod.cs_maps?.filter(({ cs2 }) => cs2).sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
    sortMaps();
  }
}

function sortMaps() {
  maps.value.sort((a, b) => {
    if (a.friendly_name < b.friendly_name) {
      return -1;
    }
    if (a.friendly_name > b.friendly_name) {
      return 1;
    }
    return 0;
  });
}

function nextPage() {
  if (page.value >= totalPages.value) return;

  page.value = page.value + 1;
  loadContent();
}

function previousPage() {
  if (page.value <= 1) return;

  page.value = page.value - 1;
  loadContent();
}

function selectPage(pageNum) {
  page.value = pageNum;
  loadContent();
}

function resetPagination() {
  page.value = 1;
}

function updateServerInfo(server) {
  serverInfo.value = server;
  showServerModal.value = true;
}

function loadContent(showLoad = true) {
  //checks to see if the active team value exists before continuing.
  if (!activeTeam.value) return;
  if (showLoad) loading.value = true;

  if (activeMod.value === "Creator Studio") {
    modUrl.value = "custom_sets";
    loadCustomSets();
  } else if (activeMod.value === "Grenade Groups") {
    modUrl.value = "grenade_groups";
    loadGrenadeGroups();
  } else if (activeMod.value === "Strategies") {
    modUrl.value = "solo_recordings";
    loadSoloRecordings('strategy');
  } else if (activeMod.value === "VPL") {
    modUrl.value = "solo_recordings";
    loadSoloRecordings('vpl');
  }
  updateUrlFilters();
  updateTagCategories();
}

function sortContentsByFeatured(a, b) {
  return b.featured - a.featured;
}
function loadCustomSets() {
  axios({
    url: "/custom_sets/",
    method: "GET",
    params: {
      "filters[tags]": getActiveTags(),
      "filters[map]": activeMap.value,
      search_input: searchInput.value,
      sort_by: sortObject.value,
      page: page.value,
      per_page: 10,
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    contents.value = response.data.custom_sets.sort(sortContentsByFeatured);
    totalPages.value = response.data.total_pages;
    totalCount.value = response.data.custom_set_count;
    loading.value = false;
  });
}

function loadGrenadeGroups() {
  axios({
    url: "/grenade_groups/",
    method: "GET",
    params: {
      "filters[map]": activeMap.value,
      search_input: searchInput.value,
      sort_by: sortObject.value,
      page: page.value,
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    contents.value = response.data.grenade_groups;
    totalPages.value = response.data.total_pages;
    totalCount.value = response.data.grenade_group_count;
    loading.value = false;
  });
}

function loadSoloRecordings(content_type) {
  axios({
    url: "/solo_recordings/",
    method: "GET",
    params: {
      "filters[tags]": getActiveTags(),
      "filters[map]": activeMap.value,
      "filters[content_type]": content_type,
      search_input: searchInput.value,
      sort_by: sortObject.value,
      page: page.value,
    },
    headers: {
      "X-TEAM-ID": activeTeam.value.id,
    },
  }).then((response) => {
    contents.value = response.data.solo_recordings;
    totalPages.value = response.data.total_pages;
    totalCount.value = response.data.solo_recordings_count;
    loading.value = false;
  });
}

function getActiveTags() {
  if (activeTags.value[0]) return activeTags.value;
  return "";
}

function toggleTag(name) {
  if (activeTags.value?.includes(name)) {
    activeTags.value = activeTags.value.filter((e) => e !== name);
  } else {
    activeTags.value.push(name);
  }
  resetPagination();
  updateUrlFilters();
  loadContent();
}

function isTagActive(name) {
  return activeTags.value?.includes(name);
}

function getActiveMod(url) {
  if (url === "grenade_groups") {
    return "Grenade Groups";
  } else if (url === "strategies") {
    return "Strategies";
  } else if (url === "vpl") {
    return "VPL";
  } else {
    return "Creator Studio";
  }
}

function updateActiveMod(mod_url) {
  activeMod.value = getActiveMod(mod_url);
  activeTags.value = [];
  resetPagination();
  loadContent();
  updateTagCategories();
}

function updateActiveMap(name) {
  activeMap.value = name;
  updateUrlFilters();
  loadContent();
}

function updateUrlFilters() {
    history.pushState({}, window.location, "?filter_map=" + activeMap.value + "&tags=" + activeTags.value.join('-'))
}

function updateFiltersHeight() {
  let contentBlockHeight = contentBlock.value?.offsetHeight;
  gameTypeFilter.value.style.height = (48 + contentBlockHeight) + "px";
}

// This is needed because if a user refreshes the page, the active team isnt there when the API call is made
watch(
  () => store.getters.activeTeam,
  (currentValue) => {
    activeTeam.value = currentValue;
    loadContent();
  }
);

//watch route changes
watch(
  () => route.path,
  (currentValue) => {
    updateActiveMod(currentValue.replace(/\/community_hub\//g, ''));
  }
);

// The timeout is so the user can finish typing before a bunch of api calls are made
watch(
  () => searchInput.value,
  () => {
    if (loading.value) return;
    loading.value = true;

    setTimeout(() => {
      loading.value = false;
      page.value = 1;
      loadContent();
    }, 500);
  }
);

watch(
  () => store.state.modInfo,
  () => {
    getMaps();
    updateTagCategories();
  }
);

watch(
  () => contentBlock.value,
  () => {
    if (loading.value || activeMod.value !== 'Creator Studio') return;
    updateFiltersHeight();
  }
);

watch(
  () => loading.value,
  () => {
    if (loading.value || !contentBlock.value || activeMod.value !== 'Creator Studio') return;
    updateFiltersHeight();
  }
);

loadContent();
getMaps();
</script>

<style lang="scss" scoped>
.width-transition {
  display: block;
  transition: all, 0.5s ease-in-out;
  width: 0;
}

.width-transition:focus {
  width: 16rem;
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #1E2733;
  opacity: 0;
}

::-webkit-scrollbar
{
	width: 12px;
  opacity: 0;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(67, 67, 67, 0.3);
	background-color: #37485D;
}
</style>
