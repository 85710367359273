<template>
  <transition name="slide">
    <div
      class="z-50"
      :class="['rounded-md shadow-lg p-4 static fixed bottom-4 right-4', backgroundColor]"
      v-show="alert.show"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon
            class="h-5 w-5 text-green-400"
            aria-hidden="true"
            v-if="alert.messageType == 'success'"
          />
          <XCircleIcon
            class="h-5 w-5 text-red-400"
            aria-hidden="true"
            v-else-if="alert.messageType == 'error'"
          />
          <ExclamationCircleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
            v-else
          />
        </div>
        <div class="ml-3">
          <p :class="[`text-sm font-medium`, alertColor]">{{ alert.messageText }}</p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              type="button"
              :class="[
                `inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2`,
                buttonColor,
              ]"
              @click="hideMessage"
            >
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  CheckCircleIcon,
  XIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/solid";
import { mapMutations } from "vuex";

export default {
  components: {
    CheckCircleIcon,
    XIcon,
    XCircleIcon,
    ExclamationCircleIcon,
  },
  computed: {
    alert() {
      return this.$store.state.alertMessage;
    },
    backgroundColor() {
      switch (this.alert.messageType) {
        case "success":
          return "bg-green-50";
        case "warning":
          return "bg-yellow-50";
        case "error":
          return "bg-red-50";
        default:
          return "bg-yellow-50";
      }
    },
    alertColor() {
      switch (this.alert.messageType) {
        case "success":
          return "text-green-800";
        case "warning":
          return "text-yellow-800";
        case "error":
          return "text-red-800";
        default:
          return "text-yellow-800";
      }
    },
    buttonColor() {
      switch (this.alert.messageType) {
        case "success":
          return "text-green-500";
        case "warning":
          return "text-yellow-500";
        case "error":
          return "text-red-500";
        default:
          return "text-yellow-500";
      }
    },
  },
  methods: {
    ...mapMutations(["hideMessage"]),
  },
};
</script>
