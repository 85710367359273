const objSort = function objSort(property) {
  let sortOrder = 1;
  let sortProperty = property;
  if (property[0] === '-') {
    sortOrder = -1;
    sortProperty = property.substr(1);
  }
  return function internalSort(a, b) {
    let result = 0;
    if (a[sortProperty] < b[sortProperty]) {
      result = -1;
    } else if (a[sortProperty] > b[sortProperty]) {
      result = 1;
    }
    return result * sortOrder;
  };
};

export default objSort;
export {
  objSort,
};
