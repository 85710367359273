<script setup>
import buttonLogo from "../assets/logos/refrag-icon-button.svg";
</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <div class="h-12">
        <!-- spacer -->
      </div>
      <TransitionGroup name="notifications" tag="ul" class="w-full contents justify-end z-50">
        <div v-if="showNotifications" class="bg-gray-900 p-2 flex flex-col gap-3 rounded-lg border border-gray-800 w-[320px] z-50 max-h-[500px] overflow-auto">
          <div v-if="notifications.length" class="flex gap-4 items-center w-full p-2">
            <h1 class="text-text-primary text-xl font-semibold flex-1">Notifications</h1>
            <button
              class="flex-row px-3 py-2 text-text-primary items-center justify-center cursor-pointer bg-gray-800 hover:bg-gray-700 duration-300 pointer-events-auto flex rounded-md"
              @click="clearAllNotifications"
            >
              Clear all
            </button>
          </div>

          <div v-else class="p-2 flex flex-col items-center justify-center gap-4">
            <div class="w-12 h-12 rounded-full bg-gray-800 flex items-center justify-center">
              <BellIcon class="h-8 w-8 text-text-secondary" />
            </div>
            <div class="flex flex-col gap-2 items-center justify-center">
              <h1 class="text-text-primary text-xl font-semibold">No notifications yet</h1>
              <h2 class="text-text-secondary text-sm text-center">
                You have no notifications right now. <br />
                Come back later. 
              </h2>
            </div>

          </div>
          

          <li
            v-for="notification in notifications"
            v-show="showNotification(notification)"
            :key="notification.title"
            @click="sendToNotification(notification)"
            class="cursor-pointer flex w-full gap-3 pointer-events-auto hover:bg-gray-800 duration-300 p-2 rounded-md"
          >
            <img class="h-10 w-10 rounded-full" :src="buttonLogo" alt="" />
            <div class="flex flex-col gap-1 flex-1">
              <p v-if="notification.created_at" class="text-xs text-text-secondary">{{ createdAtToDaysAgo(notification.created_at) }}</p>
              <p class="text-text-primary">{{ notification.title }}</p>
              <p class="text-sm text-text-secondary">{{ notification.description }}</p>
            </div>


            <div class="flex flex-shrink-0">
              <button
                type="button"
                @click="toggleNotification(notification)"
                class="rounded-full w-fit h-fit inline-flex rounded-md text-text-primary hover:text-text-secondary duration-300 z-50"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </li>
        </div>

      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import { BellIcon } from "@heroicons/vue/solid";

export default {
  components: {
    XIcon,
  },
  props: {
    showNotifications: Boolean,
    notifications: Array,
  },
  methods: {
    toggleNotification(notification) {
      this.$emit("see-notification", notification);
    },
    sendToNotification(notification) {
      this.$emit("see-notification", notification);
      this.$router.push(`${notification.link}`);
    },
    showNotification(notification) {
      if (this.showNotifications && !notification.seen) {
        return true;
      }
      return false;
    },
    clearAllNotifications() {
      this.$emit("clear-all-notifications");
    },
    createdAtToDaysAgo(createdAt) {
      const date = new Date(createdAt);
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.abs(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 1) {
        return "Today";
      }

      return Math.ceil(diffDays) + " days ago";
    },
  },
};
</script>

<style scoped>
.notifications-enter-active {
  transition: all 0.4s ease-in;
}
.notifications-leave-active {
  display: none;
}

.notifications-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.notifications-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
