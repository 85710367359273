<template>
    <div class="">
      <div :class="['player-row', `${team}_bg`]">
        <div class="p-6 text-lg font-medium bg-gray-900 flex justify-center">
          <div class="flex">
            <p>{{ teamName }}</p>
            <CheckIcon class="w-6 h-6 text-gray-400" v-if="winner === teamName" />
          </div>
        </div>
      </div>
      <PlayerRoundRow
          :name="player.name"
          :spent="player.moneyspent"
          :value="player.startmoney - player.moneyspent"
          :hasArmor="player.armor > 0"
          :hasHelmet="player.helmet"
          :kills="player.kills"
          :assists="player.assists"
          :died="player.died"
          :plantedBomb="player.planted_bomb"
          :defusedBomb="player.defused_bomb"
          :defuser="player.defuser"
          :key="player.xuid"
          :team="team"
          :teamName="teamName"
          :equipment="player.equipment"
          v-for="player in players"/>
    </div>
</template>

<script>
import PlayerRoundRow from './PlayerRoundRow.vue';
import { CheckIcon } from '@heroicons/vue/outline';

export default {
  name: 'RoundTable',
  components: {
    PlayerRoundRow,
    CheckIcon,
  },
  props: {
    players: Array,
    team: String,
    teamName: String,
    winner: String,
  },
};
</script>

<style lang="scss">
</style>
