<template>
  <div v-if="loading" class="w-full h-full flex pt-6 items-center justify-center">
    <svg
      class="animate-spin h-full font-normal w-12"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="white"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="white"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
  <div v-else class="py-8 grid grid-cols-1 md:grid-cols-2 gap-4 xl:grid-cols-3">
    <div v-if="subscribedSets.length > 0" class="bg-gray-900 h-24 flex rounded-md" v-for="set in subscribedSets">
      <div class="relative bg-top bg-cover h-full w-1/3 rounded-l-md" v-bind:style="{ 'background-image': 'url(/maps/' + set.cs_map.name + '_cs2.jpg)' }">
        <div @click="launchSet(set)" class="w-full h-full flex items-center justify-center bg-black/50 group hover:bg-black/40 cursor-pointer duration-300">
          <svg
            v-if="launchLoading === set.id"
            class="animate-spin h-full font-normal w-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="white"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="white"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <ThumbUpIcon v-else-if="success === set.id" class="text-green-500 w-12 h-12"></ThumbUpIcon>
          <PlayIcon v-else class="text-white w-16 h-16 group-hover:scale-105 duration-300"></PlayIcon>
        </div>
      </div>
      <div @click="goToContentPage(set)" class="cursor-pointer p-4 flex flex-col w-full overflow-hidden text-ellipsis">
        <h1 class="text-white font-semibold whitespace-nowrap overflow-hidden text-ellipsis">{{ set.title }}</h1>
        <h2 class="text-white text-xs flex-1">By {{ getAuthorUsername(set) }}</h2>
        <h3 class="text-gray-300 text-xs w-full text-right">Last updated: {{ moment(set.updated_at).format("LL") }}</h3>
      </div>
    </div>
    <div class="text-white font-semibold flex" v-else>
      <div class="flex flex-col items-center justify-center">
        Subscribe to sets on our community hub to get started!
        <router-link to="/community_hub" class="w-1/2 flex items-center justify-center py-2 mt-2 cursor-pointer bg-primary-600">
          Community Hub
        </router-link>
      </div>

    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { ref, watch } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import axios from "axios";
import { PlayIcon } from "@heroicons/vue/outline";
import { ThumbUpIcon } from "@heroicons/vue/solid";

const store = useStore();
const router = useRouter();
const emits = defineEmits(['submitCommand']);
const success = ref(false);
const launchLoading = ref(false);
const subscribedSets = ref([]);
const loading = ref(true);

const props = defineProps({
  activeServer: {
    type: Object
  },
  activeTeam: {
    type: Object
  }
})

function launchSet(set) {
  launchLoading.value = set.id;
  axios({
    url: `/cs_servers/${props.activeServer.id}/exec_server_command`,
    method: "POST",
    params: {
      "command": "load_set",
      "value": `custom_set, ${set.id}`,
    },
    headers: {
      "X-TEAM-ID": props.activeTeam.id,
    },
  }).then(() => {
    launchLoading.value = false;
    showSuccess(set);
  }).catch((err) => {
    store.commit("showMessage", {messageType: "error", messageText: "Could not communicate with server"})
    setTimeout(() => {
      store.commit("hideMessage");
    }, 3000);
    launchLoading.value = false;
  });
}

function showSuccess(set) {
  launchLoading.value = false;
  success.value = set.id;
  setTimeout(() => {
    success.value = false;
  }, 1000);
}

function goToContentPage(set) {
  router.push({
    name: "CustomSetPage",
    params: { id: set.id },
  });
}

function getSubscribedSets() {
  axios({
    url: "/custom_sets/subscribed_to_sets",
    method: "GET",
    headers: {
      "X-TEAM-ID": props.activeTeam?.id,
    },
  }).then((response) => {
    subscribedSets.value = response.data;
    loading.value = false;
  })
}

function getAuthorLink(set) {
  if(set.author_username) {
    return set.author_username
  }
  return set.author_uuid
}

function getAuthorUsername(set) {
  if(set.author_username) {
    return set.author_username
  }
  return set.user?.personaname
}

getSubscribedSets();

watch(
  () => store.state.modInfo.mods, () => {
    getSubscribedSets();
  }
);

</script>

<style scoped>
.this >>> .v-hl-btn {
  margin-bottom: 0px !important;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    display: none;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
