<template>
    <form class="space-y-4">
      <div>
        <div class="text-center">
          <h3 class="text-lg leading-6 font-medium text-white">
            Are you sure you want to kick {{ this.kickPlayer.name }}
          </h3>
        </div>
      </div>
      <div class="pt-5">
        <div class="flex justify-center">
          <button
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click="kickPlayer"
          >
            Kick
          </button>
          <button
            class="ml-3 float-right inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            @click="cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </template>
  
  <script>  
  export default {
    props: ["kickPlayer"],
    methods: {
      kickPlayer(e) {
        console.log(this.kickPlayer)
        e.preventDefault();
        this.$emit('submitCommand', { command: "kick_player", value: this.kickPlayer.steam_id})
        this.$emit("toggleModal");
      },
      cancel(e) {
        e.preventDefault();
        this.$emit("toggleModal");
      },
    },
  };
  </script>
  