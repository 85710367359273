<template>
    <div class="flex flex-col items-center gap-2 relative">
        <img :src="rankImage" alt="Rank" class="h-5">
        <h1 v-if="props.matchType === 'premier'" class="absolute top-0 -m-[3px] lg:mt-0 pl-1">
            <template v-if="rankValue > 0">
                <span class="thosands-place" :class="getPremierRankValue(rankValue)">{{ Math.floor(rankValue / 1000) }}</span>
                <span class="comma" :class="getPremierRankValue(rankValue)">,</span>
                <span class="hundreds-place" :class="getPremierRankValue(rankValue)">{{ (rankValue % 1000).toString().padStart(3, '0') }}</span>
            </template>
            <template v-else>
                <span class="thosands-place" :class="getPremierRankValue(rankValue)">---</span>
            </template>
        </h1>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    rank: {
        type: Number,
        required: true
    },
    matchType: {
        type: String,
        required: true
    },
    cs_map: {
        type: String,
        required: true
    }
});

const rankValue = computed(() => {
    return props.rank;
});

const rankImage = computed(() => {
    const rankImages = {
        "premier": `/img/icons/${getPremierRankValue(rankValue.value)}.svg`,
        "competitive": `/img/icons/${getCompetitiveRankValue(rankValue.value)}.svg`,
        "wingman": `/img/icons/${getWingmanRankValue(rankValue.value)}.svg`,
        "matchmaking": `/img/icons/${getFaceitRankValue(rankValue.value)}.svg`,
        "competition": `/img/icons/${getFaceitRankValue(rankValue.value)}.svg`,
        "championship": `/img/icons/${getFaceitRankValue(rankValue.value)}.svg`,
        "hub": `/img/icons/${getFaceitRankValue(rankValue.value)}.svg`
    };

    return rankImages[props.matchType] || `/img/icons/no-rank.svg`;
});

function getCompetitiveRankValue(rank) {
    return `skillgroup${rank}`;
    // const competitiveRankValue = {
    //     0: 'skillgroup0',
    //     1: 'skillgroup1',
    //     2: 'skillgroup2',
    //     3: 'skillgroup3',
    //     4: 'skillgroup4',
    //     5: 'skillgroup5',
    //     6: 'skillgroup6',
    //     7: 'skillgroup7',
    //     8: 'skillgroup8',
    //     9: 'skillgroup9',
    //     10: 'skillgroup10',
    //     11: 'skillgroup11',
    //     12: 'skillgroup12',
    //     13: 'skillgroup13',
    //     14: 'skillgroup14',
    //     15: 'skillgroup15',
    //     16: 'skillgroup16',
    //     17: 'skillgroup17',
    //     18: 'skillgroup18',
    // }

    // return competitiveRankValue[rank] || 'unranked';
}

function getWingmanRankValue(rank) {
    return `wingman${rank}`;
    // const wingmanRankValue = {
    //     0: 'wingman0',
    //     1: 'wingman1',
    //     2: 'wingman2',
    //     3: 'wingman3',
    //     4: 'wingman4',
    //     5: 'wingman5',
    //     6: 'wingman6',
    //     7: 'wingman7',
    //     8: 'wingman8',
    //     9: 'wingman9',
    //     10: 'wingman10',
    //     11: 'wingman11',
    //     12: 'wingman12',
    //     13: 'wingman14',
    //     14: 'wingman15',
    //     15: 'wingman16',
    //     16: 'wingman17',
    //     17: 'wingman18',
    //     18: 'wingman19',
    // }

    // return wingmanRankValue[rank] || 'unranked';
}

function getPremierRankValue(rank) {
    const maxPremierValue = {
        4999: 'gray-rank',
        9999: 'light-blue-rank',
        14999: 'blue-rank',
        19999: 'purple-rank',
        24999: 'pink-rank',
        29999: 'red-rank',
    }

    for (const [threshold, rankName] of Object.entries(maxPremierValue)) {
        if (rank <= threshold) {
            return rankName;
        }
    }
    return 'gold-rank';

}

function getFaceitRankValue(rank) {
    const maxFaceitRankValue = {
        0: 'level-0',
        500: 'level-1',
        750: 'level-2',
        900: 'level-3',
        1050: 'level-4',
        1200: 'level-5',
        1350: 'level-6',
        1530: 'level-7',
        1750: 'level-8',
        2000: 'level-9',
    }

    for (const [threshold, rankName] of Object.entries(maxFaceitRankValue)) {
        if (rank <= threshold) {
            return rankName;
        }
    }
    return 'level-10';
}

</script>

<style>

.thosands-place {
    text-shadow: 0px 0.369px 0.369px rgba(0, 0, 0, 0.50);
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 15px;
}

.comma {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
}

.hundreds-place {
    font-family: Poppins;
    font-size: 10px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.gray-rank {
    color: #B8C7D7;
}

.light-blue-rank {
    color: #85B5E5;
}

.blue-rank {
    color: #687DE9;
}

.purple-rank {
    color: #BD6BFD;
}

.pink-rank {
    color: #F453FB;
}

.red-rank {
    color: #EB4A49;
}

.gold-rank {
    color: #FFD700;
}
</style>

