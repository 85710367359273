<template>
  <div class="w-full pt-8">
    <div class="flex flex-col gap-5">
      <h1 class="text-text-primary text-2xl font-medium">Mode & Map</h1>
      <div class="flex flex-col md:flex-row gap-y-4">
        <div class="flex flex-[4] gap-3.5 items-center">
          <Combobox class="flex-1" as="div" v-model="selectedMod">
            <div class="relative">
              <ComboboxLabel class="flex text-sm items-center rounded-md text-text-secondary border-0 bg-gray-900 px-3 h-12 shadow-sm ring-1 ring-inset ring-gray-800 focus:ring-2 focus:ring-inset focus:ring-primary-600">
                Mode:
                <ComboboxInput class="appearance-none w-full bg-gray-900 border-0 ring-0 focus:ring-0 text-text-primary sm:text-sm sm:leading-6 font-medium" @change="modQuery = $event.target.value" :display-value="(mod) => mod.friendly_name" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-3 focus:outline-none">
                  <img src="../../assets/icons/dropdown.svg" class="text-gray-400" aria-hidden="true" />
                </ComboboxButton>
              </ComboboxLabel>


              <ComboboxOptions class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-gray-800 focus:outline-none sm:text-sm">
                <ComboboxOption v-for="mod in filteredMods" :key="mod.id" v-show="mod.playable && mod.cs2" :value="mod" as="template" v-slot="{ active, selected }">
                  <li :class="['relative cursor-pointer select-none py-2 pl-3 pr-9', selected ? 'bg-indigo-600 text-text-primary' : 'text-text-secondary hover:bg-gray-800']">
                    <span :class="['block truncate', selected && 'font-semibold text-text-primary']">
                      {{ mod.friendly_name }}
                    </span>

                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>

          <Combobox class="flex-1" as="div" v-model="selectedMap">
            <div class="relative">
              <ComboboxLabel class="flex text-sm items-center rounded-md text-text-secondary border-0 bg-gray-900 px-3 h-12 shadow-sm ring-1 ring-inset ring-gray-800 focus:ring-2 focus:ring-inset focus:ring-primary-600">
                Map:
                <ComboboxInput class="appearance-none w-full bg-gray-900 border-0 ring-0 focus:ring-0 text-text-primary sm:text-sm sm:leading-6 font-medium" @change="mapQuery = $event.target.value" :display-value="(map) => map.friendly_name" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-3 focus:outline-none">
                  <img src="../../assets/icons/dropdown.svg" class="text-gray-400" aria-hidden="true" />
                </ComboboxButton>
              </ComboboxLabel>


              <ComboboxOptions class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-gray-800 focus:outline-none sm:text-sm">
                <ComboboxOption v-for="map in filteredMaps" :key="map.id" v-show="map.cs2" :value="map" as="template" v-slot="{ selected }">
                  <li :class="['relative cursor-pointer select-none py-2 pl-3 pr-9', selected ? 'bg-indigo-600 text-text-primary' : 'text-text-secondary hover:bg-gray-800']">
                    <div class="flex gap-4 items-center">
                      <span :class="['block truncate', selected && 'font-semibold text-text-primary']">
                        {{ map.friendly_name }}
                      </span>
                    </div>

                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>


        <!-- divider -->
        <div class="my-auto h-[42px] w-[1px] bg-gray-800 mx-4 hidden md:flex"/>

        <button class="flex-[3] rounded-md bg-primary-600 flex justify-center items-center px-4 py-3.5 text-text-primary cursor-pointer hover:bg-primary-500 duration-200"
          @click="submitUpdatedMode"
          :disabled="submittedModeCommand || successModeCommand"
        >
          <h1 v-if="!submittedModeCommand && !successModeCommand">Load</h1>
          <svg v-else-if="!successModeCommand" class="animate-spin h-full font-normal w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="white" stroke-width="4"></circle>
            <path class="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <ThumbUpIcon v-else class="text-green-500 w-6 h-6"></ThumbUpIcon>
        </button>
      </div>
    </div>

    <div class="pt-16">
      <h1 class="text-text-primary text-2xl font-medium">Commands</h1>

      <div class="flex gap-8 pt-5">

        <!-- commands -->
        <div class="flex-1 basis-2/3 bg-gray-900 rounded-md overflow-hidden">
          <div class="flex border-b border-gray-700 p-6 items-center">
            <h2 v-if="filteredCommands?.length > 0" class="flex-1 text-text-primary">
              {{ activeMod?.friendly_name }} commands list
            </h2>
            <h2 v-else class="flex-1 text-text-primary">
              There are no commands for {{ activeMod?.friendly_name }} yet
            </h2>

            <div class="flex flex-col md:flex-row gap-2 items-center">
              <div class="flex gap-2 pr-6">
                <Switch
                  v-model="advancedMode"
                  :class="advancedMode ? 'bg-primary-500' : 'bg-gray-600'"
                  class="relative inline-flex h-5 w-9 items-center rounded-full"
                >
                  <span
                    :class="advancedMode ? 'translate-x-4 bg-primary-50' : 'translate-x-[2px] bg-gray-800'"
                    class="inline-block h-4 w-4 transform rounded-full transition"
                  />
                </Switch>
                <h2 class="text-sm text-text-primary flex-1">Advanced Mode</h2>
              </div>

              <Search v-model="commandSearch"></Search>
            </div>

          </div>

          <div class="px-6 pb-6">
            <div v-if="activeMod?.featured_commands?.length > 0 && commandSearch === ''" class="pb-6 pt-6 gap-3 flex md:grid md:grid-cols-3 border-b border-gray-700 overflow-x-auto">
              <div class="flex md:flex-col border border-gray-700 group rounded-md p-3 min-w-[180px] md:w-auto items-start hover:bg-gray-800 duration-300 cursor-pointer"
                v-for="command in activeMod?.featured_commands"
                :key="command.id"
                @click="submitCommand(command)">

                <div class="flex flex-col items-start">
                  <Pill class="p-2" :text="command.friendly_name" textColor="#F8F9FB" bgColor="#1E2733"/>
                  <p class="text-text-secondary text-xs py-3">{{ command.description }}</p>
                </div>
                <img v-if="submittedCommand.command !== command.command && successCommand.command !== command.command" src="../../assets/icons/play.svg" class="h-6 w-6 group-hover:scale-105 duration-300">
                <svg v-else-if="submittedCommand.command === command.command" class="animate-spin font-normal w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="white" stroke-width="4"></circle>
                  <path class="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <ThumbUpIcon v-else-if="successCommand.command === command.command" class="text-green-500 w-6 h-6"></ThumbUpIcon>
              </div>
            </div>

            <div class="flex flex-col pt-6" v-for="group in filteredCommands" v-show="group?.commands?.length > 0">
              <h1 class="uppercase text-sm text-semibold text-text-secondary tracking-[1px] pb-2">{{ group.title }}</h1>

              <div class="flex items-center gap-y-4 group hover:bg-gray-800 duration-300 rounded-md cursor-pointer" v-for="command in group.commands"
                @click="submitCommand(command)">
                <div class="flex flex-1">
                  <Pill class="p-2" :text="command.friendly_name" textColor="#F8F9FB" bgColor="#1E2733"/>
                </div>
                <p class="text-text-secondary text-xs py-3 flex-1 lg:flex-[3]">{{ command.description }}</p>
                <img v-if="submittedCommand.command !== command.command && successCommand.command !== command.command" src="../../assets/icons/play.svg" class="h-6 w-6 group-hover:scale-105 duration-300">
                <svg v-else-if="submittedCommand.command === command.command" class="animate-spin h-full font-normal w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="white" stroke-width="4"></circle>
                  <path class="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <ThumbUpIcon v-else-if="successCommand.command === command.command" class="text-green-500 w-6 h-6"></ThumbUpIcon>
              </div>
            </div>

            <div v-if="isCommandsEmpty()" class="text-text-primary pt-6">
              No commands found for your search
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton, ComboboxLabel, Switch } from '@headlessui/vue'
import { CheckIcon, ThumbUpIcon } from '@heroicons/vue/solid'
import Pill from '../Pill.vue';
import Search from '../Search.vue';

const store = useStore();
const selectedMap = ref();
const selectedMod = ref();
const command = ref();
const modeCommand = ref();
const maps = ref();
const mods = ref(store.state.modInfo?.mods);
const emits = defineEmits(['submitCommand']);
const submittedModeCommand = ref(false);
const successModeCommand = ref(false);
const submittedCommand = ref(false);
const successCommand = ref(false);
const modQuery = ref('');
const mapQuery = ref('');
const commandSearch = ref("");
const activeMod = ref();
const advancedMode = ref(false);

const props = defineProps({
  activeServer: {
    type: Object
  },
  activeTeam: {
    type: Object
  }
})

const filteredMods = computed(() =>
  modQuery.value === ''
    ? mods.value
    : mods.value.filter((mod) => {
      return mod.name.toLowerCase().includes(modQuery.value.toLowerCase())
    })
)

const filteredMaps = computed(() => {
  const filtered = mapQuery.value === ''
    ? maps.value
    : maps.value.filter((map) => {
      return map.name.toLowerCase().includes(mapQuery.value.toLowerCase())
    })

    return filtered.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
  }
)

const filteredCommands = computed(() => {
  return activeMod.value?.command_groups.map((group) => {
    return {
      ...group,
      commands: group.commands.filter((command) => {
        if(advancedMode.value) {
          return command.description.toLowerCase().includes(commandSearch.value.toLowerCase()) ||
                 command.command.toLowerCase().includes(commandSearch.value.toLowerCase())
        } else {
          return (command.description.toLowerCase().includes(commandSearch.value.toLowerCase()) ||
                 command.command.toLowerCase().includes(commandSearch.value.toLowerCase())) &&
                !command.is_advanced
        }
      })
    }
  })
})

function isCommandsEmpty() {
  return filteredCommands.value?.every((group) => group?.commands?.length === 0) && commandSearch.value !== '';
}

function updateActiveMod() {
  activeMod.value = store.state.modInfo.mods.find((mod) => mod.name?.toLowerCase() == props.activeServer.active_mod.toLowerCase())
}

function selectMap(map) {
  selectedMap.value = map;
  updateModeCommand();
}

function selectMod(mod) {
  if(!mod) return;
  selectedMod.value = mod;
  maps.value = mod.cs_maps;

  if(props.activeServer.active_map) {
    selectedMap.value = maps.value.find((map) => map.name?.toLowerCase() == props.activeServer.active_map.toLowerCase())
  } else {
    selectedMap.value = maps.value.find((map) => map.cs2)
  }
  updateModeCommand();
}

function updateModeCommand() {
  if(selectedMod.value && selectedMap.value) {
    modeCommand.value = "change_map_and_mod";
  } else if(selectedMod.value) {
    modeCommand.value = "change_mod";
  } else if(selectedMap.value) {
    modeCommand.value = "change_map";
  }
}

function getValueData() {
  if(selectedMod.value && selectedMap.value) {
    return `${selectedMap.value.name}, ${selectedMod.value.name}`
  } else if(selectedMap.value.name) {
    return selectedMap.value.name
  } else if(selectedMod.value.name) {
    return selectedMod.value.name
  }
}

function submitUpdatedMode() {
  submittedModeCommand.value = true;
  emits('submitCommand', { command: modeCommand.value, value: getValueData()})
  setTimeout(() => {
    submittedModeCommand.value = false;
    successModeCommand.value = true;
    setTimeout(() => {
      successModeCommand.value = false;
    }, 1000);
  }, 3000);
}

function submitCommand(command) {
  submittedCommand.value = command;
  emits('submitCommand', { command: command.command, value: '', is_client_command: command.is_client_command})
  setTimeout(() => {
    successCommand.value = command;
    submittedCommand.value = false;
    setTimeout(() => {
      successCommand.value = false;
    }, 1000);
  }, 3000);
}

function setCurrentMod() {
  const mod = store.state.modInfo?.mods.find((mod) => mod.name?.toLowerCase() == props.activeServer.active_mod.toLowerCase())
  if(mod) {
    selectMod(mod);
  }
}

setCurrentMod();
updateActiveMod();

watch(
  () => store.state.modInfo.mods, () => {
    setCurrentMod();
  }
);

watch(
  () => selectedMod.value, (value) => {
    selectMod(value);
  }
);

watch(
  () => selectedMap.value, (value) => {
    selectMap(value);
  }
);

watch(
  () => props.activeServer, () => {
    updateActiveMod();
    setCurrentMod();
  }
)

</script>

<style scoped>
.this >>> .v-hl-btn {
  margin-bottom: 0px !important;
}

.bounce-enter-active {
	animation: bounce-in 0.5s;
}
.bounce-leave-active {
	display: none;
}
@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}
</style>
