<script setup>
import moment from "moment";
import { XCircleIcon } from "@heroicons/vue/outline";
</script>

<template>
  <div class="flex p-6 items-center">
    <div class="flex-1">
      <p class="text-sm font-medium text-white">{{ teamInvite.email }}</p>
      <p class="text-sm text-gray-500">
        Invited on {{ moment(teamInvite.created_at).format("LL") }}
      </p>
    </div>
    <button
      @click="removeInvite"
      class="ml-3 inline-flex justify-center items-center w-10 h-10 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    >
      <XCircleIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["teamInvite"],
  methods: {
    removeInvite() {
        axios({
          method: "delete",
          url: `/team_invites/${this.teamInvite.token}`,
        })
          .then((response) => {
            this.$emit("removeInvite", this.teamInvite.id);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
};
</script>
