<template>
  <main>
    <Dashboard v-if="showUpgrade" title="Academy" no-padding="true">
      <!-- show screenshot and push to upgrade -->
      <div class="w-full h-full bg-gray-950 relative flex justify-center items-center">
        <img class="select-none w-full h-full object-cover" src="../assets/academy-preview.png">
        <div class="w-full h-full bg-black/60 z-50 absolute items-center justify-center flex">
          <UpgradeModal
            title="Upgrade to access the Academy"
            firstBenefit="2D Demo Viewer"
            secondBenefit="Utility Hub"
            primaryPromotionType="academy"
            video_url="https://www.youtube.com/embed/g3O1ZJyMCYY?si=NlthFcIkTWnZYjLi?autoplay=1">
          </UpgradeModal>
        </div>
      </div>
    </Dashboard>

    <Dashboard v-else title="Video Training Platform" iconsOnly="true" noPadding="true">
      <div v-if="loading" class="flex justify-center py-10">
        <svg class="w-16 h-16 text-white animate-spin" fill="none" viewBox="0 0 24 24">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
      <div v-else class="flex flex-1">
        <div class="flex flex-col xl:flex-row flex-1">
          <div class="flex flex-col flex-1">
            <!-- video display -->
            <div class="w-full aspect-[16/9] relative" v-if="!videoLoading">
              <iframe
                class="w-full aspect-[16/9] absolute top-0 left-0"
                :src="activeVideo?.url"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div v-else class="flex w-full aspect-[16/9] items-center justify-center">
              <svg
                class="w-16 h-16 text-white animate-spin"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>

            <div class="flex-1 w-full bg-gray-950 px-9 py-6">
              <h1 class="text-text-primary font-semibold flex items-center text-xl">
                {{ activeVideo?.title }}
              </h1>
              <h2 class="text-text-secondary text-lg pt-3">
                {{ activeVideo?.description }}
              </h2>
            </div>
          </div>

          <!-- right nav -->
          <div
            class="flex flex-col h-full bg-gray-900 w-[420px] p-6"
          >

            <div class="w-full gap-4 flex flex-col bg-gray-900">
              <h1 class="text-text-primary text-xl font-bold">Choose lesson</h1>
              <!-- maps -->
              <Menu as="div" class="relative inline-block text-left w-full">
                <div>
                  <MenuButton
                    as="div"
                    class="flex w-full bg-gray-800 rounded-md py-3 text-sm font-medium text-white shadow-sm hover:bg-gray-700 border border-gray-700 duration-300 cursor-pointer"
                  >
                    <div class="flex flex-row px-4 w-full">
                      <div class="flex pr-2 flex-1">{{ currentCategory.title }}</div>
                      <ChevronDownIcon
                        class="-mr-1 h-5 w-5 text-white flex"
                        aria-hidden="true"
                      />
                    </div>
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 w-full origin-top-right bg-gray-900 shadow-lg border border-gray-800"
                  >
                    <div class="py-1">
                      <MenuItem
                        v-for="category in categories"
                        v-slot="{ active }"
                        @click="updateCurrentCategory(category)"
                      >
                        <a
                          href="#"
                          :class="[
                            active ? 'bg-gray-800 text-white' : 'text-white',
                            'block px-4 py-2 text-sm cursor-pointer',
                          ]"
                          >{{ category.title }}</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>

              <!-- sides -->
              <div
                v-if="currentCategory.cs_map"
                class="flex flex-row bg-gray-800 p-1 rounded-md"
              >
                <div
                  @click="updateSide('t_side')"
                  class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                  :class="[currentSide === 't_side' ? 'bg-gray-700 text-text-primary' : 'text-text-secondary bg-gray-800']"
                >
                  <img class="h-6 w-6" src="../assets/icons/tbadge.png" />
                  T Side
                </div>
                <div
                  @click="updateSide('ct_side')"
                  class="w-full flex gap-2 text-sm items-center justify-center rounded-md p-1 cursor-pointer"
                  :class="[currentSide === 'ct_side' ? 'bg-gray-700 text-text-primary' : 'text-text-secondary bg-gray-800']"
                >
                  <img class="h-6 w-6" src="../assets/icons/ctbadge.png" />
                  CT Side
                </div>
              </div>
            </div>

            <!-- videos -->
            <div
              class="w-full pt-12 text-text-primary text-xl font-semibold pb-4"
            >
              Playlist
            </div>
            <div class="select-none flex-1 flex flex-col gap-2">
              <div
                v-for="video in currentCategory.videos"
                :key="video"
                v-show="showVideo(video)"
                class="items-center cursor-pointer rounded-lg overflow-hidden"
              >
                <div
                  @click="updateActiveVideo(video)"
                  class=""
                  :class="[video.slug === activeVideo?.slug ? 'bg-primary-600 rounded-t-lg text-text-primary' : 'text-text-secondary bg-gray-800 rounded-lg'],
                  video.custom_sets.length > 0 ? '' : 'rounded-b-lg'"
                >
                  <div class="p-4 flex">
                    <h1
                      class="uppercase text-[14px] tracking-wide font-semibold flex w-full items-center"
                    >
                      {{ video.title }}
                    </h1>
                    <PlayIcon class="h-5 w-5 flex" aria-hidden="true" />
                  </div>
                </div>

                <div
                  v-if="video.slug === activeVideo?.slug"
                  v-for="set in video.custom_sets"
                  class="w-full bg-gray-800 flex items-center last:rounded-b-lg"
                >
                  <div class="flex flex-col px-4 py-3 justify-center flex-1">
                    <h1 class="text-text-primary text-xs font-semibold flex-1">
                      {{ set.title }}
                    </h1>
                    <h2 class="uppercase text-text-secondary text-[10px] tracking-[1.5px]">Set</h2>
                  </div>
                  <svg
                    v-if="launchLoading === 'set' + set.id"
                    class="animate-spin h-full font-normal w-4 mr-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="white"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="white"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <ThumbUpIcon
                    v-else-if="success === 'set' + set.id"
                    class="text-green-500 w-4 h-4 mr-6"
                  ></ThumbUpIcon>
                  <div
                    v-else
                    @click="launchContent(set, 'set')"
                    class="text-gray-300 text-[12px] underline mr-6 cursor-pointer hover:font-semibold"
                  >
                    Launch
                  </div>
                </div>
                <div
                  v-if="video.slug === activeVideo?.slug"
                  v-for="grenade_group in video.grenade_groups"
                  class="w-full bg-gray-800 flex items-center last:rounded-b-lg"
                >
                  <div class="flex flex-col px-4 py-3 justify-center flex-1">
                    <h1 class="text-text-primary text-xs font-semibold flex-1">
                      {{ grenade_group.title }}
                    </h1>
                    <h2 class="uppercase text-text-secondary text-[10px] tracking-[1.5px]">Grenade Group</h2>
                  </div>
                  <svg
                    v-if="launchLoading === 'grenade_group' + grenade_group.id"
                    class="animate-spin h-full font-normal w-4 mr-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="white"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="white"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <ThumbUpIcon
                    v-else-if="success === 'grenade_group' + grenade_group.id"
                    class="text-green-500 w-4 h-4 mr-6"
                  ></ThumbUpIcon>
                  <div
                    v-else
                    @click="launchContent(grenade_group, 'grenade_group')"
                    class="text-gray-300 text-[12px] underline mr-6 cursor-pointer hover:font-semibold"
                  >
                    Launch
                  </div>
                </div>
                <div
                  v-if="video.slug === activeVideo?.slug"
                  v-for="strategy in video.strategies"
                  class="w-full bg-gray-800 flex items-center"
                >
                  <div class="flex flex-col px-4 py-3 justify-center flex-1">
                    <h1 class="text-text-primary text-xs font-semibold flex-1">
                      {{ strategy.title }}
                    </h1>
                    <h2 class="uppercase text-text-secondary text-[10px] tracking-[1.5px]">Strategy</h2>
                  </div>
                  <svg
                    v-if="launchLoading === 'strategy' + strategy.id"
                    class="animate-spin h-full font-normal w-4 mr-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="white"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="white"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <ThumbUpIcon
                    v-else-if="success === 'strategy' + strategy.id"
                    class="text-green-500 w-4 h-4 mr-6"
                  ></ThumbUpIcon>
                  <div
                    v-else
                    @click="launchContent(strategy, 'strategy')"
                    class="text-gray-300 text-[12px] underline mr-6 cursor-pointer hover:font-semibold"
                  >
                    Launch
                  </div>
                </div>
              </div>
            </div>

            <!-- divider -->
            <div class="w-full h-px bg-gray-800 my-9"/>

            <div
              class="flex flex-col gap-4 pb-8"
            >
              <h1 class="text-text-primary text-xl font-bold">
                Hosted By
              </h1>
              <div class="flex gap-x-3">
                <img
                  class="h-16 w-16 rounded-full"
                  :src="activeVideo?.proplayer?.photo"
                />
                <div class="flex flex-col">
                  <h1 class="pb-1 text-text-primary">
                    {{ activeVideo?.proplayer?.name }}
                  </h1>
                  <h2 class="pb-2 uppercase text-xs font-lighter text-text-secondary">
                    {{ activeVideo?.proplayer?.title }}
                  </h2>
                  <div class="flex space-x-2">
                    <a class="cursor-pointer" :href="activeVideo?.proplayer?.twitter_url" target="_blank"
                      ><img class="h-4 w-4" src="../assets/icons/twitter.svg"
                    /></a>
                    <a class="cursor-pointer" :href="activeVideo?.proplayer?.youtube_url" target="_blank"
                      ><img class="h-4 w-4" src="../assets/icons/youtube.svg"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  </main>
  <StartServerModal :server="serverInfo" :show="showServerModal" @close="showServerModal = false"/>
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import StartServerModal from "../components/StartServerModal.vue";
import { ref, watch, computed } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, PlayIcon, ThumbUpIcon } from "@heroicons/vue/solid";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import icon from "../assets/logos/refrag-icon-button.svg";
import axios from "axios";
import { useStore } from "vuex";
import UpgradeModal from "../components/UpgradeModal.vue";
import { useHead } from '@unhead/vue'

const showUpgrade = ref();
const router = useRouter();
const route = useRoute();
const store = useStore();

const currentCategory = ref({});
const activeVideo = ref({});
const categories = ref([]);
const currentSide = ref("t_side");
const loading = ref(true);
const videoLoading = ref(true);
const launchLoading = ref(false);
const success = ref(false);
const showServerModal = ref(false);
const serverInfo = ref();
const activeServer = computed(() => store.getters.activeServer);
const auth = useAuth();

let user = auth.user();

if (user.access_level > 1) {
  showUpgrade.value = false;
} else {
  showUpgrade.value = true;
}

useHead({
  title: () => activeVideo?.title ? `Refrag | ${activeVideo.title}` : `Refrag | Academy`,
})

function updateActiveVideo(video) {
  if (!video) return;

  getVideo(video);
  router.push({ name: "VideoPage", params: { slug: video.slug } });
}

function updateModalServerInfo(launchSettings) {
  serverInfo.value = launchSettings;
  showServerModal.value = true;
}

function getModName(type) {
  if (type === "set") {
    return "Creator Studio";
  } else if (type === "grenade_group") {
    return "Grenade Groups";
  } else if (type === "strategy") {
    return "Strategies";
  }
}

function launchContent(content, type) {
  if (!activeServer.value?.id) {
    let launchSettings = {
      cs_map: content.cs_map,
      mode: getModName(type),
      content: content,
    }
    updateModalServerInfo(launchSettings);
    return;
  }

  let command = "";
  let value = "";

  if (type === "set") {
    command = "load_set";
    value = `custom_set, ${content.id}`;
  } else if (type === "grenade_group") {
    command = "load_nadr";
    value = `grenades, ${content.id}`;
  } else if (type === "strategy") {
    command = "load_nadr";
    value = `strat, ${content.id}`;
  }

  launchLoading.value = type + content.id;
  axios({
    url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: command,
      value: value,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = false;
      showSuccess(type + content.id);
    })
    .catch((err) => {
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
      launchLoading.value = false;
    });
}

async function getVideo(video) {
  videoLoading.value = true;
  activeVideo.value = video;
  // Get the video from the backend
  axios({
    url: `/videos/${video.slug}`,
    method: "GET",
  }).then((response) => {
    videoLoading.value = false;
    activeVideo.value = response.data.data;
  });
}

function showSuccess(content) {
  launchLoading.value = false;
  success.value = content;
  setTimeout(() => {
    success.value = false;
  }, 1000);
}

function showVideo(video) {
  if (currentCategory.value.cs_map) {
    return video.side == currentSide.value;
  }
  return true;
}

function updateCurrentCategory(friendly_name) {
  currentCategory.value = friendly_name;
  updateActiveVideo(currentCategory.value.videos.find((video) => video.side == currentSide.value) || "")
}

function updateSide(side) {
  currentSide.value = side;
  updateActiveVideo(currentCategory.value.videos.find((video) => video.side == side) || "")
}

function getFriendlySideName() {
  if (currentSide.value === "t_side") return "T Side";
  return "CT Side";
}

function getCategories() {
  if (user.access_level < 2) {
    return;
  }

  loading.value = true;
  axios({
    url: "/video_categories",
    method: "GET",
  }).then((response) => {
    if (route.params.slug) {
      let video = response.data
        .find((category) => category.videos.find((video) => video.slug == route.params.slug))
        ?.videos.find((video) => video.slug == route.params.slug);
      if (video) {
        getVideo(video);
        currentCategory.value = response.data.find((category) =>
          category.videos.find((video) => video.slug == route.params.slug)
        );
        currentSide.value = video.side;
      }
    } else {
      currentCategory.value = response.data[0];
      getVideo(currentCategory.value.videos[0]);
    }
    categories.value = response.data;
    loading.value = false;
  });
}

watch(
  () => store.getters.activeTeam,
  () => {
    if (store.getters.activeTeam === null) return;
    getCategories();
  }
);

if (store.getters.activeTeam !== null) {
  getCategories();
}
</script>

<style scoped>
.grow-transition {
  transition: width 0.3s;
}
</style>
