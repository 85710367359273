<template>
  <Dashboard v-if="user" title="Highlight Page">
    <div class="flex gap-6 lg:flex-row flex-col">
      <div class="flex-[7] aspect-[16/9] w-full bg-gray-800 rounded-lg overflow-hidden">
        <iframe
          v-if="clip?.status == 'processed' || clip?.status == 'submitted'"
          :key="clip?.status"
          class="w-full h-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          :src="clip.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
        >
        </iframe>
      </div>
      <div class="flex-[5] w-full flex-col gap-4">
        <div class="flex flex-col gap-6">
          <ul class="flex flex-col gap-3 text-text-secondary">
            <h1 class="text-text-primary text-xl font-bold flex-1">
              {{ clip?.title || "Loading..." }}
            </h1>
            <h2 class="text-text-secondary text-sm">
              {{ clip?.round_number ? `Round ${clip?.round_number}` : "-" }}
            </h2>
          </ul>
          <div class="w-full h-px bg-gray-800" />
          <div class="flex flex-col gap-3">
            <h1 class="text-text-primary text-lg font-bold">Share</h1>
            <div class="flex gap-3">
              <div
                v-clipboard:copy="`https://play.refrag.gg/highlights/${clip?.uuid}`"
                v-clipboard:success="copySucceeded"
                target="_blank"
                class="bg-primary-600 flex-1 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
              >
                <img class="brightness-200" src="../assets/icons/link.svg" />
                Copy link
              </div>
              <a
                :href="`https://twitter.com/intent/tweet?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
                target="_blank"
                class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
              >
                <img class="w-6 h-6" src="../assets/icons/twitter.svg" />
              </a>
              <a
                :href="`https://www.reddit.com/submit?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
                target="_blank"
                class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
              >
                <img class="w-6 h-6" src="../assets/icons/reddit.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <div v-else class="flex flex-col h-full items-center w-full bg-gray-950">
    <div
      class="w-full min-h-[56px] bg-gray-900 border-b border-gray-800 relative gap-4 flex items-center justify-end pr-9"
    >
      <img
        class="h-6 absolute left-6 top-0 md:left-1/2 md:-translate-x-1/2 translate-y-[16px]"
        src="../assets/logos/refrag-horz-fill.svg"
        alt="Refrag Logo"
      />
      <router-link
        to="/login"
        class="px-3 py-2.5 bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700"
      >
        Login
      </router-link>
      <router-link
        to="/register"
        class="px-3 py-2.5 bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500"
      >
        Signup
      </router-link>
    </div>
    <div class="flex gap-6 max-w-7xl w-full lg:p-16 p-4 lg:flex-row flex-col">
      <div class="flex-[7] aspect-[16/9] w-full bg-gray-800 rounded-lg overflow-hidden">
        <iframe
          v-if="clip?.status == 'processed' || clip?.status == 'submitted'"
          :key="clip?.status"
          class="w-full h-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          :src="clip.clip_url + `&autoplay=1&mute=1`"
        >
        </iframe>
      </div>
      <div class="flex-[5] w-full flex-col gap-4">
        <div class="flex flex-col gap-6">
          <ul class="flex flex-col gap-3 text-text-secondary">
            <h1 class="text-text-primary text-xl font-bold flex-1">
              {{ clip?.title || "Loading..." }}
            </h1>
            <h2 class="text-text-secondary text-sm">
              {{ clip?.round_number ? `Round ${clip?.round_number}` : "-" }}
            </h2>
          </ul>
          <div class="w-full h-px bg-gray-800" />
          <div class="flex flex-col gap-3">
            <h1 class="text-text-primary text-lg font-bold">Share</h1>
            <div class="flex gap-3">
              <div
                v-clipboard:copy="`https://play.refrag.gg/highlights/${clip?.uuid}`"
                v-clipboard:success="copySucceeded"
                class="bg-primary-600 flex-1 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
              >
                <img class="brightness-200" src="../assets/icons/link.svg" />
                Copy link
              </div>
              <a
                :href="`https://twitter.com/intent/tweet?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
                target="_blank"
                class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
              >
                <img class="w-6 h-6" src="../assets/icons/twitter.svg" />
              </a>
              <a
                :href="`https://www.reddit.com/submit?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
                target="_blank"
                class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
              >
                <img class="w-6 h-6" src="../assets/icons/reddit.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center max-w-7xl w-full px-4 lg:px-16 flex-col lg:flex-row">
      <div
        class="flex gap-6 w-full bg-gray-900 items-center rounded-lg flex-col lg:flex-row"
      >
        <img src="../assets/logos/refrag-ad.png" alt="ads" class="rounded-md w-[175px]" />
        <div class="flex flex-col gap-3 justify-center flex-1">
          <h1 class="text-text-primary text-[24px] font-semibold">
            Elevate your CS2 Training
          </h1>
          <p class="text-text-secondary text-xs">
            Refrag is your ultimate training tool, designed to enhance your <br />skills,
            boost your confidence, and turn every gaming session <br />into an epic
            adventure.
          </p>
        </div>
        <div class="flex flex-row lg:flex-col gap-3 px-4 lg:px-8 w-full lg:w-auto">
          <router-link
            to="/register"
            class="px-6 py-2.5 flex w-full lg:w-auto items-center justify-center bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500"
          >
            Sign up For Free
          </router-link>
          <a
            href="https://wiki.refrag.gg/en/home"
            target="_blank"
            class="px-6 py-2.5 lg:mt-3 flex w-full lg:w-auto items-center justify-center bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { useStore } from "vuex";
import { useHead } from "@unhead/vue";

const auth = useAuth();
let user = auth.user();
const route = useRoute();
const clip = ref(null);
const store = useStore();
const clipEmbedUrl = computed(() => {
  return clip.value?.thumb_url
    .replace("thumbs", "clips")
    .replace("_thumb.jpg", ".mp4")
    .replace("media.allstar.gg", "mediacdn.allstar.gg");
});
const pageTitle = computed(() => {
  return clip.value?.title
    ? `Refrag | ${clip.value.title}${clip.value?.user?.display_name ? ' by ' + clip.value?.user?.display_name : ''}`
    : "Refrag | Highlight Page";
});

function getClip() {
  axios({
    url: `allstar/clips/${route.params.uuid}`,
    method: "GET",
  }).then((response) => {
    clip.value = response.data;
  });
}

function copySucceeded() {
  store.commit("showMessage", {
    messageType: "success",
    messageText: "Link Copied Successfully",
  });
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

useHead({
  title: () => pageTitle.value,
  meta: [
    {
      name: "description",
      content:
        "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
    {
      name: 'og:title',
      content: () => pageTitle.value
    },
    {
      name: "og:description",
      content:
        "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
    {
      name: "og:video:url",
      content: () => clipEmbedUrl.value,
    },
    {
      name: "og:video",
      content: () => clipEmbedUrl.value,
    },
    {
      name: 'twitter:video',
      content: () => clipEmbedUrl.value,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:domain',
      content: 'play.refrag.gg',
    },
    {
      name: "og:image",
      content: () => clip.value?.thumb_url,
    },
    {
      name: 'twitter:image',
      content: () => clip.value?.thumb_url,
    },
    {
      name: "og:type",
      content: "video",
    },
  ],
});

getClip();
</script>

<style>
.aspect-video {
  aspect-ratio: 16 / 9;
}
.custom-flex {
  flex: 1 1 1px;
}
</style>
