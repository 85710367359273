<template>
  <div class="flex flex-1 flex-row">
    <img class="w-9 h-9 left-0 top-0" src="/maps/de_mirage_badge.png" />
    <div class="flex flex-col flex-1 ml-3">
      <div class="flex flex-row justify-between items-center">
        <div class="text-text-primary text-sm font-medium leading-none">{{ badge.description }}</div>
        <div>
          <span class="text-text-primary text-xs font-medium leading-3">{{
            user.activity_streak >= badge.custom_fields.value ? badge.custom_fields.value : user.activity_streak
          }}</span>
          <span class="text-slate-400 text-xs font-medium leading-3">
            / {{ badge.custom_fields.value }}</span
          >
        </div>
      </div>
      <div class="flex mt-1">
        <div class="h-1 bg-indigo-700 inline-block" :style="progressStartStyle" />
        <div class="h-1 bg-gray-800 inline-block" :style="progressEndStyle" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BadgeProgress",
  props: {
    user: {
      type: Object,
      required: true,
    },
    badge: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progress() {
      if (this.user.activity_streak >= this.badge.custom_fields.value) return 100;

      return (this.user.activity_streak / this.badge.custom_fields.value) * 100;
    },
    progressStartStyle() {
      return { width: `${this.progress}%` };
    },
    progressEndStyle() {
      return { width: `${100 - this.progress}%` };
    },
  },
};
</script>
