<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep + 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 right-4 lg:right-16">
        <h1 class="text-xs text-text-primary">Skip</h1>
        <img class="w-4 ml-1" src="../../assets/icons/rightarrow.svg">
    </div>
    <div class="w-full px-4 lg:px-16 flex flex-col items-center mt-16 lg:mt-24">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Subscribe</h1>
        <h2 class="text-text-secondary text-center pb-4">Choose the right plan for you to start improving!</h2>
        <Upgrade />
        <div class="py-2 w-full"></div>
    </div>
</template>

<script setup>
import Upgrade from '../Upgrade.vue';

const emit = defineEmits(['updateOnboardingStep'])

const props = defineProps({
    currentStep: Number
});

</script>