<template>
  <div
    v-if="props.show"
    class="w-full h-full fixed inset-y-0 bg-black/60 z-50 top-0 left-0 flex items-center justify-center"
  >
    <div class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
        <h1 class="text-text-primary text-xl font-bold flex-1">Match Highlights</h1>
        <XIcon
          @click="closeModal()"
          class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"
        />
      </div>
      <div class="bg-gray-800 rounded-lg overflow-hidden w-full aspect-[16/9]">
        <iframe
          v-if="clip?.status == 'processed' || (clip?.status == 'submitted' && clip?.title)"
          :key="clip?.status"
          class="w-full h-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          :src="clip?.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
        >
        </iframe>
        <img v-if="clip?.status === 'created' || clip?.status === 'requested'" class="w-full h-full object-cover" src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/wordpress.refrag.gg/2024/05/processing.jpg/public" />
        <img v-if="clip?.status === 'failed'" class="w-full h-full object-cover" src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/wordpress.refrag.gg/2024/05/highlight.jpg/public" />
      </div>
      <div v-if="clip?.status === 'processed' || (clip?.status === 'submitted' && clip?.title)" class="flex flex-col gap-6">
        <ul class="flex flex-col gap-3 text-text-secondary">
          <h1 class="text-text-primary text-xl font-bold flex-1">
            {{ clip?.title || "Loading..." }}
          </h1>
          <h2 class="text-text-secondary text-sm">
            {{ clip?.round_number ? `Round ${clip?.round_number}` : "-" }}
          </h2>
        </ul>
        <div class="w-full h-px bg-gray-800" />
        <div class="flex flex-col gap-3">
          <h1 class="text-text-primary text-lg font-bold">Share</h1>
          <div class="flex gap-3">
            <div
              v-clipboard:copy="`https://play.refrag.gg/highlights/${clip?.uuid}`"
              v-clipboard:success="copySucceeded"
              class="bg-primary-600 flex-1 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
            >
              <img class="brightness-200" src="../assets/icons/link.svg" />
              Copy link
          </div>
            <a
              :href="`https://twitter.com/intent/tweet?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
              target="_blank"
              class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
            >
              <img class="w-6 h-6" src="../assets/icons/twitter.svg" />
            </a>
            <a
              :href="`https://www.reddit.com/submit?url=https://play.refrag.gg/highlights/${clip?.uuid}`"
              target="_blank"
              class="bg-gray-800 duration-300 p-3 hover:bg-gray-700 cursor-pointer text-text-primary text-semibold flex items-center justify-center rounded-md"
            >
              <img class="w-6 h-6" src="../assets/icons/reddit.svg" />
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <ul v-if="clip?.status === 'created' || clip?.status === 'submitted' || clip?.status === 'requested'" class="flex flex-col justify-center gap-3">
          <h1 class="text-text-primary text-xl font-bold flex-1 flex items-center gap-3">
            Processing clip
            <svg class="w-6 h-6 text-white animate-spin" fill="none"
            viewBox="0 0 24 24">
              <circle class="opacity-50" cx="12" cy="12" r="10" stroke="#1E2733" stroke-width="4"></circle>
              <path class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="#4E54C8">
              </path>
            </svg>
          </h1>
          <h2 class="text-text-secondary text-sm">
            Your clip is being processed, this may take a few minutes
          </h2>
        </ul>
        <ul v-if="clip?.status === 'failed'" class="flex flex-col gap-3 text-text-secondary">
          <h1 class="text-text-primary text-xl font-bold flex-1 flex items-center gap-3">
            <img class="w-6 h-6" src="../assets/icons/error.svg" />
            Failed to process clip
          </h1>
          <h2 class="text-text-secondary text-sm">
            Failed to process the clip, please contact support on our <a class="underline text-text-primary" href="https://discord.com/invite/Refrag" target="_blank">Discord</a>
          </h2>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XIcon } from "@heroicons/vue/outline";
import axios from "axios";
import { ref, watch, getCurrentInstance, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@websanova/vue-auth/src/v3.js";

const store = useStore();
const emit = defineEmits(["toggleModal"]);
const clip = ref({});
const websocket = ref(null);
const subscription = ref(null);
const instance = getCurrentInstance();
const auth = useAuth();

let user = auth.user();

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  clipId: {
    type: Object,
    required: true,
  },
});

function closeModal() {
  emit("close");
}

function getClip() {
  axios.get(`/allstar/clips/${props.clipId}`).then((response) => {
    clip.value = response.data;
    if (response.data.status == "created") {
      requestClip();
    }
    if (response.data.status != "processed") {
      return subscribeToAllstarClip();
    }
  });
}

function copySucceeded() {
  store.commit("showMessage", {
    messageType: "success",
    messageText: "Link Copied Successfully",
  });
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

function requestClip() {
  axios.post(`/allstar/clips/${props.clipId}/request_clip`).then((response) => {
    clip.value = response.data;
  });
}

function subscribeToAllstarClip() {
  const cable = instance.appContext.config.globalProperties.$cable;

  if (!websocket.value) {
    websocket.value = cable.connect();
  }

  if (!subscription.value) {
    subscription.value = websocket.value.subscriptions.create(
      { channel: "AllstarClipChannel", id: clip.value.id },
      {
        received: (data) => {
          clip.value = data;
        },
      }
    );
  }
}

function disconnect() {
  if (subscription.value) {
    subscription.value.unsubscribe();
    subscription.value = null;
  }
  if (websocket.value) {
    websocket.value.disconnect();
    websocket.value = null;
  }
}

onBeforeUnmount(disconnect);

watch(
  () => props.show,
  (value) => {
    if (value) {
      getClip();
    } else {
      disconnect();
    }
  }
);
</script>
