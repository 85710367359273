<template>
  <DarkFull>
    <div class="min-h-full flex flex-col bg-gray-950">
      <div class="w-full h-16 border-b border-gray-900 flex items-center justify-center">
        <img class="mx-auto h-6 w-auto" :src="Logo" alt="Refrag Logo" />
      </div>
      <div class="w-full flex-1 items-center justify-center flex flex-col">
        <div class="sm:mx-auto sm:w-full sm:max-w-md bg-gray-900 rounded-lg">
          <div class="py-8 px-4 shadow sm:rounded-md sm:px-6">
            <div class="space-y-6 border-b border-gray-800 pb-6">
              <div class="flex flex-col w-full items-center border-b border-gray-800 pb-6">
                <h1 class="text-text-primary font-semibold text-[24px]">Log In</h1>
                <h2 class="text-text-secondary">Sign in to your existing Refrag account</h2>
              </div>

              <div>
                <label for="email" class="block text-sm font-medium text-gray-100">
                  Email address
                </label>
                <div class="mt-2">
                  <input
                    v-model="state.form.body.email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder="Enter your email"
                    required="true"
                    class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                  />
                </div>
                <div class="text-danger text-sm">{{ state.form.errors.email }}</div>
              </div>

              <div>
                <div class="flex">
                  <label for="password" class="block text-sm font-medium text-gray-100 flex-1">
                    Password
                  </label>
                  <router-link
                      to="/reset_password"
                      class="text-xs text-primary-400 hover:text-primary-300"
                    >
                    Forgot Password?
                  </router-link>
                </div>

                <div class="mt-2">
                  <input
                    v-model="state.form.body.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    placeholder="Enter your password"
                    required="true"
                    class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                  />
                </div>
                <div class="text-danger text-sm">{{ state.form.errors.password }}</div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    v-model="state.form.body.remember"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label for="remember-me" class="ml-2 block text-sm text-gray-100">
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <div>
                  <button
                    @click="loginRedirect"
                    type="submit"
                    class="w-full flex justify-center py-3 px-4 border border-transparent duration-300 rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Log in
                    <!-- spinner when clickked -->
                    <svg
                      v-if="loading"
                      class="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div class="relative flex py-5 items-center">
                  <div class="flex-grow border-t border-gray-800"></div>
                  <span class="flex-shrink mx-4 text-text-secondary uppercase font-light text-sm tracking-[1.5px]">or</span>
                  <div class="flex-grow border-t border-gray-800"></div>
                </div>

                <div>
                  <form action="https://steamcommunity.com/openid/login" method="post">
                    <input
                      type="hidden"
                      name="openid.identity"
                      value="http://specs.openid.net/auth/2.0/identifier_select"
                    />
                    <input
                      type="hidden"
                      name="openid.claimed_id"
                      value="http://specs.openid.net/auth/2.0/identifier_select"
                    />
                    <input
                      type="hidden"
                      name="openid.ns"
                      value="http://specs.openid.net/auth/2.0"
                    />
                    <input type="hidden" name="openid.mode" value="checkid_setup" />
                    <input
                      type="hidden"
                      name="openid.return_to"
                      :value="getSteamAuthURL()"
                    />
                    <button
                      type="submit"
                      class="justify-center items-center w-full py-3 flex gap-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white duration-300 bg-gray-800 hover:bg-gray-700 focus:outline-none"
                    >
                      <img class="w-4 h-4" src="../assets/icons/steam-icon.svg" />
                      Log In with Steam
                    </button>
                  </form>
                </div>
              </div>
              <div>
                <p class="text-text-secondary text-xs text-center">
                  By logging into Refrag, you accept<br> our
                  <a
                    target="_blank"
                    class="text-primary-400"
                    href="https://wiki.refrag.gg/privacy-policy"
                    >privacy policy</a
                  >
                  and our
                  <a
                    target="_blank"
                    class="text-primary-400"
                    href="https://wiki.refrag.gg/terms-of-service"
                    >terms of service</a
                  >.
                </p>
              </div>
            </div>
            <div class="flex gap-2 w-full items-center justify-center pt-6">
              <h1 class="text-text-primary text-sm">Don't have an account?</h1>
              <a @click="navToRegister()" class="text-primary-400 underline text-xs cursor-pointer hover:text-primary-300">Sign up here</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DarkFull>
</template>

<script>
import Logo from "../assets/logos/refrag-horz-fill.svg";
import DarkFull from "../components/layouts/DarkFull.vue";

import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { reactive, onMounted, getCurrentInstance } from "vue";
import { mapMutations } from "vuex";
import { useHead } from '@unhead/vue'
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const auth = useAuth();
    const { proxy } = getCurrentInstance();
    const cookies = proxy.$cookies;
    const router = useRouter();
    const route = useRoute();

    const state = reactive({
      form: {
        body: {
          email: "",
          password: "",
        },
        remember: false,
        fetchUser: true,
        staySignedIn: true,
        errors: {},
      },
    });

    useHead({
      title: "Refrag | Login",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });

    onMounted(() => {
      if (auth.redirect()?.from?.query.info) {
        if (cookies.get('has_logged_in') !== 'true') {
          window.location.href = `https://refrag.gg`;
        }
      }
    });

    function errors(res) {
      state.form.errors = Object.fromEntries(
        res.data.errors.map((item) => [item.field, item.msg])
      );
    }

    return {
      state,
      cookies,
      proxy
    };
  },
  data() {
    return {
      Logo,
      loading: false,
    };
  },
  components: {
    DarkFull,
  },
  methods: {
    ...mapMutations(["showMessage"]),
    navToRegister() {
      this.$router.push({
        name: "Register",
        hash: this.$route.redirectedFrom?.hash,
      });
    },
    getEnvVar() {
      if (import.meta.env.VITE_ENVIRONMENT) return import.meta.env.VITE_ENVIRONMENT;
      return "";
    },
    getSteamAuthURL() {
      const envVar = this.getEnvVar();
      if (envVar == "development") {
        return `http://localhost:3000/auth/steam/callback?redirect_url=${this.getSteamRedirectURL()}`;
      }
      return `https://${envVar}api.refrag.gg/auth/steam/callback?redirect_url=${this.getSteamRedirectURL()}`;
    },
    getSteamRedirectURL() {
      const envVar = this.getEnvVar();
      if (envVar == "development") {
        return `http://localhost:5173/steam/callback`;
      }
      return `https://${envVar}play.refrag.gg/steam/callback`;
    },
    loginRedirect() {
      this.loading = true;
      this.$auth
        .login({
          data: this.state.form.body,
          remember: this.state.form.remember ? '{"name": "Redirect"}' : null,
          fetchUser: this.state.form.fetchUser,
          staySignedIn: this.state.form.staySignedIn,
        })
        .then((res) => {
          this.cookies.set('has_logged_in', true, '365d', null, '.refrag.gg');
          this.$gtm.push({
            event: "sign_in",
            email: this.state.form.body.email,
            steam_id: res.data.data.steam_id,
            user_id: res.data.data.id,
          });
          this.$router.push(this.$route.redirectedFrom || "/");
        })
        .then(null, (res) => {
          this.showMessage({
            messageType: "error",
            messageText: res.response.data.errors[0],
          });
          this.loading = false;
        });
    },
    loginRedirectSteam() {
      this.loading = true;
    },
  },
};
</script>
