<template>
  <Dashboard v-if="showUpgrade" title="Bootcamp" no-padding="true">
    <!-- show screenshot and push to upgrade -->
    <div class="w-full h-full bg-gray-950 relative flex justify-center items-center">
      <img
        class="select-none w-full h-full object-cover"
        src="../../assets/bootcamp-preview.png"
      />
      <div
        class="w-full h-full bg-black/60 z-50 absolute items-center justify-center flex"
      >
        <UpgradeModal
          title="Upgrade to access Refrag Bootcamp"
          tier="Player"
          firstBenefit="Refrag Academy"
          secondBenefit="Utility Hub"
          primaryPromotionType="bootcamp"
          video_url="https://www.youtube.com/embed/dC_uuBEZ9CI?si=fj-nBsQhEyA6asV2?autoplay=1"
        >
        </UpgradeModal>
      </div>
    </div>
  </Dashboard>
  <Dashboard v-else title="Bootcamps">
    <div class="flex flex-col gap-8 w-full h-full">
      <div class="flex gap-10 items-center">
        <div class="flex flex-col gap-3 flex-1">
          <h1 class="text-4xl font-medium text-text-primary">Bootcamp</h1>
          <p class="text-text-secondary text-sm font-light">
            Collection of various bootcamps prepared to elevate your skills.
          </p>
        </div>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full lg:w-auto min-w-[10rem] disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light">
              <span class="text-text-secondary">Map:</span>
              {{ selectedCategory?.name || "All" }}
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem @click="updateSelectedCategory(null)">
                  <a
                    href="#"
                    :class="[
                      selectedCategory === null
                        ? 'text-text-primary'
                        : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    All Maps
                    <CheckIcon
                      v-if="selectedCategory === null"
                      class="h-5 w-5 ml-auto text-primary-400"
                    />
                  </a>
                </MenuItem>
                <MenuItem
                  :key="category"
                  v-for="category in bootcampCategories"
                  v-slot="{ active }"
                  @click="updateSelectedCategory(category)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start',
                    ]"
                  >
                    {{ category.name }}
                    <CheckIcon
                      v-if="selectedCategory?.name === category.name"
                      class="h-5 w-5 ml-auto text-primary-400"
                    />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div v-if="loading" class="h-full w-full flex justify-center items-center">
        <p class="text-white">
          <svg
            class="animate-spin h-full font-normal w-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </p>
      </div>
      <div v-else>
        <div v-if="enrolledBootcamps?.length > 0" class="flex flex-col gap-4">
          <h1 class="text-2xl font-medium text-text-primary">In Progress</h1>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div
              v-for="bootcamp in enrolledBootcamps"
              @click="router.push({ name: 'BootcampPage', params: { slug: bootcamp.slug } })"
              class="bg-gray-900 rounded-lg w-full flex flex-col overflow-hidden hover:scale-[1.02] duration-300 cursor-pointer"
            >
              <div class="w-full aspect-[2.25] bg-gray-800">
                <img :src="bootcamp.image_url" alt="bootcamp" class="object-cover w-full h-full" />
              </div>
              <div class="p-4 flex flex-col gap-4 divide-y divide-gray-800 flex-1">
                <div class="flex gap-2 flex-wrap">
                  <h1 class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1 whitespace-nowrap">
                    <img src="../../assets/icons/user.svg" alt="beginner" class="w-4 h-4" />
                    {{ bootcamp?.students_count }} Students
                  </h1>
                  <h1 class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1 whitespace-nowrap">
                    <img src="../../assets/icons/speedometor.svg" alt="beginner" class="w-4 h-4" />
                    {{ getDifficultiesString(bootcamp) }}
                  </h1>
                </div>
                <div class="flex flex-col gap-2 pt-4 flex-1">
                  <h1 class="text-xl font-medium text-text-primary">{{ bootcamp.name }}</h1>
                  <p class="text-text-secondary text-sm font-light">{{ bootcamp.description }}</p>
                </div>
                <div class="flex w-full pt-4 items-center justify-between">
                  <div v-for="completion in getCompletionsForBootcamp(bootcamp)" class="flex">
                    <RadialProgress
                      :diameter="64"
                      :completed-steps="completion.completion"
                      :total-steps="100"
                      strokeWidth="4"
                      innerStrokeWidth="4"
                      :innerStrokeColor="getDifficultyColor(completion.difficulty) + '30'"
                      :startColor="getDifficultyColor(completion.difficulty)"
                      :stopColor="getDifficultyColor(completion.difficulty)"
                      strokeLinecap="flat"
                    >
                      <h2 class="text-text-primary">{{ completion.completion?.toFixed(0) || 0 }}%</h2>
                    </RadialProgress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <h1
            v-if="enrolledBootcamps.length > 0 && notEnrolledBootcamps.length > 0"
            class="text-2xl font-medium text-text-primary"
          >
            Other
          </h1>
          <div
            class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6"
          >
            <div
              v-for="bootcamp in notEnrolledBootcamps"
              @click="
                router.push({ name: 'BootcampPage', params: { slug: bootcamp.slug } })
              "
              class="bg-gray-900 rounded-lg w-full flex flex-col overflow-hidden hover:scale-[1.02] duration-300 cursor-pointer"
            >
              <div class="w-full aspect-[2.25] bg-gray-800">
                <img
                  :src="bootcamp.image_url"
                  alt="bootcamp"
                  class="object-cover w-full h-full"
                />
              </div>
              <div class="p-4 flex flex-col gap-4 divide-y divide-gray-800">
                <div class="flex gap-2 flex-wrap">
                  <h1
                    class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1 whitespace-nowrap"
                  >
                    <img
                      src="../../assets/icons/user.svg"
                      alt="beginner"
                      class="w-4 h-4"
                    />
                    {{ bootcamp?.students_count }} Students
                  </h1>
                  <h1
                    class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1 whitespace-nowrap"
                  >
                    <img
                      src="../../assets/icons/speedometor.svg"
                      alt="beginner"
                      class="w-4 h-4"
                    />
                    {{ getDifficultiesString(bootcamp) }}
                  </h1>
                </div>
                <div class="flex flex-col gap-2 pt-4">
                  <h1 class="text-xl font-medium text-text-primary">
                    {{ bootcamp.name }}
                  </h1>
                  <p class="text-text-secondary text-sm font-light">
                    {{ bootcamp.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import axios from "axios";
import { computed, ref } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import RadialProgress from "vue3-radial-progress";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon, XIcon } from "@heroicons/vue/outline";
import UpgradeModal from "../../components/UpgradeModal.vue";
import { useHead } from "@unhead/vue";

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const user = ref(auth.user());
const bootcampCategories = ref([]);
const currentBootcamp = ref(null);
const loading = ref(true);
const selectedCategory = ref(null);
const showUpgrade = ref();
const bootcampsCompletion = ref([]);
const showCompletion = ref(false);

useHead({
  title: "Refrag | Bootcamp",
  meta: [
    {
      name: "description",
      content: "Collection of various bootcamps prepared to elevate your skills.",
    },
  ],
});

if (user.value?.access_level < 1) {
  showUpgrade.value = true;
}

function getBootcamps() {
  loading.value = true;
  axios.get("/task_list_categories?is_exam=false").then((res) => {
    bootcampCategories.value = res.data;
    currentBootcamp.value = res.data[0];
    loading.value = false;
  });
}

function getMyBootcampCompletionStatus() {
  axios.get("/task_lists/my_bootcamp_completion_status").then((res) => {
    bootcampsCompletion.value = res.data;
  }).then(() => {
    showCompletion.value = true;
  })
}

function getCompletionsForBootcamp(bootcamp) {
  return bootcampsCompletion.value.filter((completion) => completion.id === bootcamp.id)[0]?.completion;
}

function updateSelectedCategory(category) {
  selectedCategory.value = category;
}

function getDifficultyColor(difficulty) {
  switch (difficulty) {
    case "beginner":
      return "#B8C7D5";
    case "intermediate":
      return "#B563FF";
    case "advanced":
      return "#FBD500";
  }
}

function getDifficultiesString(bootcamp) {
  return bootcamp?.difficulties
    .map((difficulty) => getFriendlyDifficultyName(difficulty))
    .join(", ");
}

function getFriendlyDifficultyName(difficulty) {
  return difficulty.charAt(0).toUpperCase() + difficulty.slice(1);
}

const bootcamps = computed(() => {
  if (selectedCategory.value) {
    return selectedCategory.value.task_lists;
  }
  return bootcampCategories.value.map((category) => category.task_lists).flat();
});

const enrolledBootcamps = computed(() => {
  if (user.value?.access_level === 5) {
    return bootcamps.value.filter((bootcamp) => bootcamp.enrolled);
  }
  return bootcamps.value
    .filter((bootcamp) => bootcamp.enrolled)
    ?.filter((bootcamp) => bootcamp.status === "published");
});

const notEnrolledBootcamps = computed(() => {
  if (user.value?.access_level === 5) {
    return bootcamps.value.filter((bootcamp) => !bootcamp.enrolled);
  }
  return bootcamps.value
    .filter((bootcamp) => !bootcamp.enrolled)
    ?.filter((bootcamp) => bootcamp.status === "published");
});

if (user.value?.access_level > 0) {
  getBootcamps();
  getMyBootcampCompletionStatus();
}
</script>
