<template>
  <div class="text-[10px] rounded-md"
    :style="{ backgroundColor: props.bgColor, color: props.textColor }">
    {{ props.text }}
  </div>
</template>

<script setup>

const props = defineProps({
  text: {
    type: String,
    required: true
  }, 
  bgColor: {
    type: String,
    required: true
  },
  textColor: {
    type: String,
    required: true
  }
})

</script>