<template>
  <div class="gap-6 rounded-md flex flex-col z-50">
    <div class="">
      <h3 class="text-lg leading-6 font-medium text-white">Delete demos</h3>
      <p class="mt-1 text-sm text-gray-300">
        Are you sure you want to delete these demos?
      </p>
    </div>
    <div class="text-text-secondary">
      <ul>
        <li v-for="demo in selectedDemos" :key="demo.id">Demo #{{ demo.id }}</li>
      </ul>
    </div>
    <div class="pt-5">
      <button
        type="button"
        class="ml-3 float-right inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 px-4 py-3 hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        @click="deleteDemos"
      >
        Delete Demos
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default {
  data: function data() {
    return {
      selectedTeam: {},
      demos: [],
    };
  },
  props: ["selectedDemos"],
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    deleteDemos(e) {
      e.preventDefault();
      this.selectedDemos.forEach((demo) => {
        axios({
          url: `/demos/${demo.id}`,
          method: "DELETE",
        });
      });
      this.showMessage({
        messageType: "success",
        messageText: `The demos were deleted!`,
      });
      this.$emit("deleteDemos");
      this.$emit("toggleModal");
    },
  },
};
</script>

<style scoped>
.demos-enter-active {
  transition: all 0.4s ease-in;
}
.demos-leave-active {
  display: none;
}
.demos-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.demos-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
