<template>
  <DashboardLayout title="Upgrade Success">
    <div class="w-full h-full flex items-center justify-center">
      <!-- failed -->
      <div
        v-if="paymentFailed"
        class="max-w-4xl w-full h-full flex flex-col items-center gap-9"
      >
        <div class="flex flex-col gap-4 items-center justify-center">
          <div
            class="h-12 w-12 bg-danger-900 rounded-full flex items-center justify-center"
          >
            <img class="w-6 h-6" src="../assets/icons/error.svg" />
          </div>
          <h1 class="text-text-primary text-4xl font-semibold">Upgrade failed</h1>
          <h2 class="text-text-secondary text-center">
            Your transaction has failed. <br />
            Please try again or contact support.
          </h2>
          <div class="flex gap-4">
            <button
              class="bg-primary-600 hover:bg-primary-500 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
            >
              <h1 class="">Try again</h1>
            </button>
            <button
              class="bg-gray-800 hover:bg-gray-700 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
            >
              <h1 class="">Support</h1>
              <img
                class="w-4 h-4 brightness-200"
                src="../assets/icons/rightarrow.svg"
                alt="Arrow"
              />
            </button>
          </div>
        </div>
      </div>

      <!-- subscription ready -->
      <div
        v-else-if="paymentSuccessful"
        class="max-w-4xl w-full h-full flex flex-col items-center gap-9"
      >
        <div class="flex flex-col gap-4 items-center justify-center">
          <div
            class="h-12 w-12 bg-success-900 rounded-full flex items-center justify-center"
          >
            <CheckCircleIcon class="h-6 w-6 text-success-400" />
          </div>
          <h1 class="text-text-primary text-4xl font-semibold">Upgrade successful!</h1>
          <h2 class="text-text-secondary text-center">
            You have successfully upgraded{{ tierName && ` to the ${tierName} tier` }}.
            <br />
            Now you can enjoy all the features of Refrag!
          </h2>
        </div>

        <div class="flex flex-col gap-6 w-full">
          <div class="w-full bg-gray-900 rounded-lg flex gap-4 flex-col lg:flex-row">
            <div class="flex-1 p-4 lg:p-6 flex flex-col gap-3 items-start">
              <div
                class="h-10 w-10 rounded-full server-gradient border border-gray-700 flex justify-center items-center"
              >
                <img class="w-6 h-6" src="../assets/icons/bigserver.svg" />
              </div>
              <h1 class="text-text-primary text-xl font-semibold">
                Start your first server
              </h1>
              <h2 class="text-text-secondary text-sm">
                Click the button below and try out our game modes!
              </h2>
              <PlayNowDropdown />
            </div>
            <div class="flex-1 pl-4 lg:pl-0 lg:pt-6 w-full h-full">
              <div
                class="flex flex-col p-4 gap-3 items-center justify-center w-full h-full flex-col border-t-2 border-l-2 border-gray-800 rounded-tl-lg card-gradient"
              >
                <div
                  class="h-16 w-16 rounded-full server-gradient border border-gray-700 flex justify-center items-center"
                >
                  <img class="w-7 h-7" src="../assets/icons/bigserver.svg" />
                </div>
                <div class="flex flex-row items-center gap-3">
                  <div
                    class="px-2 py-1 gap-x-1.5 flex items-center bg-success-900 rounded-md"
                  >
                    <div
                      class="w-1.5 h-1.5 rounded-full bg-success-500 ring-2 ring-success-700"
                    ></div>
                    <h2 class="text-xs text-success-500 text-medium">Online</h2>
                  </div>
                  <div
                    class="px-2 py-1 gap-x-1.5 flex items-center bg-gray-800 rounded-md"
                  >
                    <img src="../assets/icons/locationpin.svg" />
                    <h2 class="text-xs text-text-secondary text-medium">Poland</h2>
                  </div>
                  <div class="w-[1px] h-4 bg-gray-800" />
                  <div
                    class="px-2 py-1 gap-x-1.5 flex items-center bg-gray-800 rounded-md"
                  >
                    <img src="../assets/icons/passwordkey.svg" />
                    <h2 class="text-xs text-text-secondary text-medium">*******</h2>
                  </div>
                </div>
                <h3 class="text-2xl leading-6 font-medium text-white">
                  refrag.dathost.net:26787
                </h3>
              </div>
            </div>
          </div>

          <!-- if team tier -->
          <div
            v-if="tierName == 'team'"
            class="w-full p-4 lg:p-6 bg-gray-900 rounded-lg flex gap-4 items-center"
          >
            <div
              class="min-w-10 h-10 w-10 rounded-full team-gradient border border-text-t flex justify-center items-center"
            >
              <img class="w-6 h-6" src="../assets/icons/orange-team.svg" />
            </div>
            <h1 class="text-text-primary text-xl font-semibold flex-1">
              Add your teammates
            </h1>
            <router-link
              to="/team"
              class="bg-gray-800 hover:bg-gray-700 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
            >
              <h1 class="text-sm">Go to your Team</h1>
              <img
                class="w-4 h-4 brightness-200"
                src="../assets/icons/rightarrow.svg"
                alt="Play icon"
              />
            </router-link>
          </div>

          <div class="flex flex-col lg:flex-row gap-6">
            <div class="flex flex-col gap-6 flex-1 p-4 lg:p-6 rounded-lg bg-gray-900">
              <div class="w-full flex aspect-[1.92] relative">
                <img class="w-full aspect-[1.92]" src="../assets/bootcamp-frame.png" />
                <img
                  class="absolute top-0 left-0 w-full aspect-[1.92]"
                  src="../assets/icons/mirage-advanced-badge.svg"
                />
              </div>

              <div class="flex-1 flex flex-col gap-3 items-start">
                <div
                  class="h-10 w-10 rounded-full bootcamp-gradient border border-secondary-500 flex justify-center items-center"
                >
                  <img class="w-6 h-6" src="../assets/icons/pink-bootcamp.svg" />
                </div>
                <h1 class="text-text-primary text-xl font-semibold">
                  Participate in Bootcamps
                </h1>
                <h2 class="text-text-secondary text-sm flex-1">
                  Learn maps the right way with Refrag bootcamps!
                </h2>
                <router-link
                  to="/bootcamp"
                  class="bg-gray-800 hover:bg-gray-700 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
                >
                  <h1 class="text-sm">Go to Bootcamps</h1>
                  <img
                    class="w-4 h-4 brightness-200"
                    src="../assets/icons/rightarrow.svg"
                    alt="Play icon"
                  />
                </router-link>
              </div>
            </div>

            <!-- player tier -->
            <div
              v-if="!tierName || tierName == 'player'"
              class="flex flex-col gap-6 flex-1 p-6 rounded-lg bg-gray-900"
            >
              <div class="w-full flex aspect-[1.92] relative rounded-lg overflow-hidden">
                <iframe
                  src="https://customer-o1jnfnsdef83eyjx.cloudflarestream.com/a68fa80aadd03bbe160228d415553aa8/iframe?poster=https%3A%2F%2Fimagedelivery.net%2F5wML_ikJr-qv52ESeLE6CQ%2Fa4add990-8305-43fe-6c4e-c3b6faf97800%2Fpublic"
                  loading="lazy"
                  class="w-full aspect-[1.92] rounded-lg"
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowfullscreen="true"
                ></iframe>
              </div>

              <div class="flex-1 flex flex-col gap-3 items-start">
                <div
                  class="h-10 w-10 rounded-full play-gradient border border-gray-500 flex justify-center items-center"
                >
                  <img class="w-6 h-6" src="../assets/icons/play.svg" />
                </div>
                <h1 class="text-text-primary text-xl font-semibold">How to use Refrag</h1>
                <h2 class="text-text-secondary text-sm flex-1">
                  This video will show you how to get the most out of your subscription.
                </h2>
                <a
                  href="https://wiki.refrag.gg/en/home"
                  target="_blank"
                  class="bg-gray-800 hover:bg-gray-700 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
                >
                  <h1 class="text-sm">Refrag Wiki</h1>
                  <img
                    class="w-4 h-4 brightness-200"
                    src="../assets/icons/rightarrow.svg"
                    alt="Play icon"
                  />
                </a>
              </div>
            </div>

            <!-- comp tier -->
            <div
              v-if="tierName == 'competitor' || tierName == 'team'"
              class="flex flex-col gap-6 flex-1 p-6 rounded-lg bg-gray-900"
            >
              <div class="w-full flex aspect-[1.92] relative">
                <img class="w-full aspect-[1.92]" src="../assets/academy-frame.png" />
              </div>

              <div class="flex-1 flex flex-col gap-3 items-start">
                <div
                  class="h-10 w-10 rounded-full demos-gradient border border-info-500 flex justify-center items-center"
                >
                  <img class="w-6 h-6" src="../assets/icons/blue-academy.svg" />
                </div>
                <h1 class="text-text-primary text-xl font-semibold">Dive into Academy</h1>
                <h2 class="text-text-secondary text-sm flex-1">
                  Learn from pros and improve your game with Refrag Academy!
                </h2>
                <router-link
                  to="/academy"
                  class="bg-gray-800 hover:bg-gray-700 duration-300 text-text-primary rounded-lg mt-2 py-2 px-4 flex items-center justify-center gap-2"
                >
                  <h1 class="text-sm">Go to Academy</h1>
                  <img
                    class="w-4 h-4 brightness-200"
                    src="../assets/icons/rightarrow.svg"
                    alt="Play icon"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pending -->
      <div v-else class="max-w-4xl w-full h-full flex flex-col items-center gap-9">
        <div class="flex flex-col gap-4 items-center justify-center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="animate-spin"
          >
            <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
            <path
              d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
              stroke="#4E54C8"
              stroke-width="2"
            />
          </svg>
          <h1 class="text-text-primary text-4xl font-semibold">Transaction pending</h1>
          <h2 class="text-text-secondary text-center">
            Your transaction is currently pending. <br />
            This page will update once your transaction is complete. <br />
            You also will receive an email once the transaction is complete. <br />
            You do not need to stay on this page for your transaction to finish. <br />
            If you have any questions, please contact support.
          </h2>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import DashboardLayout from "../components/layouts/DashboardLayout.vue";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@unhead/vue";
import axios from "axios";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useGtm } from "@gtm-support/vue-gtm";
import PlayNowDropdown from "../components/PlayNowDropdown.vue";

const gtm = useGtm();
const auth = useAuth();
const route = useRoute();
const router = useRouter();
const manualStatus = ref(null);
const status = computed(() => route.query.status); // done / invoice / delivering / troubled / full_change
const invoiceId = computed(() => route.query.invoice_id);
const paymentSuccessful = computed(
  () =>
    status.value === "done" || status.value === "full_change" || manualStatus.value === 1
);
const paymentFailed = computed(
  () => status.value === "troubled" || manualStatus.value === 0
);
const paymentPending = computed(
  () => status.value === "invoice" || status.value === "delivering"
);
const user = auth.user();
const tierName = computed(() => user?.active_subscription_tier?.active_subscription_tier);
const transactionDetails = ref({});

onMounted(() => {
  if (!status.value) {
    router.push({ path: "/upgrade" });
  }
  checkTransactionStatus();
  if (paymentPending.value) {
    setInterval(() => {
      checkTransactionStatus();
    }, 5000);
    axios.get(`/subscriptions/transaction_status/${invoiceId.value}`);
  }
  if (paymentSuccessful.value) {
    auth.fetch();
  }
});

function reportSuccessToGtm() {
  gtm.push({
    event: "purchase",
    ecommerce: {
      currency: transactionDetails.value?.currency,
      value: parseFloat(transactionDetails.value?.amount),
      tier: tierName?.value,
      transaction_id: invoiceId.value,
      items: [
        {
          item_id: invoiceId.value,
          item_name: `Refrag ${tierName?.value} Tier`,
        },
      ],
    },
  });
}

function checkTransactionStatus() {
  axios
    .get(`/subscriptions/transaction_status/${invoiceId.value}`)
    .then((response) => {
      if (response.data.status === "done") {
        auth.fetch();
        manualStatus.value = 1;
        transactionDetails.value = response.data;
        reportSuccessToGtm();
      } else if (response.data.status == "canceled") {
        manualStatus.value = 0;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      return true;
    });
}
</script>

<style>
.card-gradient {
  background: linear-gradient(
    180deg,
    rgba(55, 72, 93, 0.05) 0%,
    rgba(116, 151, 195, 0.05) 100%
  );
}

.server-gradient {
  background: linear-gradient(
    180deg,
    rgba(78, 84, 200, 0.3) 0%,
    rgba(38, 41, 98, 0.3) 100%
  );
}

.bootcamp-gradient {
  background: linear-gradient(
    180deg,
    rgba(165, 54, 152, 0.3) 0%,
    rgba(63, 21, 58, 0.3) 100%
  );
}

.demos-gradient {
  background: linear-gradient(
    180deg,
    rgba(11, 132, 188, 0.3) 0%,
    rgba(5, 60, 86, 0.3) 100%
  );
}

.team-gradient {
  background: linear-gradient(
    180deg,
    rgba(245, 147, 0, 0.3) 0%,
    rgba(143, 86, 0, 0.3) 100%
  );
}
</style>
