<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
</script>

<template>
  <Dashboard title="Team Invite">
    <div class="flex justify-center my-10">
      <div class="bg-white shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ teamInvite?.user?.email || "Someone" }} has invited you to join
            {{ teamInvite?.team?.name || "a team" }}!
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p>
                If you decide to join {{ teamInvite?.team?.name || "this team" }}, your
                grenades will be transferred to the team's inventory. <br />
                Your restrat demos will not automatically be copied over, but you will be
                able to copy them over at a later date.
              </p>
            </div>
            <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
              <button
                type="button"
                @click="acceptInvite"
                class="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                Accept Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  head: {
    title: "Refrag | Team Invite"
  },
  data: function data() {
    return {
      token: "",
      inviteStatus: "",
      teamInviteId: "",
      teamInvite: {},
      load: false,
    };
  },
  computed: {
    ...mapState(["activeTeam"]),
    user() {
      return this.$auth.user() || {};
    },
    teamName() {
      return this.team.name || "My Team";
    },
    team() {
      //Checks to see which team to display based on activeTeamId
      for (var i = 0; i < this.user.teams.length; i++) {
        if (this.user.teams[i].id === Number(localStorage.activeTeamId)) {
          return this.user.teams[i];
        }
      }
      return this.user.teams[0] || {};
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    this.getTeamInvite();
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    getTeamInvite() {
      axios
      .get(`/team_invites/${this.token}`)
      .then((response) => {
        if (response.data.email.toLowerCase() === this.user.email.toLowerCase()) {
          this.teamInvite = response.data;
          this.load = true;
        } else {
          this.$router.push("/");
        }
      })
      .catch((error) => {
        this.$router.push("/");
      });
    },
    acceptInvite() {
      //Adds user to team and changes page to that team page or dashboard
      axios({
        url: `/team_invites/${this.token}/accept`,
        method: "POST",
      })
        .then(() => {
          this.showMessage({ messageType: "success", messageText: `Accepted invite!` });
          this.$auth.fetch();
          this.$router.push("/");
        })
        .catch((err) => {
          this.showMessage({
            messageType: "error",
            messageText: `Error accepting invite!`,
          });
        });
    },
  },
};
</script>
