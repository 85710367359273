<template>
  <DarkFull>
    <div class="min-h-full flex flex-col bg-gray-950">
      <div class="w-full h-16 border-b border-gray-900 flex items-center justify-center">
        <img class="mx-auto h-6 w-auto" :src="Logo" alt="Refrag Logo" />
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md flex-1 flex items-center justify-center">
        <div class="py-8 px-4 shadow sm:rounded-md sm:px-6 bg-gray-900 rounded-lg">
          <div class="space-y-6">
            <div class="flex flex-col w-full items-center border-b border-gray-800 pb-6">
              <h1 class="text-text-primary font-semibold text-[24px]">Reset Password</h1>
              <h2 class="text-text-secondary">To reset password please provide your email</h2>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium text-text-primary">
                Email address
              </label>
              <div class="mt-2">
                <input
                  v-model="state.form.body.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder="Enter your email"
                  required="true"
                  class="text-text-primary bg-gray-800 appearance-none block w-full px-3 py-3 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
              <div class="text-danger text-sm">{{ state.form.errors.email }}</div>
            </div>

            <div>
              <button
                @click="resetPassword"
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary duration-300 bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DarkFull>
</template>

<script>
import axios from "axios";
import Logo from "../assets/logos/refrag-horz-fill.svg";
import DarkFull from "../components/layouts/DarkFull.vue";

import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { reactive } from "vue";
import { onMounted } from "vue";
import { mapMutations } from "vuex";

export default {
  setup() {
    const auth = useAuth();

    const state = reactive({
      form: {
        body: {
          email: "",
        },
        fetchUser: true,
        staySignedIn: true,
        errors: {},
      },
    });

    onMounted(() => {
      auth.redirect();
    });

    return {
      state,
    };
  },
  data() {
    return {
      Logo,
    };
  },
  head: {
    title: "Refrag | Reset Password",
  },
  components: {
    DarkFull,
  },
  methods: {
    ...mapMutations(["showMessage"]),
    resetPassword() {
      axios({
        url: "/auth/password",
        method: "POST",
        data: {
          redirect_url: "https://play.refrag.gg/choose_new_password",
          email: this.state.form.body.email,
        },
      }).then(() => {
        this.showMessage({ messageType: "success", messageText: "Password was reset!" });
        this.state.form.body.email = "";
      }).catch((err) => {
        this.showMessage({ messageType: "error", messageText: err.response.data.errors[0] });
      });
    },
  },
};
</script>
