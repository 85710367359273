<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep + 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 right-4 lg:right-16">
        <h1 class="text-xs text-text-primary">Skip</h1>
        <img class="w-4 ml-1" src="../../assets/icons/rightarrow.svg">
    </div>
    <div class="flex flex-col h-full items-center lg:justify-center w-full mt-16 lg:mt-0 lg:w-[414px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Bot & Server Setup</h1>
        <h2 class="text-text-secondary text-center pb-6">Add our Refrag Steam bot to receive updates. You can also set your preferred language in the settings.</h2>
        <div class="flex flex-col gap-6 w-full">
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6 gap-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Add Steam Bot</h1>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col gap-4 text-text-secondary text-sm">
                        <h1>You will receive notifications about:</h1>
                        <h1 class="flex gap-2 items-center"><CheckIcon class="h-5 w-5 text-primary-500"/>Server Details</h1>
                        <h1 class="flex gap-2 items-center"><CheckIcon class="h-5 w-5 text-primary-500"/>Highlights from your games</h1>
                        <h1 class="flex gap-2 items-center"><CheckIcon class="h-5 w-5 text-primary-500"/>Match details & more!</h1>
                    </div>
                    
                    <a 
                        v-if="!hasBotAdded"
                        :href="friendLink"
                        target="_blank"
                        class="cursor-pointer w-full py-2.5 text-text-primary bg-primary-600 hover:bg-primary-500 rounded-md flex items-center justify-center text-xs gap-2"
                    >
                        <img class="h-4" src="../../assets/icons/steam.svg" alt="Continue" />
                        Add to friends
                    </a>
                    <div v-else class="w-full py-2.5 text-text-secondary bg-primary-600/30 rounded-md flex items-center justify-center text-xs gap-2">
                        <ThumbUpIcon class="w-4 h-4 text-text-secondary"/>
                        Added to friends
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6 gap-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Server Setup</h1>
                </div>
                <div class="flex flex-col gap-6">
                    <h1 class="text-text-secondary text-sm">Choose preferred server language</h1>
                    
                    <div class="flex gap-10 items-center">
                        <h1 class="text-text-primary text-sm text-nowrap">Server Language</h1>

                        <Menu as="div" class="relative inline-block text-left w-full">
                          <MenuButton
                            class="relative w-full disabled:bg-gray-800 disabled:text-text-disabled inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
                          >
                            <div class="flex-1 text-left">
                              {{ selectedLanguage.name }}
                            </div>
                            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
                          </MenuButton>

                          <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                          >
                            <MenuItems
                              class="absolute right-0 z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div class="py-1 flex flex-col px-4">
                                <MenuItem
                                  :key="language"
                                  v-for="language in languages"
                                  v-slot="{ active }"
                                  @click="selectLanguage(language)"
                                >
                                  <div
                                    :class="[
                                      active ? 'text-text-primary' : 'text-text-secondary',
                                      'group flex items-center py-2 text-sm w-full justify-start cursor-pointer',
                                    ]"
                                  >
                                    {{ language.name }}
                                    <CheckIcon v-if="language.name === selectedLanguage?.name" class="h-5 w-5 ml-auto text-primary-400" />
                                  </div>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
        <button :disabled="!hasBotAdded" @click="continueAndSubmit()" class="cursor-pointer group w-full py-3 text-text-primary disabled:text-text-primary/30 bg-primary-600 disabled:bg-primary-600/30 hover:bg-primary-500 mt-10 rounded-md flex items-center justify-center gap-1">
            Continue
            <img class="h-4 brightness-200 group-disabled:brightness-50" src="../../assets/icons/rightarrow.svg" alt="Continue" />
        </button>
    </div>
    <AlertPopup />
</template>

<script setup>
import Pill from '../Pill.vue';
import { onMounted, ref, getCurrentInstance, onBeforeUnmount } from 'vue';
import { useAuth } from '@websanova/vue-auth/src/v3';
import { Transition } from 'vue';
import axios from 'axios';
import { CheckIcon, ThumbUpIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useRoute, useRouter } from 'vue-router';
import AlertPopup from '../AlertPopup.vue';
import { useStore } from 'vuex';

const auth = useAuth();
const user = ref(auth.user());
const store = useStore();
const matchToken = ref("");
const authenticationCode = ref("");
const emit = defineEmits(['updateOnboardingStep']);
const route = useRoute();
const router = useRouter();
const friendLink = ref("");
const websocket = ref(null);
const subscription = ref(null);
const instance = getCurrentInstance();
const hasBotAdded = ref(user.value.has_steam_bot_added);
const languages = [
  { name: "English", code: "en" },
  { name: "Português", code: "pt_t" },
  { name: "Danish", code: "dk"},
  { name: "Chinese", code: "cn" },
  { name: "Deutsch", code: "de" },
  { name: "Polish", code: "pl" },
]
const selectedLanguage = ref(languages[0]);

const props = defineProps({
    currentStep: Number
});

if (user.value.language) {
    selectedLanguage.value = languages.find(language => language.code === user.value.language);
}

function getBot() {
    axios({
        method: "get",
        url: "/steam_bots/bot",
    }).then((response) => {
        friendLink.value = response.data.data.invite_link;
    });
}

function selectLanguage(language) {
    selectedLanguage.value = language;
}

function subscribeToSteamBot() {
    const cable = instance.appContext.config.globalProperties.$cable;

    if (!websocket.value) {
    websocket.value = cable.connect();
    }
    if (!subscription.value) {
    subscription.value = websocket.value.subscriptions.create(
        { channel: "SteamBotChannel", user_id: user.value.id },
        {
        received: (data) => {
            if (data.event === "steam_bot_added") {
                hasBotAdded.value = true;
            }
        },
        }
    );
    }
}

getBot();
subscribeToSteamBot();

function continueAndSubmit() {
    emit('updateOnboardingStep', { value: props.currentStep + 1 });
    cleanupWebsocket();
    if (matchToken.value) {
        axios({
            method: "post",
            url: "/matches",
            params: {
            match: {
                match_platform: 'valve',
                match_token: matchToken.value,
            }
        },
      })
    }
    if (selectedLanguage.value) {
        axios({
            method: "put",
            url: "/users/update_user",
            data: {
                language: selectedLanguage.value.code,
            },
        }).then(() => {
            user.value.language = selectedLanguage.value.code;
        }); 
    }
}

function cleanupWebsocket() {
  if (subscription.value) {
    subscription.value.unsubscribe();
    subscription.value = null;
  }
  if (websocket.value) {
    websocket.value.disconnect();
    websocket.value = null;
  }
}

onBeforeUnmount(() => {
  cleanupWebsocket();
});


</script>
