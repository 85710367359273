<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div class="flex flex-col h-full items-center lg:justify-center w-full mt-16 lg:mt-0 lg:w-[640px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Rating</h1>
        <h2 class="text-text-secondary text-center pb-12">In order for you to get a personalized experience, <br/> select your MM rating value so we can track your progress!</h2>
        <div class="flex flex-col gap-2 lg:gap-9 w-full">
            <div class="flex flex-col lg:flex-row gap-2 lg:gap-9 w-full">
                <div @click="selectedRating = 0" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 0 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 0" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">No Rating</h1>
                    <img src="../../assets/icons/no-rating.svg">
                </div>
                <div @click="selectedRating = 4999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 4999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 4999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">0 - 4,999</h1>
                    <img src="../../assets/icons/4999-rating.svg">
                </div>
            </div>
            <div class="flex flex-col lg:flex-row gap-2 lg:gap-9 w-full">
                <div @click="selectedRating = 9999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 9999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 9999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">5,000 - 9,999</h1>
                    <img src="../../assets/icons/9999-rating.svg">
                </div>
                <div @click="selectedRating = 14999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 14999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 14999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">10,000 - 14,999</h1>
                    <img src="../../assets/icons/14999-rating.svg">
                </div>
            </div>
            <div class="flex flex-col lg:flex-row gap-2 lg:gap-9 w-full">
                <div @click="selectedRating = 19999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 19999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 19999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">15,000 - 19,999</h1>
                    <img src="../../assets/icons/19999-rating.svg">
                </div>
                <div @click="selectedRating = 24999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 24999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 24999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">20,000 - 24,999</h1>
                    <img src="../../assets/icons/24999-rating.svg">
                </div>
            </div>
            <div class="flex flex-col lg:flex-row gap-2 lg:gap-9 w-full">
                <div @click="selectedRating = 29999" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 29999 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 29999" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">25,000 - 29,999</h1>
                    <img src="../../assets/icons/29999-rating.svg">
                </div>
                <div @click="selectedRating = 30000" class="rounded-md border w-full p-5 flex gap-2 items-center cursor-pointer"
                    :class="[selectedRating == 30000 ? 'bg-primary-600/10 border-primary-600' : 'border-gray-800']">
                    <CheckCircleIcon v-if="selectedRating == 30000" class="h-5 w-5 text-primary-600"/>
                    <div v-else class="h-5 w-5 rounded-full border-2 border-gray-700" />
                    <h1 class="text-text-primary text-medium flex-1">30,000+</h1>
                    <img src="../../assets/icons/30000-rating.svg">
                </div>
            </div>
        </div>
        <div @click="submitRating" class="cursor-pointer w-full py-3 text-text-primary bg-primary-600 hover:bg-primary-500 mt-8 lg:mt-16 rounded-md flex items-center justify-center gap-1">
            Continue
            <img class="h-4 brightness-200" src="../../assets/icons/rightarrow.svg" alt="Continue" />
        </div>
    </div>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { ref } from 'vue';
import axios from 'axios';
import { useAuth } from '@websanova/vue-auth/src/v3';

const selectedRating = ref(0);
const auth = useAuth();
const user = ref(auth.user());

const emit = defineEmits(['updateOnboardingStep']);

const props = defineProps({
    currentStep: Number
});

function submitRating() {
    if (user.value.access_level > 0) {
        emit('updateOnboardingStep', { value: props.currentStep + 2 })
    } else {
        emit('updateOnboardingStep', { value: props.currentStep + 1 })
    }
    axios({
        method: "post",
        url: "/user_stats",
        data: {
            value: selectedRating.value,
            stat_type: 'Premier',
        },
    })
}

</script>