<template></template>

<script>
export default {
  data() {
    return {
        websocket: null,
        subscription: null,
    };
  },
  created() {
    if (this.subscription) return;

    this.websocket = this.$cable.connect();
    this.subscription = this.websocket.subscriptions.create(
      { channel: "WarmupSessionChannel", steam_id: this.$route.params.steam_id },
      {
        received: (data) => {
          if (data.status === "online") {
            this.state.serverLoading = false;
            this.state.serverInfo.ip = data.ip;
            this.state.serverInfo.port = data.port;
            this.state.serverInfo.password = data.password;
            this.updateActiveTeamData(this.$auth);
          }
          this.updateServer(data);
        },
      }
    );
  },
};
</script>
