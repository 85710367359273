<template>
  <DarkFull>
    <div class="min-h-full flex flex-col bg-gray-950">
      <div class="w-full h-16 border-b border-gray-900 flex items-center justify-center">
        <img class="mx-auto h-6 w-auto" :src="Logo" alt="Refrag Logo" />
      </div>

      <div class="sm:mx-auto sm:w-full sm:max-w-md flex items-center justify-center flex-1">
        <div class="sm:mx-auto sm:w-full sm:max-w-md bg-gray-900 rounded-lg py-8 px-6 shadow sm:rounded-md">
          <div class="space-y-6 border-b border-gray-800 pb-6">
            <div class="flex flex-col w-full items-center border-b border-gray-800 pb-6">
              <h1 class="text-text-primary font-semibold text-[24px]">Sign Up</h1>
              <h2 class="text-text-secondary">Create an account and start your training!</h2>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium text-gray-100">
                Email address
              </label>
              <div class="mt-2">
                <input
                  v-model="state.form.body.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder="Enter your email"
                  required="true"
                  class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
              <div class="text-red-500 text-sm">{{ state.form.errors.email }}</div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-100">
                Password
              </label>
              <div class="mt-2">
                <input
                  v-model="state.form.body.password"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  placeholder="At least 8 characters"
                  required="true"
                  class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
              <div class="text-red-500 text-sm">{{ state.form.errors.password }}</div>
            </div>

            <div>
              <label
                for="password_confirmation"
                class="block text-sm font-medium text-gray-100"
              >
                Password Confirmation
              </label>
              <div class="mt-2">
                <input
                  v-model="state.form.body.password_confirmation"
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  autocomplete="password_confirmation"
                  placeholder="Re-enter your password"
                  required="true"
                  class="text-text-primary placeholder:text-text-secondary bg-gray-800 appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-200 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm"
                />
              </div>
              <div class="text-red-500 text-sm">
                {{ state.form.errors.password_confirmation }}
              </div>
            </div>
            <div class="relative flex flex-col items-start">
              <div class="flex items-center h-5">
                <input
                  id="candidates"
                  aria-describedby="candidates-description"
                  type="checkbox"
                  class="outline-none focus:outline-none h-4 w-4 text-primary-500 border-gray-400 rounded bg-transparent"
                  v-model="state.form.body.email_sign_up"
                />
                <div class="ml-3 text-sm">
                  <label for="candidates" class="font-medium text-text-primary"
                    >Receive Emails</label
                  >
                </div>
              </div>
              <p id="candidates-description" class="text-text-secondary text-xs pt-2">
                Has to be checked for Subscribers to receive emails with monthly Free Access codes
              </p>
            </div>
            <div>
              <button
                @click="registerRedirect"
                type="submit"
                class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary bg-primary-600 hover:bg-primary-500 duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign Up
              </button>
            </div>
            <div>
              <p class="text-text-secondary text-xs text-center">
                By registering for an account on Refrag, you accept our
                <a
                  target="_blank"
                  class="text-primary-400"
                  href="https://wiki.refrag.gg/privacy-policy"
                  >privacy <br> policy</a
                >
                and our
                <a
                  target="_blank"
                  class="text-primary-400"
                  href="https://wiki.refrag.gg/terms-of-service"
                  >terms of service</a
                >.
              </p>
            </div>
          </div>
          <div class="flex gap-2 w-full items-center justify-center pt-6">
            <h1 class="text-text-primary text-sm">Already have an account?</h1>
            <router-link to="/login" class="text-primary-400 underline text-xs cursor-pointer hover:text-primary-300">Log in here</router-link>
          </div>
        </div>
      </div>
    </div>
  </DarkFull>
</template>

<script>
import Logo from "../assets/logos/refrag-horz-fill.svg";
import DarkFull from "../components/layouts/DarkFull.vue";

import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { reactive } from "vue";
import { onMounted, inject } from "vue";
import { useHead } from '@unhead/vue'
import { useRouter, useRoute } from "vue-router";
import { useGtm } from "@gtm-support/vue-gtm";


export default {
  setup() {
    const auth = useAuth();
    const router = useRouter();
    const route = useRoute();
    const gtm = useGtm();

    const state = reactive({
      form: {
        body: {
          email: "",
          password: "",
          password_confirmation: "",
          email_sign_up: false,
          confirm_success_url: "https://play.refrag.gg/",
        },
        remember: false,
        fetchUser: true,
        staySignedIn: true,
        errors: {},
      },
    });

    onMounted(() => {
      console.log(auth.redirect());
    });

    useHead({
      title: 'Refrag | Sign up',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    })

    function errors(res) {
      state.form.errors.email = res.data.errors.email[0];
      state.form.errors.password = res.data.errors.password[0];
      state.form.errors.password_confirmation = res.data.errors.password_confirmation[0];
    }

    function registerRedirect() {
      auth
        .register({
          data: state.form.body,
          fetchUser: state.form.fetchUser,
          redirect: { name: "Dashboard" },
          staySignedIn: true,
          autoLogin: true,
          fetchUser: true,
        })
        .then((res) => {
          gtm.push({
            event: 'register',
            email: state.form.body.email,
            user_id: res.data.data.id
          });
          router.push("/upgrade" + route.hash || "")
        })
        .then(null, (res) => {
          errors(res.response);
        });
    }

    return {
      state,
      registerRedirect,
    };
  },
  data() {
    return {
      Logo,
    };
  },
  components: {
    DarkFull,
  },
};
</script>
