<template>
  <Dashboard title="Redeem a code">
    <h1 class="text-text-primary text-[28px] font-medium">
      Redeem a code
    </h1>
    <div class="flex-1 p-4 bg-gray-900 rounded-lg ring-0 mt-8">
      <div class="px-4 py-5 sm:p-6">
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Enter your unique code and start using the best practice tool on the market!
          </p>
        </div>
        <form class="mt-5 sm:flex sm:items-center">
          <div class="w-full sm:max-w-xs">
            <label for="code" class="sr-only">Code</label>
            <input
              type="text"
              v-model="input"
              class="block w-full rounded-md bg-gray-800 border-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
              placeholder="Enter code"
            />
          </div>
          <button
            type="button"
            @click="redeemCode(input)"
            class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Redeem
          </button>
        </form>
      </div>
    </div>
  </Dashboard>
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { ref } from "vue";
import axios from "axios";
</script>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      input: "",
    };
  },
  head: {
    title: "Refrag | Redeem a code",
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    redeemCode(code) {
      axios({
        url: "/trial_codes/redeem",
        method: "POST",
        headers: {},
        data: {
          code: code,
        },
      })
        .then((res) => {
          this.$auth.fetch();
          this.$router.push({
            name: "Dashboard",
          });
        })
        .catch((err) => {
          this.showMessage({
            messageType: "error",
            messageText: err.response.data.errors[0],
          });
        });
    },
  },
  mounted() {
    // Check if the route has a query parameter by the name of code
    if (this.$route.query.code) {
      this.input = this.$route.query.code;
    }
  },
};
</script>
