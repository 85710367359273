<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
</script>

<template>
  <Dashboard :showSteamLogin="showSteamLogin" :key="showSteamLogin">
    <div class="flex justify-center my-10">
      <div class="bg-white shadow sm:rounded-md">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            This Steam ID is already connected to an account
          </h3>
          <div class="mt-2">
            <div class="max-w-xl text-sm text-gray-500">
              <p>
                If you believe this is an error please submit a support ticket
                <a class="text-blue-500 underline" href="https://discord.com/invite/BgjhD4Jd6A"
                  >here</a
                >
                <br />
                or try again with a different steam account.
              </p>
            </div>
            <div class="mt-5 text-center">
              <button
                @click="toggleSteamLogin"
                type="submit"
                class="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
              >
                Connect your account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script>
export default {
  head: {
    title: "Refrag | Steam Link Error"
  },
  data() {
    return {
      showSteamLogin: false,
    };
  },
  methods: {
    toggleSteamLogin() {
      this.showSteamLogin = true;
    },
  },
};
</script>
