<template>
  <div
    class="w-80 p-4 bg-gray-900 rounded-lg shadow border border-gray-800 flex-col justify-start items-start gap-6 inline-flex absolute right-5 top-20 z-50"
  >
    <div class="self-stretch flex-col justify-start items-center gap-4 flex">
      <div class="self-stretch justify-start items-center gap-3 inline-flex">
        <div
          class="w-[42px] h-[42px] bg-orange-gradient rounded-full justify-center items-center gap-2 flex"
        >
          <img class="w-8 h-8" src="../../../assets/icons/bolt.svg" />
        </div>
        <div class="flex-col justify-start items-center gap-2 inline-flex">
          <div class="text-text-primary text-xl font-bold leading-normal">
            {{ user.activity_streak || 0 }} Day Streak
          </div>
        </div>
      </div>
      <div class="h-[1px] w-full border-0 bg-gray-700"></div>
      <div class="flex-col justify-start gap-4 flex w-full">
        <h1 class="text-text-secondary text-sm leading-tight tracking-wide">
          Keep your streak alive by starting a server at least once a day!
        </h1>
        <!-- <div class="text-text-primary text-base font-semibold leading-tight tracking-wide">
          Badges
        </div>
        <div class="flex-col justify-start gap-6 flex">
          <BadgeProgress :user="user" :badge="badge" v-for="badge in badges" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BadgeProgress from "./BadgeProgress.vue";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      badges: []
    };
  },
  mounted() {
    this.getBadges();
  },
  methods: {
    getBadges() {
      // Fetch badges from API
      axios({
        method: "GET",
        url: "/badges?type=activity",
      })
        .then((response) => {
          this.badges = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  components: {
    BadgeProgress,
  },
};
</script>

<style scoped>

.bg-orange-gradient {
  background: linear-gradient(180deg, rgba(251, 105, 0, 0.3) 0%, rgba(251, 213, 0, 0.3) 100%);
}

.bolt-gradient {
  background: linear-gradient(180deg, #FB6900 0%, #FBD500 100%);
}

</style>
