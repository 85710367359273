<template>
  <div class="w-full flex flex-col gap-9">
    <div
      class="flex flex-col gap-4 w-full"
    >
      <div class="flex items-center gap-2">
        <h1 class="text-text-primary text-3xl font-semibold">Overview</h1>
        <div v-if="props.activeUserData && props.activeUserMatchData && props.activeUserTwoLowest?.length > 0" class="has-tooltip">
          <img src="../../assets/icons/info-icon.svg" alt="Avatar" class="w-6 h-6 cursor-pointer" />
          <div class="tooltip bg-gray-900 rounded-lg p-2 ml-8 -mt-7">
            <p class="text-text-secondary text-xs">Comparison of your match performance to your {{ props.matchData?.platform == 'faceit' ? 'Faceit' : props.matchData?.match_type }} ranks average</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4" v-if="props.activeUserData && props.activeUserMatchData && props.activeUserTwoLowest?.length > 0">
        <div
          class="grid grid-cols-1 lg:grid-cols-3 w-full gap-6"
        >
          <StatBlock
            v-for="stat in props.activeUserTwoLowest"
            :key="stat.title"
            :stat="stat"
            @open-server-modal="openServerModal"
          />
          <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg">
            <div class="px-4 py-3 text-text-primary">K/D/A</div>
            <div class="p-4 flex gap-2 items-center">
              <h2 class="text-text-secondary flex-1">Kills</h2>
              <h1 class="text-text-primary text-lg font-medium">
                {{ props.activeUserMatchData?.match_performance?.kills }}
              </h1>
            </div>
            <div class="p-4 flex gap-2 items-center">
              <h2 class="text-text-secondary flex-1">Assists</h2>
              <h1 class="text-text-primary text-lg font-medium">
                {{ props.activeUserMatchData?.match_performance?.assists }}
              </h1>
            </div>
            <div class="p-4 flex gap-2 items-center">
              <h2 class="text-text-secondary flex-1">Deaths</h2>
              <h1 class="text-text-primary text-lg font-medium">
                {{ props.activeUserMatchData?.match_performance?.deaths }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="userHighlights?.length > 0" class="flex flex-col gap-4 w-full">
      <div class="flex items-center gap-3">
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Highlights</h1>
        <button
          @click="updateTab('highlights')"
          class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
        >
          See more
        </button>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div v-for="highlight in userHighlights" :key="highlight.status" class="flex flex-col">
          <div class="aspect-[16/9] w-full bg-gray-800 rounded-t-lg overflow-hidden">
            <iframe
              v-if="highlight?.status == 'processed' || highlight?.status == 'submitted' || highlight?.status == 'created'"
              :key="highlight?.status"
              class="w-full h-full"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              :src="highlight.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
            >
            </iframe>
          </div>
          <div class="flex flex-col gap-2 bg-gray-900 rounded-b-lg p-4">
            <h1 class="text-text-primary text-lg font-medium">{{ highlight.title }}</h1>
            <h2 class="text-text-secondary text-sm">{{ highlight.round_number ? `Round ${highlight.round_number}` : "-" }} • {{ clipUser(highlight)?.player?.steam_profile?.personaName }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 w-full">

      <!-- Desktop -->
      <div class="items-center gap-3 hidden lg:flex">
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Scoreboard</h1>
        <div class="flex flex-row bg-gray-800 p-1 rounded-md">
          <div
            @click="updateSelectedTeams('all')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'all'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            All
          </div>

          <div class="w-[1px] bg-gray-700 mx-1 my-2"></div>

          <div
            @click="updateSelectedTeams('winningteam')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'winningteam'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            {{ teamALabel }}
          </div>

          <div class="w-[1px] bg-gray-700 mx-1 my-2"></div>

          <div
            @click="updateSelectedTeams('losingteam')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedTeams === 'losingteam'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            {{ teamBLabel }}
          </div>
        </div>

        <div class="flex flex-row bg-gray-800 p-1 rounded-md">
          <div
            @click="updateSelectedStats('general')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'general'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            General
          </div>

          <div class="w-[1px] bg-gray-700 mx-1 my-2"></div>

          <div
            @click="updateSelectedStats('aim')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'aim'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Aim
          </div>

          <div class="w-[1px] bg-gray-700 mx-1 my-2"></div>

          <div
            @click="updateSelectedStats('trades')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'trades'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Entry & Trades
          </div>

          <div class="w-[1px] bg-gray-700 mx-1 my-2"></div>

          <div
            @click="updateSelectedStats('utility')"
            class="w-full flex gap-2 text-sm items-center justify-center rounded-md py-1 px-2 cursor-pointer text-nowrap"
            :class="[
              selectedStats === 'utility'
                ? 'bg-gray-700 text-text-primary'
                : 'text-text-secondary bg-gray-800',
            ]"
          >
            Utility
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div class="flex flex-col gap-3 block lg:hidden">
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Scoreboard</h1>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light flex gap-1">
              <span class="text-text-secondary">Side:</span>
              <span v-if="selectedTeams">{{ selectedTeams === 'winningteam' ? teamALabel : selectedTeams === 'losingteam' ? teamBLabel : 'All' }}</span>
              <span v-else>All</span>
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem @click="updateSelectedTeams('all')">
                  <a
                    href="#"
                    :class="[
                      selectedTeams === 'all' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    All
                    <CheckIcon v-if="selectedTeams === 'all'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
                <MenuItem @click="updateSelectedTeams('winningteam')">
                  <a
                    href="#"
                    :class="[
                      selectedTeams === 'winningteam' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    {{ teamALabel }}
                    <CheckIcon v-if="selectedTeams === 'winningteam'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
                <MenuItem @click="updateSelectedTeams('losingteam')">
                  <a
                    href="#"
                    :class="[
                      selectedTeams === 'losingteam' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    {{ teamBLabel }}
                    <CheckIcon v-if="selectedTeams === 'losingteam'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light flex gap-1">
              <span class="text-text-secondary">Type:</span>
              <span v-if="selectedStats">{{ selectedStats === 'general' ? 'General' : selectedStats === 'aim' ? 'Aim' : selectedStats === 'trades' ? 'Entry & Trades' : 'Utility' }}</span>
              <span v-else>All</span>
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem @click="updateSelectedStats('general')">
                  <a
                    href="#"
                    :class="[
                      selectedStats === 'general' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    General
                    <CheckIcon v-if="selectedStats === 'general'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
                <MenuItem @click="updateSelectedStats('aim')">
                  <a
                    href="#"
                    :class="[
                      selectedStats === 'aim' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    Aim
                    <CheckIcon v-if="selectedStats === 'aim'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
                <MenuItem @click="updateSelectedStats('trades')">
                  <a
                    href="#"
                    :class="[
                      selectedStats === 'trades' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    Entry & Trades
                    <CheckIcon v-if="selectedStats === 'trades'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
                <MenuItem @click="updateSelectedStats('utility')">
                  <a
                    href="#"
                    :class="[
                      selectedStats === 'utility' ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start font-light',
                    ]"
                  >
                    Utility
                    <CheckIcon v-if="selectedStats === 'utility'" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

      </div>

      <!-- Desktop -->
      <div
        v-if="selectedTeams === 'winningteam' || selectedTeams === 'all'"
        class="bg-gray-900 rounded-lg shadow-lg text-text-primary text-sm hidden lg:block"
      >
        <table class="w-full text-right">
          <thead>
            <tr
              v-if="selectedStats === 'general'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Kills / Deaths / Assists"
              >
                K / D / A
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kill / Death ratio">
                K/D
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of shots hit that were headshots"
              >
                HS Kill %
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kills per round">
                KPR
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average Damage per round"
              >
                ADR
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="Percent of rounds in which player killed someone, assisted, stayed alive, or was traded"
              >
                KAST
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'aim'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to kill a player starting from the moment you see them in seconds"
              >
                TTK
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to damage a player starting from the moment you see them in seconds"
              >
                TTD
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of bullets hit when you can see someone"
              >
                Spotted Acc
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your shots were perfectly counter strafed"
              >
                Counter Strafe
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of kills that were caused by a headshot"
              >
                HS %
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="The average of how many degrees you have to move your crosshair to damage someone after seeing them"
              >
                Crosshair Placement
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="How many bullets hit the target when spraying (3 or more bullets)"
              >
                Recoil Accuracy
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'trades'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamALabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You got the first kill in a round"
              >
                Opening Kills
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You were the first person to die in a round"
              >
                Opening Deaths
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Killing the killer of a teammate within 5 seconds"
              >
                Trades
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Having the opportunity (seeing the enemy) to trade your teammate but not killing them"
              >
                Trade Fails
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were traded"
              >
                Deaths Traded
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were untradable"
              >
                Trade Death Fails
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'utility'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamALabel }}</th>
              <th class="py-4 px-4 font-light">Flash Assists</th>
              <th class="py-4 px-4 font-light">Enemies Flashed</th>
              <th class="py-4 px-4 font-light">Friends Flashed</th>
              <th class="py-4 px-4 font-light">Avg Flash Time</th>
              <th class="py-4 px-4 font-light">Util Damage</th>
              <th class="py-4 font-light px-4">Unused Util Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="selectedStats === 'general'"
              v-for="(player, index) in winningTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>

              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills }} /
                {{ player.match_performance.deaths }} /
                {{ player.match_performance.assists }}
              </td>
              <td class="py-2 px-4">
                {{
                  calculateKD(
                    player.match_performance.kills,
                    player.match_performance.deaths
                  )
                }}
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_kill_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills_per_round.toFixed(2) }}
              </td>
              <td class="py-2 px-4">
                {{ Math.round(player.match_performance.average_damage_per_round) }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.kast?.toFixed(2) }}</td>
            </tr>
            <tr
              v-if="selectedStats === 'aim'"
              v-for="(player, index) in winningTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_kill) }}s
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_damage) }}s
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.shots_at_low_velocity_rate * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.crosshair_placement.toFixed(2) }}°
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.recoil_control_accuracy * 100).toFixed(0) }}%
              </td>
            </tr>
            <tr
              v-if="selectedStats === 'trades'"
              v-for="(player, index) in winningTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.opening_success }}</td>
              <td class="py-2 px-4">{{ player.match_performance.opening_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.trade_kill_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.trade_kill_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.traded_death_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.traded_death_fails }}</td>
            </tr>
            <tr
              v-if="selectedStats === 'utility'"
              v-for="(player, index) in winningTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.flash_assists }}</td>
              <td class="py-2 px-4">{{ player.match_performance.enemies_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.friends_flashed }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.average_flash_time.toFixed(2) }}s
              </td>
              <td class="py-2 px-4">{{ player.match_performance.utility_damage }}</td>
              <td class="py-2 px-4">
                ${{ player.match_performance.unused_utility_value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="selectedTeams === 'losingteam' || selectedTeams === 'all'"
        class="bg-gray-900 rounded-lg shadow-lg text-text-primary text-sm hidden lg:block"
      >
        <table class="w-full text-right">
          <thead>
            <tr
              v-if="selectedStats === 'general'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Kills / Deaths / Assists"
              >
                K / D / A
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kill / Death ratio">
                K/D
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of shots hit that were headshots"
              >
                HS Kill %
              </th>
              <th class="py-4 px-4 font-light cursor-pointer" title="Kills per round">
                KPR
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average Damage per round"
              >
                ADR
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="Percent of rounds in which player killed someone, assisted, stayed alive, or was traded"
              >
                KAST
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'aim'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/2">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to kill a player starting from the moment you see them in seconds"
              >
                TTK
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Average time to damage a player starting from the moment you see them in seconds"
              >
                TTD
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of bullets hit when you can see someone"
              >
                Spotted Acc
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your shots were perfectly counter strafed"
              >
                Counter Strafe
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Percent of kills that were caused by a headshot"
              >
                HS %
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="The average of how many degrees you have to move your crosshair to damage someone after seeing them"
              >
                Crosshair Placement
              </th>
              <th
                class="py-4 font-light px-4 cursor-pointer"
                title="How many bullets hit the target when spraying (3 or more bullets)"
              >
                Recoil Accuracy
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'trades'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamBLabel }}</th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You got the first kill in a round"
              >
                Opening Kills
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="You were the first person to die in a round"
              >
                Opening Deaths
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Killing the killer of a teammate within 5 seconds"
              >
                Trades
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="Having the opportunity (seeing the enemy) to trade your teammate but not killing them"
              >
                Trade Fails
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were traded"
              >
                Deaths Traded
              </th>
              <th
                class="py-4 px-4 font-light cursor-pointer"
                title="How many of your deaths were untradable"
              >
                Trade Death Fails
              </th>
            </tr>
            <tr
              v-else-if="selectedStats === 'utility'"
              class="text-sm text-text-secondary text-nowrap"
            >
              <th class="py-4 px-4 font-light text-left w-1/3">{{ teamBLabel }}</th>
              <th class="py-4 px-4 font-light cursor-pointer">Flash Assists</th>
              <th class="py-4 px-4 font-light">Enemies Flashed</th>
              <th class="py-4 px-4 font-light">Friends Flashed</th>
              <th class="py-4 px-4 font-light">Avg Flash Time</th>
              <th class="py-4 px-4 font-light">Util Damage</th>
              <th class="py-4 font-light px-4">Unused Util Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="selectedStats === 'general'"
              v-for="(player, index) in losingTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills }} /
                {{ player.match_performance.deaths }} /
                {{ player.match_performance.assists }}
              </td>
              <td class="py-2 px-4">
                {{
                  calculateKD(
                    player.match_performance.kills,
                    player.match_performance.deaths
                  )
                }}
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_kill_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.kills_per_round.toFixed(2) }}
              </td>
              <td class="py-2 px-4">
                {{ Math.round(player.match_performance.average_damage_per_round) }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.kast?.toFixed(2) }}</td>
            </tr>
            <tr
              v-if="selectedStats === 'aim'"
              v-for="(player, index) in losingTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_kill) }}s
              </td>
              <td class="py-2 px-4">
                {{ convertNanoSeconds(player.match_performance.time_to_damage) }}s
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.shots_at_low_velocity_rate * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{
                  (player.match_performance.headshot_percentage * 100).toFixed(0)
                }}%
              </td>
              <td class="py-2 px-4">
                {{ player.match_performance.crosshair_placement.toFixed(2) }}°
              </td>
              <td class="py-2 px-4">
                {{ (player.match_performance.recoil_control_accuracy * 100).toFixed(0) }}%
              </td>
            </tr>
            <tr
              v-if="selectedStats === 'trades'"
              v-for="(player, index) in losingTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.opening_success }}</td>
              <td class="py-2 px-4">{{ player.match_performance.opening_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.trade_kill_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.trade_kill_fails }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.traded_death_successes }}
              </td>
              <td class="py-2 px-4">{{ player.match_performance.traded_death_fails }}</td>
            </tr>
            <tr
              v-if="selectedStats === 'utility'"
              v-for="(player, index) in losingTeam"
              @click="player?.player?.uuid ? router.push(`/user/${player?.player?.uuid}`) : null"
              :key="index"
              class="text-nowrap border-t border-t-gray-950"
              :class="[
                player?.player?.steam_id === user?.steam_id
                  ? 'box-border shadow-[inset_2px_0_0_0_#4E54C8] selected-user'
                  : '',
                player?.player?.uuid ? 'cursor-pointer' : '',
              ]"
            >
              <td class="py-3 px-4 flex items-center space-x-3">
                <img
                  :src="playerAvatar(player)"
                  alt="Avatar"
                  class="w-12 h-12 rounded-full"
                />
                <div class="flex flex-col items-start gap-1">
                  <span class="flex gap-2 items-center">
                    {{ playerName(player) }}
                    <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                    <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                  </span>
                  <Rank
                    :rank="player.match_performance.end_rank"
                    :matchType="matchData?.match_type"
                    :cs_map="matchData?.map_name"
                  />
                </div>
              </td>
              <td class="py-2 px-4">{{ player.match_performance.flash_assists }}</td>
              <td class="py-2 px-4">{{ player.match_performance.enemies_flashed }}</td>
              <td class="py-2 px-4">{{ player.match_performance.friends_flashed }}</td>
              <td class="py-2 px-4">
                {{ player.match_performance.average_flash_time.toFixed(2) }}s
              </td>
              <td class="py-2 px-4">{{ player.match_performance.utility_damage }}</td>
              <td class="py-2 px-4">
                ${{ player.match_performance.unused_utility_value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Mobile -->
      <div v-if="selectedTeams === 'winningteam' || selectedTeams === 'all'" class="flex flex-col lg:hidden w-full rounded-lg gap-px">
        <div class="py-4 px-3 text-text-secondary text-xs w-full bg-gray-900 rounded-t-lg">{{ teamALabel }}</div>
        <div class="flex bg-gray-900 px-3 py-4 w-full gap-4" v-for="(player, index) in winningTeam" :key="index">
          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'general'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">K / D / A</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kills }} / {{ player.match_performance.deaths }} / {{ player.match_performance.assists }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KD</h2>
              <h2 class="text-text-primary text-sm text-center">{{ calculateKD(player.match_performance.kills, player.match_performance.deaths) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">HS%</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.headshot_kill_percentage * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KPR</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kills_per_round.toFixed(2) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">ADR</h2>
              <h2 class="text-text-primary text-sm text-center">{{ Math.round(player.match_performance.average_damage_per_round) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KAST</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kast?.toFixed(2) }}</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'aim'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">TTK</h2>
              <h2 class="text-text-primary text-sm text-center">{{ convertNanoSeconds(player.match_performance.time_to_kill) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">TTD</h2>
              <h2 class="text-text-primary text-sm text-center">{{ convertNanoSeconds(player.match_performance.time_to_damage) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Spotted Acc</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Counter Strafe</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.shots_at_low_velocity_rate * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Headshot %</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.headshot_percentage * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">XHair Placement</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.crosshair_placement.toFixed(2) }}°</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Recoil Control</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.recoil_control_accuracy * 100).toFixed(0) }}%</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'trades'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Opening Kills</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.opening_success }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Opening Deaths</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.opening_fails }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Kills</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.trade_kill_successes }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Fails</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.trade_kill_fails }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Deaths Traded</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.traded_death_successes }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Death Fails</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.traded_death_fails }}</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'utility'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Flash Assists</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.flash_assists }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Enemies Flashed</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.enemies_flashed }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Friends Flashed</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.friends_flashed }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Avg Flash Time</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.average_flash_time.toFixed(2) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Utility Damage</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.utility_damage }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Unused Utility</h2>
              <h2 class="text-text-primary text-sm text-center">${{ player.match_performance.unused_utility_value }}</h2>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedTeams === 'losingteam' || selectedTeams === 'all'" class="flex flex-col lg:hidden w-full rounded-lg gap-px">
        <div class="py-4 px-3 text-text-secondary text-xs w-full bg-gray-900 rounded-t-lg">{{ teamBLabel }}</div>
        <div class="flex bg-gray-900 px-3 py-4 w-full gap-4" v-for="(player, index) in losingTeam" :key="index">
          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'general'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">K / D / A</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kills }} / {{ player.match_performance.deaths }} / {{ player.match_performance.assists }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KD</h2>
              <h2 class="text-text-primary text-sm text-center">{{ calculateKD(player.match_performance.kills, player.match_performance.deaths) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">HS%</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.headshot_kill_percentage * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KPR</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kills_per_round.toFixed(2) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">ADR</h2>
              <h2 class="text-text-primary text-sm text-center">{{ Math.round(player.match_performance.average_damage_per_round) }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">KAST</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.kast?.toFixed(2) }}</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'aim'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">TTK</h2>
              <h2 class="text-text-primary text-sm text-center">{{ convertNanoSeconds(player.match_performance.time_to_kill) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">TTD</h2>
              <h2 class="text-text-primary text-sm text-center">{{ convertNanoSeconds(player.match_performance.time_to_damage) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Spotted Acc</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.spotted_accuracy * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Counter Strafe</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.shots_at_low_velocity_rate * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Headshot %</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.headshot_percentage * 100).toFixed(0) }}%</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">XHair Placement</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.crosshair_placement.toFixed(2) }}°</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Recoil Control</h2>
              <h2 class="text-text-primary text-sm text-center">{{ (player.match_performance.recoil_control_accuracy * 100).toFixed(0) }}%</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'trades'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Opening Kills</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.opening_success }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Opening Deaths</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.opening_fails }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Kills</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.trade_kill_successes }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Fails</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.trade_kill_fails }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Deaths Traded</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.traded_death_successes }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Trade Death Fails</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.traded_death_fails }}</h2>
            </div>
          </div>

          <div class="grid grid-cols-3 w-full gap-4" v-if="selectedStats === 'utility'">
            <div class="flex items-center gap-3 col-span-3">
              <img
                :src="playerAvatar(player)"
                alt="Avatar"
                class="w-12 h-12 rounded-full"
              />
              <div class="flex flex-col items-start gap-1">
                <span class="flex gap-2 items-center text-text-primary">
                  {{ playerName(player) }}
                  <img v-if="player.player.subscribed" src="../../assets/logos/refrag-logo-holo.png" alt="Avatar" class="w-4 h-4" />
                  <img v-else-if="player.player.uuid" src="../../assets/logos/refrag-icon-button.svg" alt="Avatar" class="w-4 h-4" />
                </span>
                <Rank
                  :rank="player.match_performance.end_rank"
                  :matchType="matchData?.match_type"
                  :cs_map="matchData?.map_name"
                />
              </div>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Flash Assists</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.flash_assists }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Enemies Flashed</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.enemies_flashed }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Friends Flashed</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.friends_flashed }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Avg Flash Time</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.average_flash_time.toFixed(2) }}s</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Utility Damage</h2>
              <h2 class="text-text-primary text-sm text-center">{{ player.match_performance.utility_damage }}</h2>
            </div>

            <div class="flex flex-col col-span-1 gap-2">
              <h2 class="text-text-secondary text-sm text-center">Unused Utility</h2>
              <h2 class="text-text-primary text-sm text-center">${{ player.match_performance.unused_utility_value }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineEmits } from "vue";
import StatBlock from "./StatBlock.vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import unknownUserImage from "../../assets/unknown-user.png";
import Rank from "./Rank.vue";
import { useRouter } from "vue-router";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/outline";

const router = useRouter();

const props = defineProps({
  matchData: {
    type: Object,
    required: true,
  },
  activeUserTwoLowest: Object,
  activeUserMatchData: Object,
  activeUserData: Object,
});

const emit = defineEmits([
  "updateActiveTab",
  "updateActiveUserTwoLowest",
  "updateActiveUserMatchData",
  "openServerModal",
]);
const auth = useAuth();
const user = ref(auth.user());
const selectedTeams = ref("all");
const selectedStats = ref("general");

const playerName = (player) => {
  return player.player?.steam_profile?.personaName || player.player?.name;
};

const playerAvatar = (player) => {
  return player.player?.steam_profile?.avatarFull || unknownUserImage;
};

const calculateKD = (kills, deaths) => {
  return (kills / deaths).toFixed(2);
};

const teamALabel = computed(() => (isMatchTied.value ? "Team A" : "Winning Team"));
const teamBLabel = computed(() => (isMatchTied.value ? "Team B" : "Losing Team"));

const isMatchTied = computed(() => {
  return props.matchData?.winning_team === 0;
});

const winningTeam = computed(() => {
  if (isMatchTied.value) {
    return props.matchData?.players?.filter(
      (player) => player.match_performance.last_round_team === 2
    );
  }
  return props.matchData?.players?.filter(
    (player) => player.match_performance.last_round_team === props.matchData?.winning_team
  );
});

const losingTeam = computed(() => {
  if (isMatchTied.value) {
    return props.matchData?.players?.filter(
      (player) => player.match_performance.last_round_team === 3
    );
  }
  return props.matchData?.players?.filter(
    (player) => player.match_performance.last_round_team !== props.matchData?.winning_team
  );
});

const userHighlights = computed(() => {
  const userHighlights = props.matchData?.highlights?.filter(
    (highlight) => highlight.user_uuid === user.value?.uuid && highlight.status !== "created" && highlight.status !== "failed"
  ) || [];
  const otherHighlights = props.matchData?.highlights?.filter(
    (highlight) => highlight.user_uuid !== user.value?.uuid && highlight.status !== "created" && highlight.status !== "failed"
  ).slice(0, 3) || [];
  return [...userHighlights, ...otherHighlights];
});

function clipUser(clip) {
  return props.matchData.players.find(
    (player) => player.player.steam_id === clip.user_steam_id
  );
}

function openServerModal(selectedStat) {
  emit("openServerModal", selectedStat);
}

function getActiveUserTwoLowest() {
  // Ensure userData and userMatchData are available
  if (!props.activeUserData || !props.activeUserMatchData) {
    return [];
  }

  // Extract user data and match performance
  const userStats = props.activeUserData.stats;
  const matchStats = props.activeUserMatchData?.match_performance;

  let differences = [];

  // Iterate over the keys in userStats
  for (const key in userStats) {
    // Skip the key if it doesn't exist in matchStats
    if (!matchStats[key]) {
      continue;
    }

    if (!userStats[key].recommended_mods?.length) {
      continue;
    }

    // Calculate the difference between the user's average and the match performance
    let difference;
    let percentDifference;

    // Adjust the difference calculation based on the is_ascending flag
    if (userStats[key].is_ascending) {
      // For ascending stats, a negative difference means the match performance is worse
      difference = matchStats[key] - userStats[key].rating_range_average_value;
    } else {
      // For descending stats, a positive difference means the match performance is worse
      difference = userStats[key].rating_range_average_value - matchStats[key];
    }

    // Calculate the percentage difference
    let playerValue =
      userStats[key].rating_range_average_value === 0
        ? 1
        : userStats[key].rating_range_average_value;
    percentDifference = (difference / playerValue) * 100;

    if (percentDifference > 0) {
      continue;
    }

    // Add the difference to the differences array
    differences.push({
      title: key,
      value: matchStats[key],
      difference: difference.toFixed(2),
      percentDifference: percentDifference.toFixed(2),
      unit_type: userStats[key].unit_type,
      is_ascending: userStats[key].is_ascending,
      recommended_mods: userStats[key].recommended_mods,
    });
  }

  // Sort the differences to get the stats with the most significant percentage negative impact first
  differences.sort((a, b) => a.percentDifference - b.percentDifference);

  // Return the two stats with the largest negative percentage differences
  emit("updateActiveUserTwoLowest", differences.slice(0, 2));
}

const kdr = computed(() => {
  return (
    props.activeUserMatchData?.match_performance?.kills /
    props.activeUserMatchData?.match_performance?.deaths
  ).toFixed(2);
});

function updateTab(tab) {
  emit("updateActiveTab", tab);
}

function convertNanoSeconds(nanoSeconds) {
  return (nanoSeconds / 1000000000).toFixed(2);
}

function updateSelectedTeams(team) {
  selectedTeams.value = team;
}

function updateSelectedStats(stat) {
  selectedStats.value = stat;
}

function getActiveUserMatchData() {
  if (!user.value) {
    return;
  }
  // Ensure matchData is available and has players
  if (!props.matchData || !props.matchData?.players) {
    return null;
  }
  // Find and return the specific player's data based on steam_id
  emit(
    "updateActiveUserMatchData",
    props.matchData.players.find(
      (player) => player.player.steam_id == user.value.steam_id
    ) || null
  );
}

watch(
  () => props.matchData,
  () => {
    if (props.matchData?.players) {
      getActiveUserMatchData();
    }
  }
);

watch(
  () => [props.activeUserData, props.activeUserMatchData],
  () => {
    if (props.activeUserData && props.activeUserMatchData) {
      getActiveUserTwoLowest();
    }
  }
);

if (!props.activeUserMatchData) {
  getActiveUserMatchData();
}
</script>

<style scoped>
.selected-user {
  background: linear-gradient(
    90deg,
    rgba(78, 84, 200, 0.06) 0%,
    rgba(78, 84, 200, 0) 100%
  );
}
</style>
