<template>
  <div
    class="text-center cursor-pointer align-center"
    :style="{ width: `${(1 / totalRounds) * 100}%` }"
    @click="activateRound"
    @keydown="activateRound"
  >
    <figure class="image resultImage">
      <i :class="iconClass" v-if="shouldShow" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "RoundResult",
  props: {
    round: Object,
    team: Object,
    selectRound: Function,
    totalRounds: Number,
    roundNumber: Number,
  },
  methods: {
    activateRound: function activateRound() {
      this.selectRound(this.round);
    },
  },
  computed: {
    iconClass() {
      return {
        "#SFUI_Notice_Target_Bombed": "fas fa-bomb t_win ",
        "#SFUI_Notice_Terrorists_Win": "fas fa-skull t_win ",
        "#SFUI_Notice_CTs_Win": "fas fa-skull ct_win ",
        "#SFUI_Notice_Bomb_Defused": "fas fa-wrench ct_win ",
        "#SFUI_Notice_Target_Saved": "fas fa-clock ct_win ",
        "#CTWIN": "fas fa-check-circle ct_win ",
        "#TWIN": "fas fa-check-circle t_win ",
      }[this.round.message];
    },
    shouldShow() {
      return this.round.winner_team === this.team.m_szClanTeamname;
    },
  },
};
</script>

<style lang="scss" scoped>
.resultImage {
  height: 21px;
}
</style>
