<script setup>
import {
  PlayIcon,
  CheckCircleIcon,
  XCircleIcon,
  DownloadIcon,
} from "@heroicons/vue/outline";
import { integer } from "@vuelidate/validators";
</script>

<template>
  <tr class="group">
    <td class="flex w-12 pt-6 group-last:pb-6">
      <input
        type="checkbox"
        :value="checkboxVal"
        v-bind:checked="checkboxVal"
        v-on:input="updateSelected(demo)"
        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
      />
    </td>
    <td class="whitespace-nowrap text-sm items-center pt-6 group-last:pb-6 pr-4">
      <span v-if="demo.cs_map" class="flex gap-2 items-center">
        <img
          :src="`/maps/map_icon_${demo.cs_map?.name}.svg`"
          class="h-6 w-6 min-w-6 rounded-full"
        />
        <span class="text-text-primary text-sm hidden lg:block">{{
          demo.cs_map?.friendly_name
        }}</span>
      </span>
      <span class="text-text-primary text-sm hidden lg:block" v-else>-</span>
    </td>
    <td
      class="whitespace-nowrap text-sm text-text-primary pt-6 text-ellipsis group-last:pb-6 overflow-hidden pr-4"
    >
      <span v-if="validateDemo() && hasTeamNames">
        {{ demo.team_names.CT }}
        vs
        {{ demo.team_names.T }}
      </span>
      <span v-else>-</span>
    </td>
    <td class="whitespace-nowrap text-sm text-text-primary pt-6 group-last:pb-6 pr-4">
      <span v-if="validateDemo() && hasTeamScores">
        {{ demo.team_scores.CT }}:{{ demo.team_scores.T }}
      </span>
      <span v-else>-</span>
    </td>
    <td
      class="whitespace-nowrap text-sm text-text-primary pt-6 group-last:pb-6 overflow-hidden text-ellipsis max-w-12 lg:max-w-none"
    >
      <span>
        {{ moment(demo.created_at).format("MMM Do YYYY") }}
      </span>
    </td>
    <td class="text-center flex gap-2 justify-end pt-6 group-last:pb-6">
      <div
        class="inline-flex items-center justify-center items-center h-8 w-8 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
        @click="downloadDemo()"
      >
        <DownloadIcon class="w-4 h-4 text-text-primary" />
      </div>
      <a
        @click="playDemo()"
        class="inline-flex items-center justify-center mr-2 items-center w-8 h-8 border border-transparent rounded-md shadow-sm text-sm font-medium"
        :class="
          demoInvalid
            ? 'bg-gray-400 text-black has-tooltip'
            : 'bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 cursor-pointer'
        "
        :disabled="demoInvalid"
      >
        <img src="../../../assets/icons/play.svg" class="w-4 h-4 brightness-200" alt="Play icon" />
        <span class="tooltip rounded shadow-md p-1 bg-gray-700 text-white mt-36 py-2 px-4"
          >This demo failed to process</span
        >
      </a>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import moment from "moment";

export default {
  name: "DemoRow",
  data() {
    return {
      checkboxVal: false,
    };
  },
  props: {
    demo: {},
    allSelected: Boolean,
    selectedDemos: [],
    numOfDemos: integer,
  },
  watch: {
    //Watching and updating child checkboxes depending on status of checkall checkbox in parent
    allSelected: function (newValue) {
      if (!newValue) {
        this.checkboxVal = false;
        this.$emit("remove-selected-demo", this.demo.id);
      } else if (newValue && !this.checkboxVal) {
        this.checkboxVal = true;
        this.$emit("push-selected-demo", this.demo);
      }
    },
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    team() {
      //Checks to see which team to display based on activeTeamId
      for (var i = 0; i < this.user.teams.length; i++) {
        if (this.user.teams[i].id === Number(localStorage.activeTeamId)) {
          return this.user.teams[i];
        }
      }
      return this.user.teams[0] || {};
    },
    hasTeamNames() {
      return this.demo.team_names && this.demo.team_names.CT && this.demo.team_names.T;
    },
    hasTeamScores() {
      return this.demo.team_scores && this.demo.team_scores.CT && this.demo.team_scores.T;
    },
    demoInvalid() {
      return (
        this.demo.status === "broken" ||
        this.demo.status === "analysis_failed" ||
        this.demo.status === "failed"
      );
    },
  },
  methods: {
    updateSelected(demo) {
      this.checkboxVal = !this.checkboxVal;
      if (this.checkboxVal) {
        this.$emit("push-selected-demo", demo);
      } else {
        this.$emit("remove-selected-demo", demo.id);
      }
      if (this.allSelected) this.$emit("update-all-selected", false);
    },
    ...mapMutations(["showMessage", "hideMessage"]),
    ...mapState(["activeTeam"]),
    goToDemo: function goToDemo() {
      if (this.demo.status === "broken" || this.demo.status === "analysis_failed") {
        return false;
      }

      return this.$router.push({
        name: "RestratDemos",
        params: { id: this.demo.id },
      });
    },
    playDemo: function playDemo() {
      if (this.demoInvalid) {
        return false;
      }

      return this.$router.push({
        name: "ReviewPage",
        params: { id: this.demo.id },
      });
    },
    downloadDemo() {
      window.open(this.demo.download_url, "_blank");
    },
    showMessagePopup(messageType, messageText, hideAutomatically = false) {
      this.showMessage({ messageType, messageText });
      if (hideAutomatically) {
        setTimeout(this.hideMessage, 3000);
      }
    },
    validateDemo: function validateDemo() {
      return (
        (this.demo && this.demo.status === "ready") ||
        this.demo.status === "analyzing" ||
        this.demo.status === "uploaded"
      );
    },
  },
};
</script>

<style lang="scss"></style>
