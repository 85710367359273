<template>
  <div class="p-4 gap-6 bg-gray-900 rounded-md absolute flex flex-col w-[500px]">
    <div class="flex gap-2 items-center">
      <div class="rounded-full h-8 w-8 bg-gray-800 flex items-center justify-center">
        <img src="../assets/icons/lock.svg" />
      </div>
      <h1 class="text-text-primary text-xl font-bold">{{ props.title }}</h1>
    </div>
    <iframe
      v-if="props.video_url"
      class="w-full aspect-[16/9] rounded-sm"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      :src="props.video_url + '&autoplay=1&mute=1'"
      >>
    </iframe>
    <div v-if="tier === 'Player'" class="text-text-secondary">
      By upgrading to our Player Tier and gain access to our tools!
    </div>
    <div v-else class="text-text-secondary">
      By upgrading to our Competitor Tier and gain access to all of our tools!
    </div>
    <ul class="flex flex-col gap-4 text-text-secondary">
      <li class="flex">
        <img class="mr-2" src="../assets/icons/check.svg" />
        Starting from
        <span v-if="tier === 'Player'" class="text-text-primary px-1">$7</span>
        <span v-else class="text-text-primary px-1">$11.5</span>
        / month
      </li>
      <li class="flex">
        <img class="mr-2" src="../assets/icons/check.svg" />
        {{ props.firstBenefit }}
      </li>
      <li class="flex">
        <img class="mr-2" src="../assets/icons/check.svg" />
        {{ props.secondBenefit }}
      </li>
    </ul>
    <router-link
      to="upgrade"
      class="bg-primary-500 px-4 py-3 hover:bg-primary-400 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
    >
      Upgrade Now
      <img class="brightness-200" src="../assets/icons/rightarrow.svg" />
    </router-link>
  </div>
</template>

<script setup>
import { useGtm } from "@gtm-support/vue-gtm";
import { onMounted } from 'vue'

const gtm = useGtm();

onMounted(() => {
  gtm.push({
    event: "view_promotion",
    promotion_type: props.primaryPromotionType,
    tier: props.tier
  });
});

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  tier: {
    type: String,
    required: false,
  },
  firstBenefit: {
    type: String,
    required: false,
  },
  secondBenefit: {
    type: String,
    required: false,
  },
  video_url: {
    type: String,
    required: true,
  },
  primaryPromotionType: {
    type: String,
    required: true,
  }
});
</script>
