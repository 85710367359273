<template>
  <DashboardLayout title="Upgrade">
    <!-- Header and Page Header -->
    <div class="relative">
      <!-- Page Header -->
      <div
        class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:max-w-7xl lg:py-24 lg:px-8"
      >
        <div class="relative">
          <h1
            class="text-3xl font-extrabold text-center text-white sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl font-header"
          >
            Are you a competitor?
          </h1>
          <p class="mt-6 max-w-2xl text-xl mx-auto text-gray-300 text-center">
            Choose the right plan for you and become good, quick!
          </p>
        </div>
      </div>
    </div>

    <main>
      <!-- Pricing Section -->
      <section class="relative max-w-7xl mx-auto flex justify-center" aria-labelledby="pricing-heading">
        <Upgrade />
      </section>
    </main>
  </DashboardLayout>
</template>

<script setup>
import DashboardLayout from "../components/layouts/DashboardLayout.vue";
import Upgrade from "../components/Upgrade.vue";
import { onMounted } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";

const gtm = useGtm();
onMounted(() => {
  if(!gtm) return;
  gtm.push({
    event: 'add_to_cart',
  })
});
import { useHead } from '@unhead/vue'

useHead({
  title: 'Refrag | Upgrade',
})
</script>
