<template>
  <div class="p-4 gap-6 bg-gray-900 flex flex-col gap-5">
    <div class="flex gap-2 items-center">
      <div class="h-16 w-16 bg-danger-500/10 flex items-center justify-center gap-3">
        <img src="../assets/icons/cookie.svg" />
      </div>
      <h1 class="text-text-primary text-3xl">Cookies Policy</h1>
    </div>
  
    <p class="text-text-secondary font-light text-sm">
      If you choose to 'Accept all', we will use cookies and data to measure the effectiveness of our marketing efforts. If you choose to 'Save', we will only use cookies that are necessary for the site to function properly.
    </p>

    <h2 class="text-text-primary text-xl">
      Necessary cookies
    </h2>

    <div class="flex gap-3">
      <Switch
        v-model="necessaryCookies"
        :disabled="true"
        :class="necessaryCookies ? 'bg-danger-500' : 'bg-gray-600'"
        class="relative inline-flex h-5 min-w-9 w-9 items-center rounded-full mt-1"
      >
        <span class="sr-only">Enable notifications</span>
        <span
          :class="necessaryCookies ? 'translate-x-4 bg-primary-50' : 'translate-x-[2px] bg-gray-800'"
          class="inline-block h-4 w-4 transform rounded-full transition"
        />
      </Switch>
      <div class="flex flex-col gap-2">
        <h3 class="text-text-primary text-lg">Necessary cookies</h3>
        <p class="text-text-secondary font-light text-sm">
          These cookies are necessary for the website to function properly.
        </p>
      </div>
    </div>

    <h2 class="text-text-primary text-xl">
      Optional cookies
    </h2>

    <div class="flex gap-3">
      <Switch
        v-model="optionalCookies"
        :class="optionalCookies ? 'bg-danger-500' : 'bg-gray-600'"
        class="relative inline-flex h-5 min-w-9 w-9 items-center rounded-full mt-1"
      >
        <span class="sr-only">Enable notifications</span>
        <span
          :class="optionalCookies ? 'translate-x-4 bg-primary-50' : 'translate-x-[2px] bg-gray-800'"
          class="inline-block h-4 w-4 transform rounded-full transition"
        />
      </Switch>
      <div class="flex flex-col gap-2">
        <h3 class="text-text-primary text-lg">Analytics cookies</h3>
        <p class="text-text-secondary font-light text-sm">
          These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.
        </p>
      </div>
    </div>

    <div class="flex gap-6 w-full">
      <button
        @click="acceptAllCookies"
        class="bg-danger-500 w-full px-4 py-3 hover:bg-danger-400 duration-300 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center"
      >
        Accept All
      </button>
      <button
        @click="saveCookieSettings"
        class="bg-gray-600 w-full px-4 py-3 hover:bg-gray-500 duration-300 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center"
      >
        Save
      </button>
    </div>

  </div>
</template>

<script setup>
import { useGtm } from "@gtm-support/vue-gtm";
import { Switch } from "@headlessui/vue";
import { ref, getCurrentInstance, defineEmits } from "vue";

const gtm = useGtm();
const necessaryCookies = ref(true);
const optionalCookies = ref(false);
const { proxy } = getCurrentInstance();
const cookies = proxy.$cookies;
const emit = defineEmits(['toggleModal'])

function getCookieSettings() {
  var enabledCookies = cookies.get('ncc_e')?.split('~');
  var analyticsCookie = enabledCookies?.find(cookie => cookie.includes('ncc_analytics'));

  if (analyticsCookie) {
    optionalCookies.value = true;
    gtm.enable(true);
  } else {
    optionalCookies.value = false;
    gtm.enable(false);
  }
}

function saveCookieSettings() {
  var cookieValue = necessaryCookies.value ? 'ncc_necessary' : '';
  cookieValue += optionalCookies.value ? '~ncc_analytics' : '';

  cookies.set('ncc_c', 'ncc_necessaryncc_analytics', '365d', null, '.refrag.gg');
  cookies.set('ncc_e', cookieValue, '365d', null, '.refrag.gg');

  if (optionalCookies.value) {
    gtm.enable(true);
  } else {
    gtm.enable(false);
  }
  emit('toggleModal');
}

function acceptAllCookies() {
  necessaryCookies.value = true;
  optionalCookies.value = true;
  saveCookieSettings();
}

getCookieSettings();

</script>
