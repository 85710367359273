<template>
    <Dashboard v-if="authUser">
      <div v-if="!loading && userExam.status === 'passed'" class="flex flex-col items-start gap-8 w-full h-full">
        <div  class="w-full flex justify-center relative h-full items-center">
          <div class="flex flex-col gap-6 max-w-[800px] w-full">
            <div class="flex-1 p-6 exam-finish-background rounded-lg flex items-center flex-col gap-3 topography">
              <img :src="`/img/icons/${route.params.slug}_${userExam.difficulty}_badge.svg`" alt="finish" class="max-w-[148px]" />
              <div class="flex gap-2 items-center">
                <img v-if="user.steam_profile?.avatarfull" :src="user?.steam_profile?.avatarfull" alt="trophy" class="w-10 h-10 rounded-full" />
                <img v-else src="../../assets/logos/refrag-icon-button.svg" alt="trophy" class="w-9 h-9 rounded-full" />
                <h1 class="text-2xl font-semibold text-text-primary tracking-[0.5px]">{{ username() }}'s Exam Badge</h1>
              </div>
              <p class="text-text-secondary text-sm font-light">{{ username() }} successfully completed {{ userExam.bootcamp_name }} Bootcamp on {{ getFriendlyDifficultyName(userExam.difficulty) }} difficulty</p>
            </div>
            <div class="flex gap-6 w-full">
              <!-- <div class="flex-[5] bg-gray-900 rounded-lg">
                <div class="flex flex-col divide-y-2 divide-gray-950">
                  <h1 class="text-lg font-semibold text-text-primary tracking-[0.5px] p-4">Replay</h1>
                  <div class="p-4 w-full aspect-[16/9]">
                    <iframe
                      v-if="clip?.status == 'processed' || clip?.status == 'submitted'"
                      :key="clip?.status"
                      class="w-full h-full"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      :src="clip.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
                    >
                    </iframe>
                    <div v-else class="w-full h-full bg-black rounded-lg"/>
                  </div>
                </div>
              </div> -->
              <div class="flex-[3] flex gap-2">
                <div class="p-4 flex flex-col gap-1 items-center justify-center bg-gray-900 rounded-lg flex-1">
                  <h2 class="text-text-secondary text-sm">Time</h2>
                  <h1 class="text-text-primary text-xl font-semibold">{{ getTimeInMinutesAndSeconds() }}</h1>
                </div>
                <div class="p-4 flex flex-col gap-1 items-center justify-center bg-gray-900 rounded-lg flex-1">
                  <h2 class="text-text-secondary text-sm">Score</h2>
                  <h1 class="text-text-primary text-xl font-semibold">{{ userExam.score }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>

    <div v-else class="flex flex-col h-full items-center w-full bg-gray-950">
      <div class="w-full min-h-[56px] bg-gray-900 border-b border-gray-800 relative gap-4 flex items-center justify-end pr-9">
        <img class="h-6 absolute left-6 top-0 md:left-1/2 md:-translate-x-1/2 translate-y-[16px]" src="../../assets/logos/refrag-horz-fill.svg" alt="Refrag Logo">
        <router-link to="/login" class="px-3 py-2.5 bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700">
          Login
        </router-link>
        <router-link to="/register" class="px-3 py-2.5 bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500">
          Signup
        </router-link>
      </div>

      <div v-if="!loading && userExam.status === 'passed'" class="flex flex-col items-start gap-8 w-full mt-16 h-full">
        <div  class="w-full flex justify-center relative h-full items-center">
          <div class="flex flex-col gap-6 max-w-[800px] w-full">
            <div class="flex-1 p-6 exam-finish-background rounded-lg flex items-center flex-col gap-3 topography">
              <img :src="`/img/icons/${route.params.slug}_${userExam.difficulty}_badge.svg`" alt="finish" class="max-w-[148px]" />
              <div class="flex gap-2 items-center">
                <img v-if="user.steam_profile?.avatarfull" :src="user?.steam_profile?.avatarfull" alt="trophy" class="w-10 h-10 rounded-full" />
                <img v-else src="../../assets/logos/refrag-icon-button.svg" alt="trophy" class="w-9 h-9 rounded-full" />
                <h1 class="text-2xl font-semibold text-text-primary tracking-[0.5px]">{{ username() }}'s Exam Badge</h1>
              </div>
              <p class="text-text-secondary text-sm font-light">{{ username() }} successfully completed on {{ getFriendlyDifficultyName(userExam.difficulty) }} difficulty</p>
            </div>
            <div class="flex gap-6 w-full">
              <!-- <div class="flex-[5] bg-gray-900 rounded-lg">
                <div class="flex flex-col divide-y-2 divide-gray-950">
                  <h1 class="text-lg font-semibold text-text-primary tracking-[0.5px] p-4">Replay</h1>
                  <div class="p-4 w-full aspect-[16/9]">
                    <iframe
                      v-if="clip?.status == 'processed' || clip?.status == 'submitted'"
                      :key="clip?.status"
                      class="w-full h-full"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      :src="clip.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
                    >
                    </iframe>
                    <div v-else class="w-full h-full bg-black rounded-lg"/>
                  </div>
                </div>
              </div> -->
              <div class="flex-[3] flex gap-2">
                <div class="p-4 flex flex-col gap-1 items-center justify-center bg-gray-900 rounded-lg flex-1">
                  <h2 class="text-text-secondary text-sm">Time</h2>
                  <h1 class="text-text-primary text-xl font-semibold">{{ getTimeInMinutesAndSeconds() }}</h1>
                </div>
                <div class="p-4 flex flex-col gap-1 items-center justify-center bg-gray-900 rounded-lg flex-1">
                  <h2 class="text-text-secondary text-sm">Score</h2>
                  <h1 class="text-text-primary text-xl font-semibold">{{ userExam.score }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import Dashboard from "../../components/layouts/DashboardLayout.vue";
  import { ref, computed, getCurrentInstance, onBeforeUnmount } from "vue";
  import axios from "axios";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import StartServerModal from "../../components/StartServerModal.vue";
  import { useAuth } from "@websanova/vue-auth/src/v3.js";
  
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const userExam = ref(null);
  const loading = ref(false);
  const user = ref(null);
  const auth = useAuth();
  const authUser = computed(() => auth.user());

  function getExam() {
    loading.value = true;
    axios({
      url: `/user_exams/${route.params.id}`,
      method: "GET",
    }).then((response) => {
      userExam.value = response.data;
      user.value = response.data.user;
      loading.value = false;
    });
  }

  function getFriendlyDifficultyName(difficulty) {
    return difficulty.charAt(0).toUpperCase() + difficulty.slice(1);
  }

  function getTimeInMinutesAndSeconds() {
    const minutes = Math.floor(userExam.value.total_time_taken / 60000);
    const seconds = ((userExam.value.total_time_taken % 60000) / 1000).toFixed(0);
    return `${minutes}m, ${(seconds < 10 ? "0" : "") + seconds}s`;
  }

  function username() {
    if (user.value.username) {
      return user.value.username;
    }
    return user?.value.steam_profile?.personaname;
  }
  
  getExam();
  </script>
  
  <style scoped>
  
  .exam-finish-background {
    background: radial-gradient(50.14% 50.14% at 50% 0%, rgba(142, 188, 232, 0.2) 0%, rgba(142, 188, 232, 0) 100%);
    background-color: #0F141A;
  }
  
  </style>
  